import ajax from 'api/utils/ajax';
import closeRedIcon from 'assets/images/close-red.svg';
import imageUploadIcon from 'assets/images/image-upload.svg';
import axios from 'axios';
import { IMAGE_TYPE, MODAL_IMAGE_THUMB } from 'const';
import get from 'lodash/get';
import { useModal } from 'modules/modal';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  arrayRequired,
  composeValidators,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Field, useForm, useFormState } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { colors } from 'theme/colors';
import { Button } from 'ui/button';
import { Divider } from 'ui/divider';
import { Icon } from 'ui/icon';
import { TextField } from 'ui/input';
import ColorInput from 'ui/input/ColorInput';
import { Error } from 'ui/labels';
import { Typography } from 'ui/typography';
import { useNotification } from "hooks/useNotification";
import { Notification } from "ui/notification";

const FAColorInput = fieldAdapter(ColorInput);
const FATextField = fieldAdapter(TextField);

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border: 1px dashed ${colors.blue.main};
  border-radius: 8px;
  cursor: pointer;

  & > p {
    max-width: 50%;
    word-break: break-word;
    font-size: 14px;
    line-height: 1.6;
    color: ${colors.gray.medium};
  }

  & > span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & button {
    padding: 6px 16px;
    font-size: 12px;
    background-color: ${colors.blue.mediumLight};
  }
`;

const DropzoneThumbContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
`;

const DropzoneThumb = styled.div`
  position: relative;
  width: calc(33% - 15px);
  height: 100px;
  margin: 0 20px 20px 0;

  ${mq.md} {
    width: 100px;
  }

  &:nth-child(3n) {
    margin-right: 0;

    ${mq.md} {
      margin-right: 20px;
    }
  }
`;

const DropzoneImage = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const DropzoneRemove = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  background-color: ${colors.white.main};
  border: 1px solid #e6e9ef;
  z-index: 1;
  transform: translate(50%, -50%);
`;

type Props = {
  internalNotesSlot: ReactNode;
};

type presignedResponse = {
  data: {
    presigned_url: string;
    file_url: string;
  };
};

type ImageFile = File & {
  preview: string;
  s3Key: string;
};

export const CreateQrCodeImageSubform = memo(({ internalNotesSlot }: Props) => {
  const { showModal } = useModal();
  const { change } = useForm();
  const { values } = useFormState();
  const imageButtonTextValue = get(values, 'button_text_image');
  const imageButtonLinkValue = get(values, 'button_link_image');
  const imageFilesLinksValues = get(values, 'files_links_image');
  const [isButton, setIsButton] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<ImageFile[]>([]);
  const [isImageLoading, setIsImageLoading] = useState<string | null>(null);
  const [error, setError] = useState<string>();
  const {
    key,
    isNotificationVisible,
    handleCloseNotification,
  } = useNotification(error);

  const handleImagesUpload = useCallback(async (file: File) => {
    try {
      setError(undefined)

      if (file.size > 2e7) {
        setError("Please, upload image less then 20Mb")
        return
      }

      setIsImageLoading(file.name);

      const presignedResponse: presignedResponse = await ajax.get(
        '/aws/s3/presigned_urls/new',
        {
          params: {
            s3_key: encodeURI(file.name),
            content_type: IMAGE_TYPE,
          },
        }
      );
      const preasignedUrl: string = presignedResponse.data.presigned_url;
      const fileUrl: string = presignedResponse.data.file_url;

      await axios.put(preasignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const filePreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
        s3Key: encodeURI(fileUrl),
      });

      setIsImageLoading(null);
      return filePreview;
    } catch (err) {
      console.log('handleImagesUpload err =>', err);
    }
  }, []);

  const handleOrDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles.map(async (file: File) => {
        let imageUploaded = await handleImagesUpload(file);
        return imageUploaded;
      });

      Promise.all(files)
        .then((completed: any) => {
          const compactCompleted = completed.filter(function (element: any) { return element != undefined });
          if (compactCompleted.length > 0) {
            setImageFiles((state) => [...state, ...completed]);
          }
        })
        .catch((err) => {
          console.log('dropzone err =>', err);
        });
    },
    [handleImagesUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: handleOrDrop,
  });

  const handleClickButton = useCallback(() => {
    setIsButton(!isButton);
  }, [isButton]);

  const handleOpenThumbModal = useCallback(
    (thumb: ImageFile) => {
      showModal(MODAL_IMAGE_THUMB, {
        title: thumb.preview,
        url: thumb.preview,
      });
    },
    [showModal]
  );

  const handleRemoveThumb = useCallback((name: string) => {
    setImageFiles((state) => state.filter((it) => it?.name !== name));
  }, []);

  useEffect(() => {
    if (!!imageFilesLinksValues) {
      const initImageFilesLinksValues = imageFilesLinksValues.map(
        (it: string) => ({
          name: it,
          preview: it,
          s3Key: it,
        })
      );
      setImageFiles(initImageFilesLinksValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    change(
      'files_links_image',
      imageFiles.map((it) => it?.s3Key)
    );
  }, [change, imageFiles]);

  useEffect(() => {
    if (!!imageButtonTextValue || !!imageButtonLinkValue) {
      setIsButton(true);
    }
  }, [imageButtonLinkValue, imageButtonTextValue]);

  return (
    <Box>
      <Notification
        key={key}
        message={error}
        type='error'
        isNotificationVisible={isNotificationVisible}
        onClose={handleCloseNotification}
      />
      <Box>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Basic Information
        </Typography>
      </Box>
      <Box mt='16px'>
        <Field
          id='headline_image'
          name='headline_image'
          label='Headline'
          placeholder='Enter name'
          component={FATextField}
          validate={required}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='description_image'
          name='description_image'
          label='Description'
          placeholder='More information about your gallery'
          component={FATextField}
          validate={required}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='website_image'
          name='website_image'
          label='Website'
          placeholder='https://yourwebsite.com'
          component={FATextField}
          validate={composeValidators(required, isUrl, maxLength(200))}
          format={toHttps}
          formatOnBlur
        />
      </Box>

      <Box mt='24px'>
        <Divider />
      </Box>

      <Box mt='16px'>
        <Flex>
          <Box mr='50px'>
            <Typography
              variant='footnote'
              color='gray.medium'
              style={{ marginBottom: '4px' }}
            >
              Button
            </Typography>
          </Box>
          {!isButton && (
            <Box>
              <Button
                onClick={handleClickButton}
                variant='text'
                style={{ padding: 0, textDecoration: 'underline' }}
              >
                Add button
              </Button>
            </Box>
          )}
        </Flex>
        {isButton && (
          <Flex flexDirection='column'>
            <Flex
              mb='8px'
              justifyContent='space-between'
              flexDirection={['column', 'row']}
            >
              <Box flex='1' mr={[0, 16]} mb={[16, 0]}>
                <Field
                  id='button_text_image'
                  name='button_text_image'
                  placeholder='Start shopping'
                  component={FATextField}
                  validate={required}
                />
              </Box>
              <Box flex='1'>
                <Field
                  id='button_link_image'
                  name='button_link_image'
                  placeholder='https://yourwebsite.com'
                  component={FATextField}
                  validate={composeValidators(required, isUrl, maxLength(200))}
                  format={toHttps}
                  formatOnBlur
                />
              </Box>
            </Flex>
            <Box alignSelf='flex-end'>
              <Button
                onClick={handleClickButton}
                variant='text'
                style={{ padding: 0, textDecoration: 'underline' }}
              >
                Remove button
              </Button>
            </Box>
          </Flex>
        )}
      </Box>

      <Box mt={16}>
        <Field
          id='files_links_image'
          name='files_links_image'
          label='Images'
          validate={arrayRequired}
          render={(props) => (
            <>
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag and drop images here</p>
                <span>
                  <Icon url={imageUploadIcon} width={56} height={56} mb={16} />
                  <Button variant={'text'}>Upload image</Button>
                </span>
              </DropzoneContainer>
              {props.meta.touched && <Error>{props.meta.error}</Error>}
            </>
          )}
        />
      </Box>
      <Box mt={16}>
        <DropzoneThumbContainer>
          {imageFiles &&
          imageFiles.map(
            (it, i) =>
              isImageLoading !== it?.name && (
                <DropzoneThumb key={`${i}-${it}`}>
                  <DropzoneImage
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpenThumbModal(it);
                    }}
                  >
                    {it && <img src={it.preview} alt={it?.name} />}
                  </DropzoneImage>
                  <DropzoneRemove>
                    <Button
                      p='8px'
                      onClick={() => handleRemoveThumb(it?.name)}
                    >
                      <Icon url={closeRedIcon} width={9} height={9} />
                    </Button>
                  </DropzoneRemove>
                </DropzoneThumb>
              )
          )}
        </DropzoneThumbContainer>
      </Box>

      <Box mt='16px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Color palette
        </Typography>
      </Box>
      <Box
        mt='16px'
        sx={{
          display: 'grid',
          columnGap: '12px',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Box>
          <Field
            id='background_color_image'
            name='background_color_image'
            label='Background'
            component={FAColorInput}
          />
        </Box>
        <Box>
          <Field
            id='text_color_image'
            name='text_color_image'
            label='Text'
            component={FAColorInput}
          />
        </Box>
      </Box>
      <Box mt='16px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Welcome Screen
        </Typography>
      </Box>
      <Box mt='16px'>Welcome</Box>
      {internalNotesSlot}
    </Box>
  );
});
