import styled from 'styled-components';
import { padding, PaddingProps } from 'styled-system';
import { themeGet } from 'theme';

export const Item = styled.div<PaddingProps>`
  display: flex;
  align-items: center;
  padding: 8px 14px 8px 14px;
  background-color: ${themeGet('colors.white.main')};
  margin: 0;
  text-indent: 0;
  list-style-type: 0;
  cursor: pointer;

  &:hover,
  &.active,
  &.selected {
    color: ${themeGet('colors.blue.main')};
    background-color: ${themeGet('colors.blue.mediumLight')};
  }

  ${padding};
`;

export const TypeQrCodeItem = styled.div<PaddingProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px 8px 14px;
  background-color: ${themeGet('colors.white.main')};
  margin: 0;
  text-indent: 0;
  list-style-type: 0;
  cursor: pointer;

  &:hover,
  &.active,
  &.selected {
    color: ${themeGet('colors.blue.main')};
    background-color: ${themeGet('colors.blue.mediumLight')};
  }

  ${padding};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
