import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import { FolderResponse } from '../types';
import { Folder } from 'types';

export const mapResponseToFolder = (res: FolderResponse): Folder =>
  compose(
    omit([
      'account_id',
      'created_at',
      'updated_at',
      'qr_codes_count',
      'last_version',
    ]),
    (res) => ({
      ...res,
      accountId: res.account_id,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
      qrCodesCount: res.qr_codes_count,
      lastVersion: res.last_version,
    })
  )(res);
