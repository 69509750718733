import { createSlice } from '@reduxjs/toolkit';
import { putUser, removeUser } from '../actions/user';
import { CurrentUser, CurrentAccount, CurrentPlan, Card } from 'types';

export interface UserState {
  currentUser: CurrentUser | undefined;
  account: CurrentAccount | undefined;
  currentPlan: CurrentPlan | undefined;
  cards: Card[];
  invites: [];
  confirmed: boolean;
}

const initialState: UserState = {
  currentUser: undefined,
  account: undefined,
  currentPlan: undefined,
  cards: [],
  invites: [],
  confirmed: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [putUser.type]: (state, { payload }) => ({ ...state, ...payload }),
    [removeUser.type]: () => initialState,
  },
});
