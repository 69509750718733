import { useState } from 'react';
import { bindPromiseCreators } from 'redux-saga-routines';
import { connect } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { Card } from 'ui/card';
import { Confirm } from 'ui/modal/Confirm';
import { Invite } from 'types';
import { Typography } from 'ui/typography';
import { revokeInviteRoutinePromiseCreator } from 'store/actions/user';

type Props = {
  onClose: () => void;
  options: { invite: Invite };
  revoke: (payload: { id: number }) => PromiseLike<any>;
};

const enhance = connect(null, (dispatch) => ({
  revoke: bindPromiseCreators(revokeInviteRoutinePromiseCreator, dispatch),
}));

export const RevokeAccessModal = enhance(
  ({ onClose, revoke, options: { invite } }: Props) => {
    const [error, setError] = useState<string | undefined>();
    const { id, email } = invite;

    const handleRevoke = async () => {
      try {
        await revoke({ id });
        onClose();
      } catch (e) {
        setError(e.message || e);
      }
    };

    return (
      <Card>
        <Confirm
          heading='Revoke Acces'
          subheading={`Please confirm that you would like to revoke access for ${email}.
          This cannot be undone.`}
          submitText='Revoke'
          onCalcel={onClose}
          onSubmit={handleRevoke}
        />
        {error && (
          <Box pb={24}>
            <Typography variant='footnote' color='red.main' textAlign='center'>
              {error}
            </Typography>
          </Box>
        )}
      </Card>
    );
  }
);
