import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  putUser,
  fetchCurrentUser,
  updateCurrentUserRoutine,
  UpdateCurrentUserActionPayload,
  updateCurrentAccountRoutine,
  UpdateCurrentAccountActionPayload,
  updatePasswordRoutine,
  UpdatePasswordActionPayload,
  createCardRoutine,
  CreateCardActionPayload,
  fetchCards,
  removeCardRoutine,
  RemoveCardActionPayload,
  fetchCurrentPlan,
  fetchInvites,
  createInviteRoutine,
  CreateInviteActionPayload,
  revokeInviteRoutine,
  RevokeInviteActionPayload,
  acceptInviteRoutine,
  AcceptInviteActionPayload,
  fetchFullCurrent,
  deleteAvatarRoutine,
} from '../actions/user';
import { setAppState } from '../actions/app';
import {
  selectCurrentAccount,
  selectCurrentPlan,
  selectCurrentUser,
} from '../selectors/user';
import {
  getCurrentUser,
  patchAccount,
  patchUser,
  getCurrentPlan,
  patchPassword,
  deleteUserAvatar,
} from 'api/user';
import { createCard, getCards, removeCard } from 'api/cards';
import {
  getInvitedUsers,
  acceptInvitation,
  sendInvite,
  revokeInvitation,
} from 'api/invitations';
import { mapUserToRequest, mapAccountToRequest } from 'api/mappers/user';
import { CurrentUser, CurrentAccount, CurrentPlan, Card, Invite } from 'types';

type Current = {
  account: CurrentAccount;
  currentUser: CurrentUser;
};

function* fetchUser() {
  try {
    yield put(setAppState({ error: null, loading: true }));
    const user: Current = yield call(getCurrentUser);

    yield put(putUser(user));
    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* updateUser({
  payload,
}: PayloadAction<UpdateCurrentUserActionPayload>) {
  try {
    yield put(updateCurrentUserRoutine.request());

    const req = yield call(mapUserToRequest, payload);

    const currentUser: CurrentUser = yield call(
      patchUser,
      req
    );

    yield put(putUser({ currentUser }));
    yield put(updateCurrentUserRoutine.success());
  } catch (e) {
    yield put(updateCurrentUserRoutine.failure(e?.message || e));
  } finally {
    yield put(updateCurrentUserRoutine.fulfill());
  }
}

function* deleteAvatar() {
  try {
    yield put(deleteAvatarRoutine.request())

    const currentUser: CurrentUser = yield call(deleteUserAvatar)
    yield put(putUser({ currentUser }))
    yield put(deleteAvatarRoutine.success());
  } catch (e) {
    yield put(deleteAvatarRoutine.failure(e?.message || e));
  } finally {
    yield put(deleteAvatarRoutine.fulfill());
  }
}

function* updateAccount({
  payload,
}: PayloadAction<UpdateCurrentAccountActionPayload>) {
  try {
    yield put(updateCurrentAccountRoutine.request());

    const account = yield call(patchAccount, mapAccountToRequest(payload));

    yield put(putUser({ account }));
    yield put(updateCurrentAccountRoutine.success());
  } catch (e) {
    yield put(updateCurrentAccountRoutine.failure(e?.message || e));
  } finally {
    yield put(updateCurrentAccountRoutine.fulfill());
  }
}

function* updatePassword({
  payload,
}: PayloadAction<UpdatePasswordActionPayload>) {
  try {
    yield put(updatePasswordRoutine.request());
    yield call(patchPassword, payload);
    yield put(updatePasswordRoutine.success());
  } catch (e) {
    yield put(updatePasswordRoutine.failure(e?.message || e));
  } finally {
    yield put(updatePasswordRoutine.fulfill());
  }
}

function* updateCurrentPlan() {
  try {
    setAppState({ loading: true, error: null });
    const currentPlan: CurrentPlan = yield call(getCurrentPlan);

    yield put(putUser({ currentPlan }));

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* createNewCard({ payload }: PayloadAction<CreateCardActionPayload>) {
  try {
    yield put(createCardRoutine.request());
    const card: Card = yield call(createCard, payload);

    yield put(putUser({ cards: [card] }));
    yield put(createCardRoutine.success());
  } catch (e) {
    yield put(createCardRoutine.failure(e));
  } finally {
    yield put(createCardRoutine.fulfill());
  }
}

function* fetchAccountCards() {
  try {
    yield put(setAppState({ error: null, loading: true }));
    const cards: Card[] = yield call(getCards);

    yield put(putUser({ cards }));

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* removeCardById({ payload }: PayloadAction<RemoveCardActionPayload>) {
  try {
    yield put(removeCardRoutine.request());
    yield put(putUser({ cards: [] }));
    yield call(removeCard, payload);
    yield put(removeCardRoutine.success());
  } catch (e) {
    yield put(removeCardRoutine.failure(e.message));
  } finally {
    yield put(removeCardRoutine.fulfill());
  }
}

function* fetchCurrentInvites() {
  try {
    yield put(setAppState({ error: null, loading: true }));

    const invites: Invite[] = yield call(getInvitedUsers);

    yield put(putUser({ invites }));

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* createInviteForEmail({
  payload,
}: PayloadAction<CreateInviteActionPayload>) {
  try {
    yield put(createInviteRoutine.request());
    yield call(sendInvite, payload);
    yield put(fetchInvites());
    yield put(createInviteRoutine.success());
  } catch (e) {
    yield put(createInviteRoutine.failure(e.message));
  } finally {
    yield put(createInviteRoutine.fulfill());
  }
}

function* removeInvitation({
  payload,
}: PayloadAction<RevokeInviteActionPayload>) {
  try {
    yield put(revokeInviteRoutine.request());
    yield call(revokeInvitation, payload);
    yield put(fetchInvites());
    yield put(revokeInviteRoutine.success());
  } catch (e) {
    yield put(revokeInviteRoutine.failure(e.message));
  } finally {
    yield put(revokeInviteRoutine.fulfill());
  }
}

function* fetchAllCurrentEntites() {
  try {
    yield put(setAppState({ error: null, loading: true }));

    yield put(fetchCurrentUser());
    yield put(fetchCurrentPlan());

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

export default function* watcher() {
  yield takeLatest(fetchCurrentUser.type, fetchUser);
  yield takeLatest(updateCurrentUserRoutine.TRIGGER, updateUser);
  yield takeLatest(deleteAvatarRoutine.TRIGGER, deleteAvatar);
  yield takeLatest(updateCurrentAccountRoutine.TRIGGER, updateAccount);
  yield takeLatest(fetchCurrentPlan.type, updateCurrentPlan);
  yield takeLatest(updatePasswordRoutine.TRIGGER, updatePassword);
  yield takeLatest(createCardRoutine.TRIGGER, createNewCard);
  yield takeLatest(fetchCards, fetchAccountCards);
  yield takeLatest(removeCardRoutine.TRIGGER, removeCardById);
  yield takeLatest(fetchInvites.type, fetchCurrentInvites);
  yield takeLatest(createInviteRoutine.TRIGGER, createInviteForEmail);
  yield takeLatest(revokeInviteRoutine.TRIGGER, removeInvitation);
  yield takeLatest(fetchFullCurrent.type, fetchAllCurrentEntites);
}
