import { ReactElement } from 'react';
import ReactModal from 'react-modal';

import './_modal.css';

ReactModal.setAppElement('#modal');

type Props = {
  id: string;
  children: ReactElement;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const ModalWrapper = ({
  id,
  children,
  isOpen,
  onRequestClose,
}: Props) => (
  <ReactModal
    id={id}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className={{
      base: 'ModalContent',
      afterOpen: 'ModalContent__after-open',
      beforeClose: 'ModalContent__before-close',
    }}
    overlayClassName={{
      base: 'ModalOverlay',
      afterOpen: 'ModalOverlay__after-open',
      beforeClose: 'ModalOverlay__before-close',
    }}
    closeTimeoutMS={300}>
    {children}
  </ReactModal>
);
