import get from 'lodash/fp/get';
import { RootState } from 'store/index';
import { CurrentPlan, CurrentAccount, CurrentUser, Invite, Card } from 'types';

export const selectCurrentUser = (state: RootState): CurrentUser | undefined =>
  get('user.currentUser', state);

export const selectCurrentAccount = (
  state: RootState
): CurrentAccount | undefined => get('user.account', state);

export const selectCurrentPlan = (state: RootState): CurrentPlan | undefined =>
  get('user.currentPlan', state);

export const selectCurrentCards = (state: RootState): Card[] =>
  get('user.cards', state);

export const selectCurrentInvites = (state: RootState): Invite[] =>
  get('user.invites', state);

export const selectUserConfirmed = (state: RootState): boolean => 
  get('user.currentUser.confirmed', state);