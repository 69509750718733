export const ACTION_AUTH_LOGIN = '@auth/LOGIN';
export const ACTION_AUTH_LOGIN_OAUTH = '@auth/LOGIN_OAUTH';
export const ACTION_AUTH_REGISTER = '@auth/REGISTER';
export const ACTION_AUTH_REGISTER_OAUTH = '@auth/REGISTER_OAUTH';
export const ACTION_AUTH_FORGOT_PWD = '@auth/FORGOT_PASSWORD';
export const ACTION_AUTH_RESET_PWD = '@auth/RESET_PASSWORD';
export const ACTION_AUTH_STORE_TOKEN = '@auth/STORE_TOKEN';
export const ACTION_AUTH_RESET_TOKEN = '@auth/RESET_TOKEN';
export const ACTION_AUTH_LOGOUT = '@auth/LOGOUT';
export const ACTION_CONFIRM_EMAIL = '@auth/CONFIRM_EMAIL';
export const ACTION_RESEND_CONFIRM_EMAIL = '@auth/RESEND_CONFIRM_EMAIL';

export const ACTION_USER_PUT = '@user/PUT';
export const ACTION_USER_UPDATE_USER = '@user/UPDATE_USER';
export const ACTION_USER_DELETE_AVATAR = '@user/DELETE_AVATAR';
export const ACTION_USER_UPDATE_ACCOUNT = '@user/UPDATE_ACCOUNT';
export const ACTION_USER_UPDATE_PASSWORD = '@user/UPDATE_PASSWORD';
export const ACTION_USER_REMOVE = '@user/REMOVE';
export const ACTION_USER_FETCH = '@user/FETCH';
export const ACTION_USER_CURRENT_FETCH = '@user/CURRENT_FETCH';
export const ACTION_USER_FETCH_CURRENT_PLAN = '@user/FETCH_CURRENT_PLAN';
export const ACTION_USER_CREATE_CARD = '@user/CREATE_CARD';
export const ACTION_USER_FETCH_CARDS = '@user/FETCH_CARDS';
export const ACTION_USER_REMOVE_CARD = '@user/REMOVE_CARD';

export const ACTION_USER_CREATE_INVITE = '@user/CREATE_INVITE';
export const ACTION_USER_REVOKE_INVITE = '@user/REVOKE_INVITE';
export const ACTION_USER_FETCH_INVITES = '@user/FETCH_INVITES';
export const ACTION_USER_ACCEPT_INVITATION = '@user/ACCEPT_INVITATION';

export const ACTION_FOLDER_FETCH = '@folders/FETCH';
export const ACTION_FOLDER_FETCH_ALL = '@folders/FETCH_ALL';
export const ACTION_FOLDER_CREATE = '@folders/CREATE';
export const ACTION_FOLDER_PATCH = '@folders/PATCH';
export const ACTION_FOLDER_DELETE = '@folders/DELETE';
export const ACTION_FOLDER_PUT = '@folders/PUT';
export const ACTION_FOLDER_REMOVE = '@folders/REMOVE';
export const ACTION_FOLDER_REMOVE_ALL = '@folders/REMOVE_ALL';

export const ACTION_QRCODE_CREATE = '@qrCode/CREATE';
export const ACTION_QRCODE_FETCH_ALL = '@qrCode/FETCH_ALL';
export const ACTION_QRCODE_FETCH = '@qrCode/FETCH';
export const ACTION_QRCODE_ARCHIVE = '@qrCode/ARCHIVE';
export const ACTION_QRCODE_RESTORE = '@qrCode/RESTORE';
export const ACTION_QRCODE_PUT_LIST = '@qrCode/PUT_LIST';
export const ACTION_QRCODE_UPDATE = '@qrCode/UPDATE';
export const ACTION_QRCODE_UPDATE_BY_ID = '@qrCode/UPDATE_BY_ID';
export const ACTION_QRCODE_PUT_SIZES = '@qrCode/PUT_SIZES';
export const ACTION_QRCODE_REMOVE_ALL = '@qrCode/REMOVE_ALL';
export const ACTION_QRCODE_REMOVE = '@qrCode/REMOVE';
export const ACTION_QRCODE_ADD_TO_FOLDER = '@qrCode/ADD_TO_FOLDER';
export const ACTION_QRCODE_PAUSE = '@qrCode/PAUSE';
export const ACTION_QRCODE_UNPAUSE = '@qrCode/UNPAUSE';
export const ACTION_QRCODE_CHECK = '@qrCode/CHECK';
export const ACTION_QRCODE_CHECK_ALL = '@qrCode/CHECK_ALL';

export const ACTION_APP_SET_STATE = '@app/SET_STATE';
export const ACTION_APP_FETCH_COUNTRIES = '@app/FETCH_COUNTRIES';
export const ACTION_APP_FETCH_STATES = '@app/FETCH_STATES';
export const ACTION_APP_FETCH_CITIES = '@app/FETCH_CITIES';

export const ACTION_SHOW_MODAL = '@modals/SHOW';
export const ACTION_CLOSE_MODAL = '@modals/CLOSE';

export const ACTION_PLANS_FETCH_ALL = '@plans/FETCH_ALL';
export const ACTION_PLANS_UPPGRADE = '@plans/UPGRADE';
export const ACTION_PLANS_CANCEL_DOWNGRADE = '@plans/CANCEL_DOWNGRADE';
export const ACTION_PLANS_REMOVE_ALL = '@plans/REMOVE_ALL';
export const ACTION_PLANS_PUT = '@plans/PUT';

export const ACTION_FEEDBACKS_FETCH_ALL = '@feedbacks/FETCH_ALL';
export const ACTION_FEEDBACKS_FETCH_CATEGORIES = '@feedbacks/FETCH_CATEGORIES';
export const ACTION_FEEDBACKS_PUT = '@feedbacks/PUT';
export const ACTION_FEEDBACKS_PUT_RATING = '@feedbacks/PUT_RATING';
export const ACTION_FEEDBACKS_PUT_CATEGORIES = '@feedbacks/PUT_CATEGORIES';
export const ACTION_FEEDBACKS_REMOVE_BY_ID = '@feedbacks/REMOVE_BY_ID';
export const ACTION_FEEDBACKS_REMOVE = '@feedbacks/REMOVE';
export const ACTION_FEEDBACKS_REMOVE_ALL = '@feedbacks/REMOVE_ALL';
