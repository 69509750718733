import get from 'lodash/fp/get';
import { DefaultTheme } from 'styled-components';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { typography, fontSizes, fonts, fontWeights } from './typography';

export * from './global';

const theme: DefaultTheme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  typography,
};

const themeGet = (path: string) => ({
  theme: themeObj,
}: {
  theme: DefaultTheme;
}) => get(path, themeObj);

export { theme, themeGet };
