import { Flex, Box } from 'rebass/styled-components';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  required,
  maxLength,
  composeValidators,
} from 'modules/utils/validators';

const FATextField = fieldAdapter(TextField);

export type CreateCategoryValueType = {
  label: string;
};

type Props = {
  type: string;
  onClose: () => void;
  onSubmit: (v: CreateCategoryValueType) => Promise<void>;
};

export const CreateCategoryForm = ({ onClose, onSubmit }: Props) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine, submitting, invalid }) => (
      <Flex
        height='100%'
        flexDirection='column'
        justifyContent='space-between'
        mt='12px'
        as='form'
        onSubmit={handleSubmit}
      >
        <Box>
          <Field
            id='categoryName'
            name='categoryName'
            component={FATextField}
            label='Enter name'
            placeholder='Name'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box
          mt={[40, 40, 187]}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '12px',
          }}
        >
          <Button
            variant='filled'
            onClick={onClose}
            color='gray.black'
            backgroundColor='blue.mediumLight'
          >
            Cancel
          </Button>
          <Button
            variant='filled'
            type='submit'
            disabled={pristine || submitting || invalid}
          >
            Create
          </Button>
        </Box>
      </Flex>
    )}
  />
);
