import { range } from 'modules/utils/range';
import { Box, Flex } from 'rebass/styled-components';
import { EditorLayout } from 'ui/layout';
import { Skeleton, SkeletonProvider } from 'ui/skeleton';

const ITEMS_RANGE = range(7);

export const LeftSideSkeleton = () => (
  <SkeletonProvider>
    <Flex>
      <Box pt={56} width={1} maxWidth={516}>
        <Box maxWidth={166}>
          <Skeleton height={41} borderRadius='6px' />
        </Box>
        <Flex mt='16px' alignItems='center'>
          <Box width={1} maxWidth={36}>
            <Skeleton height={36} borderRadius='8px' />
          </Box>
          <Box width={1} px='16px'>
            <Skeleton height={2} />
          </Box>
          <Box width={1} maxWidth={36}>
            <Skeleton height={36} borderRadius='8px' />
          </Box>
          <Box width={1} px='16px'>
            <Skeleton height={2} />
          </Box>
          <Box width={1} maxWidth={36}>
            <Skeleton height={36} borderRadius='8px' />
          </Box>
        </Flex>
        <Box maxWidth={154} mt={22}>
          <Skeleton height={27} borderRadius='6px' />
        </Box>
        <Box
          mt={16}
          sx={{
            display: 'grid',
            gridGap: '12px',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          {ITEMS_RANGE.map((key) => (
            <Box key={key}>
              <Skeleton height={65} />
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  </SkeletonProvider>
);

export const RightSideSkeleton = () => (
  <SkeletonProvider bg='#E6E9EF'>
    <Box pt={56} pl={[0, 0, 23, 46]} pr={[0, 0, 23, 0]}>
      <Box width={94} mb='12px'>
        <Skeleton height={38} />
      </Box>
      <Box width={1} mb={24}>
        <Skeleton height={227} />
      </Box>
      <Box width={1} mb='12px'>
        <Skeleton height={27} />
      </Box>
      <Box width={1}>
        <Skeleton height={66} />
      </Box>
    </Box>
  </SkeletonProvider>
);

export const CreateQrCodePageSkeleton = () => (
  <EditorLayout rightSlot={<RightSideSkeleton />}>
    <LeftSideSkeleton />
  </EditorLayout>
);
