import searchIcon from 'assets/images/search.svg';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { Button } from 'ui/button';

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const InputUI = styled.input`
  flex-basis: calc(100% - 37px);
  color: ${({ theme }) => theme.colors.white.main};
  background-color: transparent;
  padding: 11px 16px;
  border: none;
  width: 100%;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.white.main};
  }
`;

const Border = styled.span`
  background-color: ${({ theme }) => theme.colors.white.main};
  position: absolute;
  right: 46px;
  bottom: 2px;
  width: 0;
  height: 2px;
  transition: width 0.3s;

  ${mq.sm} {
    bottom:0;
  }

  ${InputUI}:hover+&, ${InputUI}:focus+&, ${InputUI}.filled+& {
    width: calc(100% - 48px);
  }
`;

const Icon = styled.span`
  background-image: url(${searchIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 21px;
  height: 21px;
  background-color: transparent;
  background-size: 21px 21px;
`;

const IconButton = styled(Button)`
  height: 37px;
  padding: 0 8px;
`;

export type SearchInputProps = {
  onChange: (event: any) => void;
  onSubmit: (value: string) => void;
};

export const SearchInput = ({
  onChange,
  onSubmit,
  ...rest
}: SearchInputProps) => {
  const [value, setValue] = useState('');
  const [filled, setFilled] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    inputRef?.current?.focus();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilled(Boolean(event.target.value));
    onChange(event.target.value);
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    const v = value;

    onSubmit(v);
    setValue('');
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Root>
      <InputUI
        className={filled ? 'filled' : ''}
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        {...rest}
      />
      <Border />
      <IconButton onClick={filled ? handleSubmit : onButtonClick}>
        <Icon />
      </IconButton>
    </Root>
  );
};
