import { Flex, Box } from 'rebass/styled-components';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  required,
  minLength,
  maxLength,
} from 'modules/utils/validators';

const FATextField = fieldAdapter(TextField);

export type ResetPasswordFormValuesType = {
  password: string;
};

type ResetPasswordFormProps = {
  disabled?: boolean;
  onSubmit: (args: ResetPasswordFormValuesType) => void;
};

export const ResetPwdForm = ({
  onSubmit,
  disabled,
}: ResetPasswordFormProps) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <Flex flexDirection='column' as='form' onSubmit={handleSubmit}>
        <Box mb='32px'>
          <Field
            id='password'
            type='password'
            name='password'
            component={FATextField}
            label='Password'
            placeholder='Password'
            validate={composeValidators(required, minLength(6), maxLength(200))}
          />
        </Box>
        <Button
          variant='filled'
          type='submit'
          disabled={disabled || submitting || pristine}
        >
          Create
        </Button>
      </Flex>
    )}
  />
);
