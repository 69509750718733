import get from 'lodash/fp/get';
import { RootState } from 'store/index';

export const selectOpenedModal = (state: RootState) => get('app.modal', state);
export const selectLoadingStatus = (state: RootState) =>
  get('app.loading', state);
export const selectHasMoreStatus = (state: RootState) =>
  get('app.hasMore', state);
export const selectError = (state: RootState) => get('app.error', state);
export const selectSuccess = (state: RootState) => get('app.success', state);
export const selectAfterSignup = (state: RootState) => get('app.afterSignup', state);
export const selectUserConfirmed = (state: RootState) => get('app.confirmed', state);
