import uniq from 'lodash/uniq';
import { createSlice } from '@reduxjs/toolkit';
import { putFolders, removeFolder, removeAllFolders } from '../actions/folders';
import { Folder } from 'types';

export interface FoldersState {
  ids: number[];
  byIds: {
    [id: number]: Folder | undefined;
  };
}

const initialState: FoldersState = {
  ids: [],
  byIds: {},
};

export const foldersSlice = createSlice({
  name: 'folders',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [putFolders.type]: (state, { payload: folders }: { payload: Folder[] }) => {
      const newIds = folders.map((f) => f.id);
      const newFolders = folders.reduce(
        (acc, f) => ({ ...acc, [f.id]: f }),
        {}
      );

      return {
        ids: uniq(state.ids.concat(newIds)),
        byIds: { ...state.byIds, ...newFolders },
      };
    },
    [removeAllFolders.type]: () => initialState,
    [removeFolder.type]: (state, { payload: id }: { payload: number }) => ({
      ids: state.ids.filter((i) => i !== id),
      byIds: { ...state.byIds, [id]: undefined },
    }),
  },
});
