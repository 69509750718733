import { useState } from 'react';
import { connect } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Dispatch } from 'store';
import { createInviteRoutinePromiseCreator } from 'store/actions/user';
import { Card } from 'ui/card';
import { IntiveUserValueType, InviteUserForm } from 'ui/forms';
import { Typography } from 'ui/typography';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  invite: bindPromiseCreators(createInviteRoutinePromiseCreator, dispatch),
});

const enhance = connect(null, mapDispatchToProps);

type Props = {
  onClose: () => void;
  invite: (args: { email: string }) => PromiseLike<void>;
};

export const InviteUserModal = enhance(({ onClose, invite }: Props) => {
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = async (v: IntiveUserValueType) => {
    try {
      setError(undefined);
      await invite(v);
      onClose();
    } catch (e) {
      setError(e.message || e);
    }
  };

  return (
    <Card>
      <Box p={24} width={[1, 1, 522]}>
        <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
          Invite a new user
        </Typography>
        <InviteUserForm onSubmit={handleSubmit} onClose={onClose} />
        {error && (
          <Box mt={16}>
            <Typography variant='footnote' color='red.main' textAlign='center'>
              {error}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
});
