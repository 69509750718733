import { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Error } from 'ui/labels';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { InputUI, Label, Root } from 'ui/input';
import { Item, List } from 'ui/list';
import { Typography } from 'ui/typography';
import { CategoryOptionsDropdown } from 'ui/dropdown/CategoryOptionsDropdown';
import { CategoryDropdown } from './CategoryDropdown';
import { themeGet } from 'theme';
import { Category } from 'types';

import addIcon from 'assets/images/plus-blue.svg';
import removeIcon from 'assets/images/close.svg';
import arrowIcon from 'assets/images/down-arrow.svg';

const ToggleInputWrapper = styled(InputUI)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

const SubCategoryLabel = styled.div`
  background: ${themeGet('colors.blue.mediumLight')};
  border-radius: 8px;
  padding: 8px 12px;
  margin-top: 12px;
  font-size: 10px;
  line-height: 15px;
  color: ${themeGet('colors.gray.black')};
  display: flex;
  align-items: center;
`;

type ToggleProps = {
  selected?: string;
  placeholder: string;
};

const ToggleButton = ({ selected, placeholder, ...props }: ToggleProps) => (
  <Button {...props} width='100%'>
    <ToggleInputWrapper as='div'>
      {selected ? (
        <Typography
          variant='subhead'
          color='gray.black'
          textAlign='left'
          lineHeight='1.15'>
          {selected}
        </Typography>
      ) : (
        <Typography
          variant='subhead'
          color='gray.medium'
          textAlign='left'
          lineHeight='1.15'>
          {placeholder}
        </Typography>
      )}

      <Icon url={arrowIcon} ml='10px' height='8px' width='14px' />
    </ToggleInputWrapper>
  </Button>
);

type Props = {
  id: string;
  index: number;
  onChange: (idx: number, value: any) => void;
  onCreate: (index: number) => void;
  onAddSubCategory: (index: number) => void;
  onRemoveSubCategory: (index: number, subIndex: number) => void;
  onMoveUp: (id: number) => void;
  onMoveDown: (id: number) => void;
  onDelete: (id: number) => void;
  categoryName: string;
  placeholder?: string;
  errorText?: string;
  label?: string;
  categories: Category[];
  subcategories?: Array<{ categoryName: string }>;
};

export const SelectCategory = ({
  id,
  index,
  categoryName,
  onChange,
  onAddSubCategory,
  onRemoveSubCategory,
  onCreate,
  onMoveUp,
  onMoveDown,
  onDelete,
  placeholder = 'Select',
  errorText,
  label,
  categories,
  subcategories,
}: Props) => {
  const [isOpen, onToggle] = useState(false);
  const [isOptionDropdownOpen, onOptionDropdownToggle] = useState(false);
  const handleItemClick = (item: Category) => {
    onChange(index, { categoryName: item.label, weight: 0 });
    onToggle(false);
  };

  return (
    <Flex width={1} flexWrap='wrap'>
      <Flex width={1}>
        <Box width={1}>
          <Root>
            {label && <Label htmlFor={id}>{label}</Label>}
            <Dropdown
              fullWidth
              position={{ top: 'calc(100% + 8px)', left: 0 }}
              toggleSlot={
                <ToggleButton
                  placeholder={placeholder}
                  selected={categoryName}
                />
              }
              isOpen={isOpen}
              onToggle={onToggle}
              block>
              <Card>
                <Box p='8px 0' width={1}>
                  <List>
                    <Item onClick={() => onCreate(index)}>
                      <Icon
                        url={addIcon}
                        height='14px'
                        mr='10px'
                        backgroundPosition='center'
                      />
                      <Typography
                        textAlign='left'
                        variant='subhead'
                        color='blue.main'
                        fontWeight='normal'>
                        Add category
                      </Typography>
                    </Item>
                    {categories.map((item: Category) => (
                      <CategoryDropdown
                        key={item.id}
                        categories={item.children}
                        root={item}
                        onChange={handleItemClick}
                      />
                    ))}
                    {categories.length === 0 && (
                      <Item>
                        <Typography
                          textAlign='left'
                          variant='subhead'
                          color='gray.black'
                          fontWeight='normal'>
                          No categories
                        </Typography>
                      </Item>
                    )}
                  </List>
                </Box>
              </Card>
            </Dropdown>
            {errorText && <Error>{errorText}</Error>}
          </Root>
        </Box>
        <Box ml='12px'>
          <CategoryOptionsDropdown
            isOpen={isOptionDropdownOpen}
            onToggle={onOptionDropdownToggle}
            onAddNewSubCategory={() => onAddSubCategory(index)}
            onDelete={() => onDelete(index)}
            onMoveDown={() => onMoveUp(index)}
            onMoveUp={() => onMoveDown(index)}
          />
        </Box>
      </Flex>
      {subcategories && (
        <Flex width={1} flexWrap='wrap'>
          {subcategories.map((sc, subIndex) => (
            <Box mr='12px'>
              <SubCategoryLabel>
                {sc.categoryName}
                <Box ml='12px'>
                  <Button onClick={() => onRemoveSubCategory(index, subIndex)}>
                    <Icon url={removeIcon} width='9px' height='9px' />
                  </Button>
                </Box>
              </SubCategoryLabel>
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
