import { FC } from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Card } from 'ui/card';

const ImageContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 94vh;
  border-radius: 8px;
  line-height: 0;
  overflow: hidden;

  & img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
  }
`;

type ImageModalProps = {
  options: {
    url: string;
    title?: string;
  };
};

export const ImageModal: FC<ImageModalProps> = ({
  options: { url, title },
}) => {
  return (
    <Card>
      <Box>
        <ImageContainer>
          <img src={url} alt={title ? title : ''} />
        </ImageContainer>
      </Box>
    </Card>
  );
};
