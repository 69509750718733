import { createGlobalStyle } from 'styled-components';
import { fonts } from './typography';

export const Global = createGlobalStyle`
  html {
    font-size: 14px;
    box-sizing: border-box;
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    font-family: ${fonts.poppins};
  }
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;
