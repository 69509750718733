import qs from 'qs';

export const parse = <
  T extends { [key: string]: string | string[] | number | undefined }
>(
  search: string,
  options = { ignoreQueryPrefix: true }
) => {
  return qs.parse(search, options) as T;
};

/*
  return: `?field=value...`
*/
export const stringify = (obj: {}, options = {}) => {
  return qs.stringify(obj, { addQueryPrefix: true, ...options });
};
