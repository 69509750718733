import {
  BULK_URL_TYPE,
  EMAIL_TYPE,
  FEEDBACK_TYPE,
  IMAGE_TYPE,
  PDF_TYPE,
  SMS_TYPE,
  SOCIAL_MEDIA_TYPE,
  TEXT_TYPE,
  URL_TYPE,
  VCARD_TYPE,
  WHATSAPP_TYPE,
} from './qrCodeTypes';
import {
  ROUTE_ANALYTICS,
  ROUTE_ARCHIVE,
  ROUTE_FOLDERS,
  ROUTE_ROOT,
} from './routes';

export const UI_HEADER_HEIGHT = '60px';
export const UI_HEADER_HEIGHT_MOBILE = '48px';

export const UI_HEADER_LINKS = [
  {
    link: ROUTE_ROOT,
    title: 'QR Codes',
  },
  {
    link: ROUTE_ANALYTICS,
    title: 'Analytics',
  },
  {
    link: ROUTE_FOLDERS,
    title: 'Folders',
    exact: false,
  },
  {
    link: ROUTE_ARCHIVE,
    title: 'Archive',
  },
];

export const UI_SORT_OPTIONS = [
  { value: 'newest', title: 'Newest' },
  { value: 'oldest', title: 'Oldest' },
  { value: 'scans', title: 'Most scanned' },
  { value: 'name', title: 'Name' },
];

export const UI_FILTERS_OPTIONS = [
  { value: 'all', title: 'All' },
  { value: URL_TYPE, title: 'URL' },
  { value: TEXT_TYPE, title: 'Text' },
  { value: EMAIL_TYPE, title: 'Email' },
  { value: SMS_TYPE, title: 'SMS' },
  { value: WHATSAPP_TYPE, title: 'WhatsApp' },
  { value: VCARD_TYPE, title: 'vCard' },
  { value: SOCIAL_MEDIA_TYPE, title: 'Social Media' },
  { value: FEEDBACK_TYPE, title: 'Feedback' },
  { value: IMAGE_TYPE, title: 'Images' },
  { value: PDF_TYPE, title: 'PDF' },
];

export const UI_TYPE_OPTIONS = [
  { value: URL_TYPE, title: 'URL' },
  { value: TEXT_TYPE, title: 'Text' },
  { value: EMAIL_TYPE, title: 'Email' },
  { value: SMS_TYPE, title: 'SMS' },
  { value: WHATSAPP_TYPE, title: 'WhatsApp' },
  { value: VCARD_TYPE, title: 'vCard' },
  { value: SOCIAL_MEDIA_TYPE, title: 'Social Media' },
  { value: FEEDBACK_TYPE, title: 'Feedback' },
  { value: BULK_URL_TYPE, title: 'Bulk URL' },
  { value: IMAGE_TYPE, title: 'Images' },
  { value: PDF_TYPE, title: 'PDF' },
];

export const UI_MAP_TYPE_TO_TITLE: { [key: string]: string } = {
  [URL_TYPE]: 'URL',
  [TEXT_TYPE]: 'Text',
  [EMAIL_TYPE]: 'Email',
  [SMS_TYPE]: 'SMS',
  [WHATSAPP_TYPE]: 'WhatsApp',
  [VCARD_TYPE]: 'vCard',
  [SOCIAL_MEDIA_TYPE]: 'Social Media',
  [FEEDBACK_TYPE]: 'Feedback',
  [BULK_URL_TYPE]: 'Bulk URL',
  [IMAGE_TYPE]: 'Images',
  [PDF_TYPE]: 'PDF',
};

export const UI_DOWNLOAD_TYPE = [
  { value: 'svg', title: 'SVG' },
  { value: 'png', title: 'PNG' },
  { value: 'jpg', title: 'JPEG' },
];

export const UI_PERIOD_FOR_UPGRADE: { [key: string]: number } = {
  monthly: 1,
  annual: 12,
};

export const UI_PLAN_TABS = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'annual', label: 'Annual' },
];

export const UI_PERIOD: { [key: string]: string } = {
  monthly: '/mo',
  annual: '/yr',
};

export const UI_TIME_FILTER = [
  {
    value: 'all',
    label: 'All time',
  },
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'dates',
    label: 'Dates',
  },
];
