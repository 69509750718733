import { all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import appSaga from './app';
import authSaga from './auth';
import foldersSaga from './folders';
import userSaga from './user';
import planSaga from './plans';
import qrCodesSaga from './qrCodes';
import feedbacksSaga from './feedbacks';

export function* rootSaga() {
  yield all([
    appSaga(),
    authSaga(),
    foldersSaga(),
    userSaga(),
    planSaga(),
    routinePromiseWatcherSaga(),
    qrCodesSaga(),
    feedbacksSaga(),
  ]);
}
