import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Dispatch } from 'store';
import { createCardRoutinePromiseCreator } from 'store/actions/user';
import { Card } from 'ui/card';
import { CardForm, CardFormValuesType } from 'ui/forms/CardForm';
import { Typography } from 'ui/typography';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCard: bindPromiseCreators(createCardRoutinePromiseCreator, dispatch),
});

const enhance = connect(null, mapDispatchToProps);

type Props = {
  onClose: () => void;
  createCard: (args: { tokenId: string }) => PromiseLike<void>;
};

export const AddCardModal = enhance(({ onClose, createCard }: Props) => {
  const [error, setError] = useState<string | undefined>();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (v: CardFormValuesType) => {
    try {
      setError(undefined);

      if (!stripe || !elements) {
        throw new Error('Stripe is not initialized');
      }

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const data = {
          name: v.fullName,
        };
        const { error, token } = await stripe.createToken(cardElement, data);

        if (token) {
          await createCard({ tokenId: token.id });
        } else {
          throw new Error(error?.message);
        }

        onClose();
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Box width={[1, 1, '518px']}>
      <Card>
        <Box p={24}>
          <Box mb='12px'>
            <Typography variant={['h3Mobile', 'h3']}>Add a new card</Typography>
          </Box>
          <CardForm
            isSkippable={false}
            buttonText={'Save card'}
            onSubmit={handleSubmit}
          />
          {error && (
            <Box mt={16}>
              <Typography
                variant='footnote'
                color='red.main'
                textAlign='center'
              >
                {error}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
});
