import styled from 'styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { SOCIAL_MEDIAS } from 'const';

import ellipsisIcon from 'assets/images/three-dots-gray-black.svg';

const Root = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 44px);
`;

const Content = styled.div`
  padding: 12px 16px;
  min-width: 312px;
`;

const List = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 44px);
`;

const IconButton = styled(Button)`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

const IconButtonWrapper = styled.div`
  position: relative;
`;

const ToggleButton = (props: {}) => (
  <IconButtonWrapper>
    <IconButton bg='gray.light' {...props}>
      <Icon
        url={ellipsisIcon}
        width='20px'
        height='20px'
        backgroundPosition='center'
      />
    </IconButton>
  </IconButtonWrapper>
);

type SocialMediaOption = {
  type: string;
  title: string;
  url?: string;
  logo: string;
};

type Props = {
  isOpen: boolean;
  onToggle: (v: boolean) => void;
  onClick: (v: SocialMediaOption) => void;
};

export const SocialMediaArraySelect = ({
  isOpen,
  onToggle,
  onClick,
}: Props) => {
  const firstIcons = SOCIAL_MEDIAS.slice(0, 8);
  const restIcons = SOCIAL_MEDIAS.slice(8);

  return (
    <Root>
      {firstIcons.map((item: SocialMediaOption) => (
        <IconButtonWrapper key={item.type}>
          <IconButton
            title={item.title}
            bg='gray.light'
            onClick={() => {
              onClick(item);
            }}>
            <Icon
              url={item.logo}
              width='20px'
              height='20px'
              backgroundPosition='center'
            />
          </IconButton>
        </IconButtonWrapper>
      ))}
      <Dropdown
        position={{ top: 'calc(100% + 8px)', right: 0 }}
        toggleSlot={<ToggleButton />}
        isOpen={isOpen}
        onToggle={onToggle}
        block>
        <Card>
          <Content>
            <List>
              {restIcons.map((item: SocialMediaOption) => (
                <IconButtonWrapper key={item.type}>
                  <IconButton
                    title={item.title}
                    bg='gray.light'
                    onClick={() => {
                      onClick(item);
                    }}>
                    <Icon
                      url={item.logo}
                      width='20px'
                      height='20px'
                      backgroundPosition='center'
                    />
                  </IconButton>
                </IconButtonWrapper>
              ))}
            </List>
          </Content>
        </Card>
      </Dropdown>
    </Root>
  );
};
