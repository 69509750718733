import { useState, useRef } from 'react';
import useClickAway from 'react-use/esm/useClickAway';

export const useDropdown = (
  isOpen: boolean,
  onToggle: (v: boolean) => void
) => {
  const [closingWithClick, setClosingWithClick] = useState(false);

  const onMouseDown = () => {
    if (isOpen) {
      setClosingWithClick(true);
    }
  };

  const onClick = () => {
    if (!isOpen && closingWithClick) {
      setClosingWithClick(false);

      return;
    }

    onToggle(!isOpen);
  };

  const onDismiss = () => onToggle(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickAway(dropdownRef, onDismiss);

  const toggleProps = {
    onClick,
    onMouseDown,
    // It's needed for mobile devices
    onTouchStart: onMouseDown,
  };

  return [toggleProps, dropdownRef];
};
