import { ROUTE_LOGIN, ROUTE_SIGN_UP } from 'const/routes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollTopRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== ROUTE_SIGN_UP && pathname !== ROUTE_LOGIN) {
      window.scrollTo(0, 0);
    }
    return;
  }, [pathname]);

  return null;
};
