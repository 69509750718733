import closeButton from 'assets/images/close.svg';
import folderIcon from 'assets/images/folder-gray.svg';
import plusIcon from 'assets/images/plus-blue.svg';
import { MODAL_CREATE_FOLDER, MODAL_SAVE_TO_FOLDER } from 'const';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from 'rebass/styled-components';
import { RootState } from 'store';
import {
  fetchFolders,
  FetchFoldersActionPayload,
  removeAllFolders,
} from 'store/actions/folders';
import {
  AddToFolderActionPayload,
  addToFolderQrCodes,
} from 'store/actions/qrCodes';
import { selectError, selectLoadingStatus } from 'store/selectors/app';
import { selectFolders } from 'store/selectors/folders';
import styled from 'styled-components';
import { Folder, QrCodeListItem } from 'types';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Icon } from 'ui/icon';
import { Item, List } from 'ui/list';
import { Typography } from 'ui/typography';
import { useModal } from '../index';
const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button p='8px' onClick={onClick}>
    <Icon url={closeButton} width={12} height={12} />
  </Button>
);

const ScrollableList = styled(List)`
  height: 300px;
  overflow-y: auto;
`;

const mapStateToProps = (state: RootState) => ({
  folders: selectFolders(state),
  loading: selectLoadingStatus(state),
  error: selectError(state),
});

const mapDispatchToProps = {
  fetchItems: fetchFolders,
  removeItems: removeAllFolders,
  addToFolder: addToFolderQrCodes,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  onClose: () => void;
  fetchItems: (args: FetchFoldersActionPayload) => void;
  removeItems: () => void;
  addToFolder: (args: AddToFolderActionPayload) => void;
  folders: Folder[];
  loading: boolean;
  error: boolean;
  options: { qrCode: QrCodeListItem };
};

export const SaveToFolderModal = enhance(
  ({
    onClose,
    fetchItems,
    removeItems,
    addToFolder,
    folders,
    loading,
    error,
    options,
  }: Props) => {
    const { qrCode } = options;
    const folderId = qrCode.folder_id;
    const codeIds = useMemo(
      () => (Array.isArray(qrCode) ? qrCode.map((it) => it.id) : [qrCode.id]),
      [qrCode]
    );

    const { showModal } = useModal();
    const preSelectedFolder = useMemo(
      () => folders.find((f) => f.id === folderId),
      [folderId, folders]
    );
    const [selected, setSelected] = useState<Folder | undefined>(
      preSelectedFolder
    );

    useEffect(() => {
      if (!selected && preSelectedFolder) {
        setSelected(preSelectedFolder);
      }
    }, [preSelectedFolder, selected]);

    useEffect(() => {
      fetchItems({ perPage: 100, page: 1 });

      return () => {
        removeItems();
      };
    }, []);

    const goToCreateFolderModal = () => {
      showModal(MODAL_CREATE_FOLDER, {
        previousModal: MODAL_SAVE_TO_FOLDER,
        previousOptions: options,
      });
    };

    const handleAddToFolder = () => {
      if (selected) {
        addToFolder({
          ids: codeIds,
          folderId: selected.id,
          folderName: selected.name,
        });
        onClose();
      }
    };

    return (
      <Card>
        <Box p={24} width={[1, 1, 340]} minHeight={[1, 1, 392]} height='100%'>
          <Flex alignItems='center' justifyContent='space-between'>
            <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
              Save to folder
            </Typography>
            <Box>
              <CloseButton onClick={onClose} />
            </Box>
          </Flex>
          <Box>
            <Box my='12px' px='14px'>
              <Button onClick={goToCreateFolderModal}>
                <Icon url={plusIcon} width='10px' height='10px' mr='14px' />
                <Typography
                  fontSize='12px'
                  lineHeight='18px'
                  color='gray.black'
                >
                  Add new folder
                </Typography>
              </Button>
            </Box>
            <ScrollableList>
              {folders.map((f) => (
                <Item
                  className={selected?.id === f.id ? 'selected' : ''}
                  key={f.id}
                  onClick={() => setSelected(f)}
                >
                  <Typography
                    fontSize='12px'
                    lineHeight='18px'
                    color='gray.black'
                  >
                    <Icon
                      width='13px'
                      height='12px'
                      mr='14px'
                      url={folderIcon}
                    />
                    {f.name}
                  </Typography>
                </Item>
              ))}
            </ScrollableList>
          </Box>
          <Box mt={24}>
            <Button
              variant='filled'
              onClick={handleAddToFolder}
              disabled={!selected && loading}
            >
              Move
            </Button>
          </Box>
          {error && (
            <Box mt={16}>
              <Typography
                variant='footnote'
                color='red.main'
                textAlign='center'
              >
                {error}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }
);
