import folderIcon from 'assets/images/folder-gray.svg';
import addIcon from 'assets/images/plus-blue.svg';
import {
  MODAL_CREATE_FOLDER,
  QR_CODE_BULK_DELIMETER_OPTIONS,
  QR_CODE_BULK_OPTIONS,
} from 'const';
import get from 'lodash/get';
import { useModal } from 'modules/modal';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { required, qrCodeTypeValidation} from 'modules/utils/validators';
import { memo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { Folder } from 'types';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { FileUpload } from 'ui/input/FileUpload';
import { Error } from 'ui/labels';
import { Select } from 'ui/select';
import { Typography } from 'ui/typography';
import { useSelector } from "react-redux";
import { selectCurrentPlan } from "store/selectors/user";
import { customFieldAdapter } from "modules/utils/createCustomAdapter";
import { QrCodeTypeSelect } from "ui/qr_code_type_select";

const FILE_LINK = process.env.REACT_APP_NEW_BULK_FILE_LINK;

const FASelectField = fieldAdapter(Select);
const FASelectQrCodeTypeField = customFieldAdapter(QrCodeTypeSelect);

type ButtonProps = {
  onClick: () => void;
  value?: File | null;
  error?: string;
  touched: boolean;
  folders: Folder[];
};

const mapFolderToOption = (folder: Folder) => ({
  value: folder.id,
  title: (
    <Typography fontSize='12px' lineHeight='18px' color='gray.black'>
      <Icon width='13px' height='12px' mr='14px' url={folderIcon} />
      {folder.name}
    </Typography>
  ),
});

const FirstItem = () => (
  <Typography
    textAlign='left'
    variant='footnote'
    color='gray.black'
    fontWeight='normal'
  >
    <Icon url={addIcon} width='13px' height='12px' mr='14px' />
    Add new folder
  </Typography>
);

const UploadButton = ({ onClick, error, touched }: ButtonProps) => (
  <Box>
    <Button variant='filled' onClick={onClick}>
      Upload
    </Button>
    {touched && error && <Error>{error}</Error>}
  </Box>
);

type Props = {
  folders: Folder[];
};

export const BulkUploadSubform = memo(({ folders }: Props) => {
  const { change } = useForm();
  const { values } = useFormState();
  const file: File | undefined = get(values, 'file');
  const fileName = file?.name;
  const { showModal } = useModal();
  const currentPlan = useSelector(selectCurrentPlan);

  const resetFile = () => {
    change('file', undefined);
  };

  const changeFolder = ({ id }: { id: number }) => {
    change('folder_id', id);
  };

  const onCreate = () => {
    showModal(MODAL_CREATE_FOLDER, { onSubmit: changeFolder });
  };

  return (
    <Box>
      <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
        Upload bulk QR Codes{' '}
        <Typography
          as='a'
          href={FILE_LINK}
          download
          textDecoration='none'
          variant='subhead'
          color='blue.main'
        >
          file example
        </Typography>
      </Typography>
      <Box
        mt='16px'
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          columnGap: '12px',
          rowGap: '16px',
        }}
      >
        <Field
          id='bulkFile'
          name='file'
          component={FileUpload}
          triggerSlot={UploadButton}
          validate={required}
          accept='.csv'
        />
        <Box>
          {file && (
            <>
              <Box mb='4px'>
                <Typography variant='footnote'>{fileName}</Typography>
              </Box>
              <Box>
                <Button onClick={resetFile}>
                  <Typography variant='subhead' color='red.text'>
                    Reset
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Box sx={{ gridColumn: '1 / 3' }}>
          <Field
            id='csvOption'
            name='csv_option'
            type='csvOption'
            component={FASelectQrCodeTypeField}
            label='Type'
            options={QR_CODE_BULK_OPTIONS}
            validate={qrCodeTypeValidation(currentPlan)}
            currentPlan={currentPlan}
          />
        </Box>
        <Box sx={{ gridColumn: '1 / 3' }}>
          <Field
            id='csvDelimeter'
            name='csv_delimiter'
            component={FASelectField}
            label='Choose CSV-delimiter'
            options={QR_CODE_BULK_DELIMETER_OPTIONS}
            validate={required}
          />
        </Box>
        <Box sx={{ gridColumn: '1 / 3' }}>
          <Field
            id='folder_id'
            name='folder_id'
            component={FASelectField}
            label='Folder*'
            placeholder='Select'
            options={folders.map(mapFolderToOption)}
            validate={required}
            firstItem={<FirstItem />}
            onFirstItemClick={onCreate}
            overflow
          />
        </Box>
      </Box>
    </Box>
  );
});
