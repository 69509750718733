import get from 'lodash/get';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { required } from 'modules/utils/validators';
import { memo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { EdgesDropdown } from 'ui/dropdown/EgdesDropdown';
import ColorInput from 'ui/input/ColorInput';
import { SwitchInput } from 'ui/input/SwitchInput';
import { Typography } from 'ui/typography';

const FullRow = styled(Box)`
  grid-column: 1 / 3;
`;

const EDGE_OPTIONS = [
  {
    value: true,
    label: 'Basic',
  },
  { value: false, label: 'Advanced' },
];

const FAColorInput = fieldAdapter(ColorInput);
const FAEdgeInput = fieldAdapter(EdgesDropdown);
const FASwitchField = fieldAdapter(SwitchInput);

export const ModifyDesignCodeSubform = memo(() => {
  const form = useFormState();
  const basicEdges: boolean = get(form, 'values.basic_edges');

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: '12px',
        rowGap: '16px',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <FullRow>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Code
        </Typography>
      </FullRow>
      <Box>
        <Field
          id='color'
          name='qr_color'
          label='Code'
          component={FAColorInput}
          validate={required}
        />
      </Box>
      <Box>
        <Field
          id='bg'
          name='background_color'
          label='Background'
          component={FAColorInput}
          validate={required}
        />
      </Box>
      <FullRow>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Edges
        </Typography>
      </FullRow>
      <FullRow>
        <Field
          id='basicEdges'
          name='basic_edges'
          component={FASwitchField}
          tabs={EDGE_OPTIONS}
          buttonProps={{ py: '8px' }}
        />
      </FullRow>
      {basicEdges === EDGE_OPTIONS[0].value && (
        <FullRow>
          <Box
            sx={{
              display: 'grid',
              columnGap: '12px',
              rowGap: '14px',
              gridTemplateColumns: ['1fr', '96px 1fr 1fr'],
            }}
          >
            <Field
              id='findersType'
              name='all_edge_shape'
              label='Type'
              component={FAEdgeInput}
            />
            <Field
              id='inner'
              name='all_edge_inner_color'
              label='Inner'
              component={FAColorInput}
            />
            <Field
              id='outer'
              name='all_edge_outer_color'
              label='Outer'
              component={FAColorInput}
            />
          </Box>
        </FullRow>
      )}
      {basicEdges === EDGE_OPTIONS[1].value && (
        <>
          <FullRow>
            <Box
              sx={{
                display: 'grid',
                columnGap: '12px',
                rowGap: '14px',
                gridTemplateColumns: ['1fr', '96px 1fr 1fr'],
                gridTemplateRows: 'repeat(3, 1fr)',
              }}
            >
              <Field
                id='findersType2'
                name='top_left_edge_shape'
                label='Top Left'
                component={FAEdgeInput}
              />
              <Field
                id='inner2'
                name='top_left_edge_inner_color'
                label='Inner'
                component={FAColorInput}
              />
              <Field
                id='outer2'
                name='top_left_edge_outer_color'
                label='Outer'
                component={FAColorInput}
              />
              <Field
                id='findersType1'
                name='top_right_edge_shape'
                label='Top right'
                component={FAEdgeInput}
              />
              <Field
                id='inner1'
                name='top_right_edge_inner_color'
                label='Inner'
                component={FAColorInput}
              />
              <Field
                id='outer1'
                name='top_right_edge_outer_color'
                label='Outer'
                component={FAColorInput}
              />
              <Field
                id='findersType3'
                name='bottom_left_edge_shape'
                label='Bottom left'
                component={FAEdgeInput}
              />
              <Field
                id='inner3'
                name='bottom_left_edge_inner_color'
                label='Inner'
                component={FAColorInput}
              />
              <Field
                id='outer3'
                name='bottom_left_edge_outer_color'
                label='Outer'
                component={FAColorInput}
              />
            </Box>
          </FullRow>
        </>
      )}
    </Box>
  );
});
