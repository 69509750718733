import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export const Card = styled.div<LayoutProps>`
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  ${layout};
`;
