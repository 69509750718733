import { ROUTE_ROOT } from 'const';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Typography } from 'ui/typography';

export const Page404 = ({ history }: RouteComponentProps) => (
  <Flex flexDirection='column' alignItems='center' pt={80}>
    <Typography variant={['h1Mobile', 'h1']} color='blue.main'>
      404
    </Typography>
    <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
      Page not found.
    </Typography>
    <Box mt={24}>
      <Button variant='filled' onClick={() => history.push(ROUTE_ROOT)}>
        Go to Dashboard
      </Button>
    </Box>
  </Flex>
);
