import { Box } from 'rebass/styled-components';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  required,
  maxLength,
} from 'modules/utils/validators';

const FATextField = fieldAdapter(TextField);

export type IntiveUserValueType = {
  email: string;
};

type Props = {
  onClose: () => void;
  onSubmit: (v: IntiveUserValueType) => Promise<void>;
};

export const InviteUserForm = ({ onClose, onSubmit }: Props) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine, submitting, invalid }) => (
      <Box mt='12px' as='form' onSubmit={handleSubmit}>
        <Box mb='16px'>
          <Field
            id='email'
            name='email'
            type='email'
            component={FATextField}
            label='Email address'
            placeholder='name@gmail.com'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box
          mt={40}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '12px',
          }}
        >
          <Button
            variant='filled'
            onClick={onClose}
            color='gray.black'
            backgroundColor='blue.mediumLight'
          >
            Cancel
          </Button>
          <Button
            variant='filled'
            type='submit'
            disabled={pristine || submitting || invalid}
          >
            Invite
          </Button>
        </Box>
      </Box>
    )}
  />
);
