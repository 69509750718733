import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { convertPageToOffset } from 'modules/utils/convert';
import { setAppState } from '../actions/app';
import {
  createFolderRoutine,
  CreateFolderActionPayload,
  fetchFolders,
  FetchFoldersActionPayload,
  fetchFolder,
  FetchFolderActionPayload,
  putFolders,
  patchFolderRoutine,
  PatchFolderActionPayload,
  deleteFolderRoutine,
  DeleteFolderActionPayload,
  removeFolder,
} from '../actions/folders';
import {
  postFolder,
  getFolders,
  getFolder,
  deleteForder,
  patchForder,
} from 'api/folders';
import { Folder } from 'types';

function* fetchUserFoldersSaga({
  payload,
}: PayloadAction<FetchFoldersActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));

    const { limit, offset } = convertPageToOffset(
      payload.page,
      payload.perPage
    );
    const folders: Folder[] = yield call(getFolders, { limit, offset });
    const hasMore = folders.length === payload.perPage;

    yield put(putFolders(folders));

    yield put(setAppState({ loading: false, hasMore, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* fetchFolderByIdSaga({
  payload,
}: PayloadAction<FetchFolderActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));

    const { id } = payload;
    const folder: Folder = yield call(getFolder, id);

    yield put(putFolders([folder]));

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* createNewFolderSaga({
  payload,
}: PayloadAction<CreateFolderActionPayload>) {
  try {
    yield put(createFolderRoutine.request());
    const newFolder: Folder = yield call(postFolder, payload);

    yield put(putFolders([newFolder]));
    yield put(createFolderRoutine.success(newFolder));
  } catch (e) {
    yield put(createFolderRoutine.failure(e.message || e));
  } finally {
    yield put(createFolderRoutine.fulfill());
  }
}

function* editFolderSaga({ payload }: PayloadAction<PatchFolderActionPayload>) {
  try {
    yield put(patchFolderRoutine.request());
    const updatedFolder: Folder = yield call(patchForder, payload);

    yield put(putFolders([updatedFolder]));
    yield put(patchFolderRoutine.success());
  } catch (e) {
    yield put(patchFolderRoutine.failure(e.message || e));
  } finally {
    yield put(patchFolderRoutine.fulfill());
  }
}

function* deleteFolderSaga({
  payload: { id },
}: PayloadAction<DeleteFolderActionPayload>) {
  try {
    yield put(deleteFolderRoutine.request());
    yield put(removeFolder(id));
    yield call(deleteForder, id);
    yield put(deleteFolderRoutine.success());
  } catch (e) {
    yield put(deleteFolderRoutine.failure(e.message));
  } finally {
    yield put(deleteFolderRoutine.fulfill());
  }
}

export default function* watcher() {
  yield takeLatest(fetchFolder.type, fetchFolderByIdSaga);
  yield takeLatest(fetchFolders.type, fetchUserFoldersSaga);
  yield takeLatest(createFolderRoutine.TRIGGER, createNewFolderSaga);
  yield takeLatest(patchFolderRoutine.TRIGGER, editFolderSaga);
  yield takeLatest(deleteFolderRoutine.TRIGGER, deleteFolderSaga);
}
