import ajax from './utils/ajax';
import {
  mapResponseToUser,
  mapResponseToAccount,
  mapResponseToCurrentPlan,
} from './mappers/user';
import {
  CurrentUserResponse,
  CurrentAccountResponse,
  CurrentPlanResponse,
  PatchAccountRequest,
  PatchUserRequest,
} from './types';
import { CurrentUser, CurrentAccount, CurrentPlan } from 'types';

type GetCurrentUserResponse = {
  data: {
    current_user: CurrentUserResponse;
    account: CurrentAccountResponse;
  };
};

export const getCurrentUser = async (): Promise<{
  currentUser: CurrentUser;
  account: CurrentAccount;
}> => {
  const { data }: GetCurrentUserResponse = await ajax.get('/current_user/');

  return {
    account: mapResponseToAccount(data.account),
    currentUser: mapResponseToUser(data.current_user),
  };
};

type PatchPasswordRequest = {
  current: string;
  password: string;
};

export const patchPassword = async ({
  current,
  password,
}: PatchPasswordRequest) => {
  await ajax.patch('/passwords', {
    current_password: current,
    password,
  });
};

export const patchAccount = async (
  values: PatchAccountRequest
): Promise<CurrentAccount> => {
  const { data }: { data: CurrentAccountResponse } = await ajax.patch(
    '/accounts',
    {
      account: values,
    }
  );

  return mapResponseToAccount(data);
};

export const patchUser = async (
  values: PatchUserRequest
): Promise<CurrentUser> => {
  const {
    data,
  }: { data: { current_user: CurrentUserResponse } } = await ajax.patch(
    '/users',
    {
      user: values,
    }
  );

  return mapResponseToUser(data.current_user);
};

export const deleteUserAvatar = async (): Promise<CurrentUser> => {
  const {
    data,
  }: { data: { current_user: CurrentUserResponse } } = await ajax.delete(
    '/users/avatar',
  );

  return mapResponseToUser(data.current_user);
};

export const getCurrentPlan = async (): Promise<CurrentPlan> => {
  const { data }: { data: CurrentPlanResponse } = await ajax.get(
    '/plans/current'
  );

  return mapResponseToCurrentPlan(data);
};
