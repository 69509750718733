import { getOr } from './getOr';

export const getInitials = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  const firstLetter = getOr<string>(firstName, '0', '-');
  const lastLetter = getOr<string>(lastName, '0', '-');

  return firstLetter + lastLetter;
};

export const getFullName = (firstName: string = '', lastName: string = '') => {
  return `${firstName} ${lastName}`;
};
