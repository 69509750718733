import { useContext, ReactNode } from 'react';
import styled, { ThemeContext } from 'styled-components';
import SkeletonUI, {
  SkeletonTheme,
  SkeletonProps,
} from 'react-loading-skeleton';

type ProviderProps = {
  children: ReactNode;
  bg?: string;
};

export const SkeletonProvider = ({ children, bg }: ProviderProps) => {
  const themeContext = useContext(ThemeContext);
  const color = bg || themeContext.colors.gray.light2;
  const highlightColor = themeContext.colors.gray.light;

  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      {children}
    </SkeletonTheme>
  );
};

type WrapProps = { borderRadius?: string };

const Wrap = styled.div<WrapProps>`
  & span span {
    border-radius: ${({ borderRadius = '10px' }) => borderRadius};
  }
`;

type Props = SkeletonProps & WrapProps;

export const Skeleton = ({ borderRadius, ...props }: Props) => (
  <Wrap borderRadius={borderRadius}>
    <SkeletonUI {...props} />
  </Wrap>
);
