import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import { FeedbackResponse } from '../types';
import { Feedback } from 'types';

export const mapResponseToFeedback = (res: FeedbackResponse): Feedback =>
  compose(
    omit(['qr_code_id', 'full_name', 'created_at', 'updated_at']),
    (res) => ({
      ...res,
      qrCodeId: res.qr_code_id,
      fullName: res.full_name,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
    })
  )(res);
