import { memo, ReactElement } from 'react';

type Props = {
  condition: boolean;
  children: ReactElement<{}>;
};

export const When = memo(({ condition, children }: Props) =>
  condition ? children : null
);
