import { ElementType } from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  typography,
  TypographyProps as SSTypographyProps,
  variant,
} from 'styled-system';
import { TypographyVariants } from 'theme/typography';

interface TypographyComponentProps {
  variant?: TypographyVariants | TypographyVariants[];
  textDecoration?: string;
  textTransform?: string;
  as?: ElementType;
}

export type TypographyProps = TypographyComponentProps &
  ColorProps &
  SSTypographyProps;

export const Typography = styled.p<TypographyProps>`
  ${variant({
    scale: 'typography',
  })}
  ${color}
  ${typography}

  ${({ textDecoration = '' }) => `text-decoration: ${textDecoration}`};
  ${({ textTransform = '' }) => `text-transform: ${textTransform}`};
`;

Typography.defaultProps = {
  variant: 'subhead',
};
