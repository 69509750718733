import get from 'lodash/get';
import { RootState } from 'store/index';
import { Feedback, Category } from 'types';

export const selectFeedbacks = (state: RootState): Feedback[] => {
  const ids = get(state, 'feedbacks.ids', []);

  return ids.map((id: number) => get(state, `feedbacks.byIds.${id}`));
};

export const selectFeedbacksById = (id: number) => (
  state: RootState
): Feedback => {
  return get(state, `feedbacks.byIds.${id}`);
};

export const selectFCategories = (state: RootState): Category[] => {
  return get(state, 'feedbacks.categories');
};

export const selectAverageRating = (state: RootState): number | undefined => {
  return get(state, 'feedbacks.averageRating');
};
