import arrowIcon from 'assets/images/arrow-right.svg';
import { useState } from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { Category } from 'types';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { Item, List } from 'ui/list';
import { Typography } from 'ui/typography';

const ToggleItemWrapper = styled(Item)<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 44px;

  background-color: ${({ isOpen }) =>
    isOpen ? themeGet('colors.blue.light2') : themeGet('colors.white.main')};
`;

type ToggleProps = {
  selected: Category;
  isOpen: boolean;
};

const ToggleButton = ({ selected, isOpen, ...props }: ToggleProps) => (
  <Button {...props} width='100%'>
    <ToggleItemWrapper isOpen={isOpen}>
      <Typography
        textAlign='left'
        variant='subhead'
        color='gray.black'
        fontWeight='normal'
      >
        {selected.label}
      </Typography>
      <Icon url={arrowIcon} ml='10px' height='14px' width='14px' />
    </ToggleItemWrapper>
  </Button>
);

type Props = {
  onChange: (v: any) => void;
  root: Category;
  categories?: Category[];
};

export const CategoryDropdown = ({
  onChange,
  root,
  categories = [],
}: Props) => {
  const [isOpen, onToggle] = useState(false);
  const handleItemClick = (item: Category) => {
    onChange(item);
    onToggle(false);
  };

  return (
    <Dropdown
      position={{ left: ['auto', '100%'], right: ['-50px', 'auto'], bottom: 0 }}
      toggleSlot={<ToggleButton isOpen={isOpen} selected={root} />}
      isOpen={isOpen}
      onToggle={onToggle}
      block
    >
      <Card>
        <Box p='8px 0' minWidth='180px'>
          <List>
            {categories.map((item: Category) => (
              <Item onClick={() => handleItemClick(item)} key={item.id}>
                <Typography
                  textAlign='left'
                  variant='subhead'
                  color='gray.black'
                  fontWeight='normal'
                >
                  {item.label}
                </Typography>
              </Item>
            ))}
            {categories.length === 0 && (
              <Item>
                <Typography
                  textAlign='left'
                  variant='subhead'
                  color='gray.black'
                  fontWeight='normal'
                >
                  No categories
                </Typography>
              </Item>
            )}
          </List>
        </Box>
      </Card>
    </Dropdown>
  );
};
