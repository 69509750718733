import ajax from './utils/ajax';
import { mapResponseToCard } from './mappers/cards';
import { CardResponse } from './types';
import { Card } from 'types';

type CreateCardRequest = {
  tokenId: string;
};

export const createCard = async ({
  tokenId,
}: CreateCardRequest): Promise<Card> => {
  const { data }: { data: CardResponse } = await ajax.post('/cards', {
    token_id: tokenId,
  });

  return mapResponseToCard(data);
};

export type GetCardResponse = Card[];

export const getCards = async (): Promise<GetCardResponse> => {
  const { data }: { data: CardResponse[] } = await ajax.get('/cards');

  return data.filter((card) => !!card).map(mapResponseToCard);
};

export const removeCard = async ({ id }: { id: number }) => {
  await ajax.delete(`/cards/${id}`);
};
