import { useState } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Dropdown } from 'ui/dropdown';
import { Root, Label } from 'ui/input';
import { Icon } from 'ui/icon';
import { QR_CODES_EDGES } from 'const';

import arrowIcon from 'assets/images/down-arrow.svg';

export type ListItem = { image: string; type: string; value: number };

type ToggleProps = { selected?: ListItem };

const StyledButton = styled(Button)`
  border: 1px solid #e6e9ef;
  border-radius: 10px;
`;

const ToggleButton = ({ selected, ...rest }: ToggleProps) => (
  <StyledButton p='12px 16px' {...rest}>
    <Icon url={selected?.image || ''} mr='16px' height='22px' width='22px' />
    <Icon url={arrowIcon} ml='10px' height='8px' width='14px' />
  </StyledButton>
);

type Props = {
  onChange: (v: number) => void;
  value: number;
  label: string;
};

export const EdgesDropdown = ({ onChange, value, label }: Props) => {
  const selected = QR_CODES_EDGES.find((e) => e.value === value);
  const [isOpen, onToggle] = useState(false);
  const handleItemClick = (item: ListItem) => {
    onChange(item.value);
    onToggle(false);
  };

  return (
    <Root>
      <Label>{label}</Label>
      <Dropdown
        position={{ top: 'calc(100% + 8px)', left: 0 }}
        toggleSlot={<ToggleButton selected={selected} />}
        isOpen={isOpen}
        onToggle={onToggle}>
        <Card>
          <Box
            p='12px 16px'
            sx={{
              display: 'grid',
              gridGap: '16px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}>
            {QR_CODES_EDGES.map((item) => (
              <Button key={item.type} onClick={() => handleItemClick(item)}>
                <Icon url={item.image} width='22px' height='22px' />
              </Button>
            ))}
          </Box>
        </Card>
      </Dropdown>
    </Root>
  );
};
