import ajax from './utils/ajax';

type LoginRequest = {
  email: string;
  password: string;
  remember: boolean;
};

type LoginResponse = {
  data: {
    token: string;
  };
};

export const authenticate = (payload: LoginRequest): Promise<LoginResponse> => {
  return ajax.post('/login/', payload);
};

type oAuthRequest = {
  tokenId: string;
};

type oAuthResponse = string;

export const oAuthLogin = async (
  payload: oAuthRequest
): Promise<oAuthResponse> => {
  const {
    data: { token },
  } = await ajax.post('/oauth-login/', payload);

  return token;
};

export const oAuthRegister = async (
  payload: oAuthRequest
): Promise<oAuthResponse> => {
  const {
    data: { token },
  } = await ajax.post('/oauth-register/', payload);

  return token;
};

type SignUpRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  time_zone: string;
};

type SignUpResponse = {
  data: {
    token: string;
    confirmed: boolean;
  };
};

export const register = (payload: SignUpRequest): Promise<SignUpResponse> => {
  return ajax.post('/signup/', {
    user: {
      email: payload.email,
      password: payload.password,
      first_name: payload.firstName,
      last_name: payload.lastName,
      time_zone: payload.time_zone
    },
  });
};

export const requestForgotPassword = async (email: string) => {
  await ajax.post('/reset-password/', { email });
};

type ResetPasswordRequest = { password: string; token: string };

export const requestResetPassword = ({
  password,
  token,
}: ResetPasswordRequest) => {
  return ajax.post('/reset-password/confirm/', { password, token });
};
