import get from 'lodash/get';
import { RootState } from 'store/index';
import { QrCodeListItem } from 'types';

export const selectQrCodes = (state: RootState): QrCodeListItem[] => {
  const ids = get(state, 'qrCodes.ids', []);

  return ids.map((id: number) => get(state, `qrCodes.byIds.${id}`));
};

export const selectQrCodeById = (id: number) => (
  state: RootState
): QrCodeListItem => {
  const qrCode = get(state, `qrCodes.byIds.${id}`);

  return qrCode;
};

export const selectQrCodeSizesByTypes = (
  state: RootState
): { [type: string]: number } => {
  const sizes = get(state, `qrCodes.sizesByType`);

  return sizes;
};
