import ajax from './utils/ajax';
import { mapResponseToPlans } from './mappers/plans';
import { PlansResponse } from './types';
import { Plans } from 'types';

export const getPlans = async (): Promise<Plans> => {
  const { data }: { data: PlansResponse } = await ajax.get(
    '/plans'
  );
  return mapResponseToPlans(data);
};

export const updatePlan = (payload: { planId: string | undefined }) => {
  return ajax.post('/plans/activate', payload);
};

export const cancelPlanDowngrade = () => {
  return ajax.post('/plans/cancel_downgrade');
};