import { BillingProvider } from 'modules/billing';
import { intercomWidget } from 'modules/intercom';
import { Modal } from 'modules/modal';
import { MainRouter } from 'modules/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import { ThemeProvider } from 'styled-components';
import { Global, theme } from 'theme';

function App() {
  useEffect(() => {
    intercomWidget.boot();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Global />
        <BillingProvider>
          <MainRouter>
            <Modal />
          </MainRouter>
        </BillingProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
