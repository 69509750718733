import removeIcon from 'assets/images/close-red.svg';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  composeValidators,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import { Field } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);

type Props = {
  index: number;
  title: string;
  name: string;
  onRemove: (i: number) => void;
};

export const SocialMediaDetailsSubform = ({
  index,
  title,
  name,
  onRemove,
}: Props) => (
  <Box>
    <Flex justifyContent='space-between'>
      <Typography variant='body' color='gray.black'>
        {title}
      </Typography>
      <Button onClick={() => onRemove(index)}>
        <Icon url={removeIcon} width='12px' height='12px' />
      </Button>
    </Flex>
    <Box mt='16px'>
      <Field
        id={`${name}.url`}
        name={`${name}.url`}
        label='URL*'
        placeholder='http://website.com/'
        component={FATextField}
        validate={composeValidators(required, isUrl, maxLength(200))}
        format={toHttps}
        formatOnBlur
      />
    </Box>
    <Box mt='16px'>
      <Field
        id={`${name}.text`}
        name={`${name}.text`}
        label='Text*'
        component={FATextField}
        validate={composeValidators(required, maxLength(200))}
      />
    </Box>
  </Box>
);
