import { Route, Redirect, RouteProps } from 'react-router';
import { ROUTE_ROOT } from 'const';
import { useAuth } from 'modules/utils/useAuth';

export const AuthRoute = (props: RouteProps & { next?: string }) => {
  let isAuth = useAuth();

  if (isAuth) {
    return (
      <Route {...props}>
        <Redirect
          to={{
            pathname: props.next || ROUTE_ROOT,
          }}
        />
      </Route>
    );
  }

  return <Route {...props} />;
};
