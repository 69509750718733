import styled, { keyframes } from 'styled-components';
import { themeGet } from 'theme';

const rotate = keyframes`
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

type SpinnerProps = { size?: string };

export const Spinner = styled.div<SpinnerProps>`
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: ${({ size = '24px' }) => size};
  height: ${({ size = '24px' }) => size};
  border-radius: 50%;
  background: ${themeGet('colors.blue.main')};
  background: linear-gradient(
    to right,
    ${themeGet('colors.blue.main')} 10%,
    rgba(50, 120, 228, 0) 42%
  );
  position: relative;
  animation: ${rotate} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    background: #3278e4;
    border-radius: 100% 0 0 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${themeGet('colors.white.main')};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    margin: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  height: 100%;
`;

export const WrapperSpinner = (props: SpinnerProps) => (
  <Wrapper>
    <Spinner {...props} />
  </Wrapper>
);
