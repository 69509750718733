import resetIcon from 'assets/images/close-red.svg';
import noIcon from 'assets/images/forbid.svg';
import addIcon from 'assets/images/plus-gray.svg';
import { SOCIAL_MEDIA_BACKGROUNDS } from 'const';
import { memo } from 'react';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';

const Root = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(auto-fit, 90px);

  ${mq.md} {
    grid-template-columns: repeat(auto-fit, 120px);
    grid-template-rows: repeat(auto-fit, 90px);
  }
`;

const ImageButton = styled(Button)`
  width: 100%;
  height: 90px;
  background: #f7f7f7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mq.md} {
    width: 120px;
  }
`;

const ButtonTextWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const ResetButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #e6e9ef;
  box-sizing: border-box;
  border-radius: 50%;
`;

type Props = {
  value: number;
  isFile: boolean;
  file?: string;
  onChange: (v: number | undefined) => void;
  onUpload: () => void;
  onReset: () => void;
};

export const BackgroundInput = memo(
  ({ value, file, isFile, onChange, onUpload, onReset }: Props) => (
    <Root>
      {isFile && file && (
        <ButtonWrapper>
          <ImageButton>
            <Icon
              url={file}
              width='100%'
              height='100%'
              backgroundPosition='center'
              backgroundSize='cover'
              borderRadius='10px'
            />
          </ImageButton>
          <ResetButton onClick={() => onReset()}>
            <Icon url={resetIcon} width='9px' height='9px' />
          </ResetButton>
        </ButtonWrapper>
      )}

      {!isFile && (
        <ImageButton onClick={onUpload}>
          <Icon url={addIcon} width='14px' height='14px' />
          <ButtonTextWrapper>
            <Typography variant='footnote' color='gray.medium'>
              Upload
            </Typography>
          </ButtonTextWrapper>
        </ImageButton>
      )}

      <ImageButton onClick={() => onReset()}>
        <Icon url={noIcon} width='27px' height='27px' />
        <ButtonTextWrapper>
          <Typography variant='footnote' color='gray.medium'>
            No template
          </Typography>
        </ButtonTextWrapper>
      </ImageButton>
      {SOCIAL_MEDIA_BACKGROUNDS.map(
        (bg: { template: string; image_index: number }) => (
          <ButtonWrapper key={bg.image_index}>
            <ImageButton
              onClick={() => {
                onReset();
                onChange(bg.image_index);
              }}
            >
              <Icon
                url={bg.template}
                width='100%'
                height='100%'
                backgroundPosition='center'
                backgroundSize='cover'
                borderRadius='10px'
              />
            </ImageButton>
            {value === bg.image_index && (
              <ResetButton onClick={() => onReset()}>
                <Icon url={resetIcon} width='9px' height='9px' />
              </ResetButton>
            )}
          </ButtonWrapper>
        )
      )}
    </Root>
  )
);
