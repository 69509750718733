import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_FOLDER_CREATE,
  ACTION_FOLDER_PATCH,
  ACTION_FOLDER_DELETE,
  ACTION_FOLDER_PUT,
  ACTION_FOLDER_REMOVE,
  ACTION_FOLDER_REMOVE_ALL,
  ACTION_FOLDER_FETCH_ALL,
  ACTION_FOLDER_FETCH
} from 'const';
import { Folder } from 'types';

export type CreateFolderActionPayload = { name: string };
export const createFolderRoutine = createRoutine<CreateFolderActionPayload>(
  ACTION_FOLDER_CREATE
);
export const createFolderRoutinePromiseCreator = promisifyRoutine(
  createFolderRoutine
);

export type PatchFolderActionPayload = { id: number; name: string };
export const patchFolderRoutine = createRoutine<PatchFolderActionPayload>(
  ACTION_FOLDER_PATCH
);
export const patchFolderRoutinePromiseCreator = promisifyRoutine(
  patchFolderRoutine
);

export type FetchFoldersActionPayload = {
  page?: number;
  perPage?: number;
};
export const fetchFolders = createAction<FetchFoldersActionPayload>(
  ACTION_FOLDER_FETCH_ALL
);

export type FetchFolderActionPayload = {
  id: string;
};
export const fetchFolder = createAction<FetchFolderActionPayload>(
  ACTION_FOLDER_FETCH
);

export type PutFoldersActionPayload = Folder[];
export const putFolders = createAction<PutFoldersActionPayload>(
  ACTION_FOLDER_PUT
);

export type RemoveFolderActionPayload = number;
export const removeFolder = createAction<RemoveFolderActionPayload>(
  ACTION_FOLDER_REMOVE
);

export const removeAllFolders = createAction(ACTION_FOLDER_REMOVE_ALL);

export type DeleteFolderActionPayload = { id: number };
export const deleteFolderRoutine = createRoutine<DeleteFolderActionPayload>(
  ACTION_FOLDER_DELETE
);
export const deleteFolderRoutinePromiseCreator = promisifyRoutine(
  deleteFolderRoutine
);
