import { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

type ProviderProps = { children: ReactNode };

export const BillingProvider = ({ children }: ProviderProps) => (
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
        },
      ],
    }}>
    {children}
  </Elements>
);
