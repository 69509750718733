import { ROUTE_UPGRADE_PLAN } from 'const';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { mq } from 'theme/breakpoints';
import { Link } from 'ui/link';
import { Typography } from 'ui/typography';

const Root = styled.div`
  width: 100%;
  background-color: #19325e;
  padding: 4px 8px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1258px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${mq.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const UpgradeLink = styled(Link)`
  display: inline-block;
  margin-top: -16px;
  margin-left: 20px;
  padding: 3px 16px;
  background-color: ${themeGet('colors.white.main')};
  font-size: 12px;
  line-height: 18px;
  color: ${themeGet('colors.gray.black')};
  border-radius: 6px;
  text-decoration: none;

  ${mq.sm} {
    margin-top: 0;
  }
`;

const UpgradeTypography = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  days: number;
  pausedDynamicQrCodes: number;
  planName: string;
  canceled?: boolean;
};

const codesPhrase = (pausedDynamicQrCodes: number) => {
  if (pausedDynamicQrCodes > 0) {
    return `: ${pausedDynamicQrCodes} dynamic ${
      pausedDynamicQrCodes > 1 ? 'codes are' : 'code is'
    } now paused`;
  } else {
    return '';
  }
};

const buildTrialText = ({ planName, days, pausedDynamicQrCodes, canceled }: Props) => {
  if (planName === 'trial' && days > 0) {
    return days + (days > 1 ? ' days ' : ' day ') + 'left in your trial';
  } else if (planName === 'basic' && canceled) {
    return 'Your subscrption has been canceled after failed payment retries' + codesPhrase(pausedDynamicQrCodes);
  } else {
    return 'Your trial has expired' + codesPhrase(pausedDynamicQrCodes);
  }
};

export const Upgrade = ({ planName, days, pausedDynamicQrCodes, canceled = false }: Props) => (
  <Root>
    <Container>
      <Typography fontSize='10px' lineHeight='15px' color='white.main'>
        {buildTrialText({ planName, days, pausedDynamicQrCodes, canceled })}
      </Typography>
      <UpgradeTypography fontSize='10px' lineHeight='15px' color='white.main'>
        Upgrade now to get access to all features
        <UpgradeLink to={ROUTE_UPGRADE_PLAN}>Upgrade</UpgradeLink>
      </UpgradeTypography>
    </Container>
  </Root>
);
