import { TIME_ZONES } from 'const';
import sortBy from 'lodash/sortBy';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  minLength,
} from 'modules/utils/validators';
import { Field, Form } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import {
  findTimeZone,
  getUTCOffset,
} from 'timezone-support/src/index-2012-2022';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { Select } from 'ui/select';
import { Typography } from 'ui/typography';

const getTimeZones = (name: string) => {
  const tzValue = TIME_ZONES[name];
  const tz = getUTCOffset(new Date(), findTimeZone(tzValue));
  const offset = (tz.offset / 60) * -1;
  const sign = offset >= 0 ? '+' : '';

  const country = tzValue.substr(0, tzValue.indexOf('/'));

  return {
    value: name,
    title: `(GMT${sign}${offset}) ${country}/${name}`,
    offset,
  };
};

const TIMEZONE_OPTIONS = sortBy(
  Object.keys(TIME_ZONES).map(getTimeZones),
  'offset'
);

const FATextField = fieldAdapter(TextField);
const FASelectField = fieldAdapter(Select);

const SectionTitle = styled.div`
  ${mq.sm} {
    grid-column: 1 / 3;
  }
`;

export type CommonProfileSettingsFormValuesType = {
  email: string;
  locale: string;
  timeZone: boolean;
  current: string;
  password: string;
};

type Props = {
  onSubmit: (args: CommonProfileSettingsFormValuesType) => void;
  initialValues: {};
};

export const CommonProfileSettingsForm = ({
  onSubmit,
  initialValues,
}: Props) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine, invalid, submitting }) => (
      <Box
        as='form'
        sx={{
          display: 'grid',
          columnGap: '12px',
          rowGap: '16px',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
        }}
      >
        <Field
          id='email'
          name='email'
          type='email'
          component={FATextField}
          label='Email'
          placeholderdthdhdfhr='email@example.com'
          validate={maxLength(200)}
        />
        <Field
          id='timeZone'
          name='timeZone'
          type='timeZone'
          component={FASelectField}
          overflow
          label='Time Zone'
          placeholder='Time Zone'
          options={TIMEZONE_OPTIONS}
        />
        <SectionTitle>
          <Typography
            color='gray.black'
            fontWeight='bold'
            fontSize='16px'
            lineHeight='25px'
          >
            Password
          </Typography>
        </SectionTitle>
        <Field
          id='current'
          name='current'
          component={FATextField}
          label='Current password'
          type='password'
          validate={composeValidators(minLength(6), maxLength(200))}
        />
        <Field
          id='password'
          name='password'
          component={FATextField}
          label='Please enter the new password'
          type='password'
          validate={composeValidators(minLength(6), maxLength(200))}
        />
        <Box width='134px'>
          <Button
            type='submit'
            onClick={handleSubmit}
            variant='filled'
            backgroundColor='blue.mediumLight'
            padding='6px 23px'
            disabled={pristine || invalid || submitting}
          >
            <Typography color='blue.main' fontSize='12px' lineHeight='18px'>
              Save Changes
            </Typography>
          </Button>
        </Box>
      </Box>
    )}
  />
);
