import { ROUTE_ROOT } from 'const';
import diff from 'date-fns/differenceInDays';
import get from 'lodash/get';
import { intercomWidget } from 'modules/intercom';
import { getFullName, getInitials } from 'modules/utils/formatName';
import { getOr } from 'modules/utils/getOr';
import { stringify } from 'modules/utils/qs';
import { useAuth } from 'modules/utils/useAuth';
import { useQuery } from 'modules/utils/useQuery';
import { useResponsive } from 'modules/utils/useResponsive';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useToggle } from 'react-use';
import { RootState } from 'store';
import { fetchFullCurrent } from 'store/actions/user';
import {
  selectCurrentAccount,
  selectCurrentPlan,
  selectCurrentUser,
} from 'store/selectors/user';
import { CurrentAccount, CurrentPlan, CurrentUser } from 'types';
import { HeaderDropdown } from 'ui/dropdown/HeaderDropdown';
import { Header as HeaderUI } from 'ui/header';
import { BurgerButton } from 'ui/header/BurgerButton';
import { Links } from 'ui/header/Links';
import { PastDue } from 'ui/header/PastDue';
import { Upgrade } from 'ui/header/Upgrade';
import { SearchInput, SearchInputProps } from 'ui/input/SearchInput';

type DropdownWrapperProps = {
  fullName: string;
  shortName: string;
  avatar?: string;
  email: string;
  confirmed?: boolean;
};

const DropdownWrapper = ({
  fullName,
  shortName,
  avatar,
  email,
  confirmed,
}: DropdownWrapperProps) => {
  const [isOpen, onToggle] = useToggle(false);

  return (
    <HeaderDropdown
      isOpen={isOpen}
      onToggle={onToggle}
      fullName={fullName}
      shortName={shortName}
      email={email}
      avatar={avatar}
      confirmed={confirmed}
    />
  );
};

const SearchWrapper = ({ onChange, onSubmit }: SearchInputProps) => {
  return <SearchInput onChange={onChange} onSubmit={onSubmit} />;
};

const mapStateToProps = (state: RootState) => ({
  user: selectCurrentUser(state),
  account: selectCurrentAccount(state),
  plan: selectCurrentPlan(state),
});

const mapDispatchToProps = {
  fetchUser: fetchFullCurrent,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

type Props = {
  fetchUser: () => void;
  plan?: CurrentPlan;
  user?: CurrentUser;
  account?: CurrentAccount;
};

type Query = {
  search_pattern?: string;
};

const Header = enhance(({ fetchUser, plan, user, account }: Props) => {
  const history = useHistory();
  const [query] = useQuery();
  const { search_pattern = '' } = query as Query;
  const [search, setSearch] = useState(search_pattern);
  const isAuth = useAuth();
  const { isMobile } = useResponsive();
  const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(true);

  const email: string = get(user, 'email', '-');
  const avatar: string | undefined = get(user, 'avatar');
  const firstName: string = getOr(account, 'firstName', '-');
  const lastName: string = getOr(account, 'lastName', '-');
  const shortName = getInitials(firstName, lastName);
  const fullName = getFullName(firstName, lastName);

  const toggleMobileMenuCollapse = useCallback(() => {
    setIsMobileMenuCollapsed(!isMobileMenuCollapsed);
  }, [isMobileMenuCollapsed]);

  const handleMobileMenuClose = useCallback(() => {
    setIsMobileMenuCollapsed(true);
  }, []);

  const onSearchSubmit = () => {
    history.push({
      pathname: ROUTE_ROOT,
      search: stringify({ search_pattern: search }),
    });
  };

  useEffect(() => {
    if (isAuth) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (user && account && plan) {
      const intercomProps = {
        email: user.email,
        userId: user.id,
        createdAt: user.createdAt,
        name: `${account?.firstName} ${account?.lastName}`,
        role: user.role,
        plan: plan.planName,
        user_hash: user.userHash,
      };

      intercomWidget.update(intercomProps);
    }
  }, [account, plan, user]);

  const headerProps = isAuth
    ? {
        isMobile,
        isMobileMenuCollapsed,
        confirmed: user?.confirmed,
        linksSlot: (
          <Links
            isMobile={isMobile}
            isMobileMenuCollapsed={isMobileMenuCollapsed}
            handleMobileMenuClose={handleMobileMenuClose}
            confirmed={user?.confirmed}
          />
        ),
        searchSlot: user?.confirmed ? (
          <SearchWrapper onSubmit={onSearchSubmit} onChange={setSearch} />
        ) : null,
        userDropdownSlot: (
          <DropdownWrapper
            fullName={fullName}
            shortName={shortName}
            avatar={avatar}
            email={email}
            confirmed={user?.confirmed}
          />
        ),
        upgrageSlot:
          user?.confirmed &&
          (plan?.planCode === 'trial' ||
            plan?.planCode === 'basic') ? (
            <Upgrade
              days={
                plan?.expiration
                  ? diff(new Date(plan?.expiration), new Date()) + 1
                  : 0
              }
              pausedDynamicQrCodes={plan?.pausedDynamicQrCodes}
              planName={plan?.planCode}
              canceled={plan?.prevPlanName !== 'trial'}
            />
          ) : null,
        pastDueSlot: plan?.pastDueAt ? (
          <PastDue
            pastDueDate={plan?.pastDueAt}
            planName={plan?.planName}
            pausedDynamicQrCodes={plan?.pausedDynamicQrCodes} 
          />
         ) : null,
        burgerSlot: (
          <BurgerButton
            isMobileMenuCollapsed={isMobileMenuCollapsed}
            toggleMobileMenuCollapse={toggleMobileMenuCollapse}
          />
        ),
      }
    : {};

  return <HeaderUI {...headerProps} />;
});

export default memo(Header);
