import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from 'modules/utils/validators';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { GoogleAuthButton } from 'ui/button/GoogleAuthButton';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);

export type SignUpFormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  time_zone: string;
};

type SignUpProps = {
  onSubmit: (args: SignUpFormValuesType) => void;
  onOauth: (args: any) => void;
  loading: boolean;
  initialValues: { email?: string; time_zone?: string };
};

export const SignUpForm = ({
  onSubmit,
  onOauth,
  loading,
  initialValues,
}: SignUpProps) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine }) => (
      <Flex flexDirection='column' as='form' onSubmit={handleSubmit}>
        <Box mb='16px'>
          <Field
            id='firstName'
            name='firstName'
            component={FATextField}
            label='First Name'
            placeholder='Name'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mb='16px'>
          <Field
            id='lastName'
            name='lastName'
            component={FATextField}
            label='Last Name'
            placeholder='Last name'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mb='16px'>
          <Field
            id='email'
            name='email'
            type='email'
            component={FATextField}
            label='Email'
            placeholder='empty@gmail.com'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mb='32px'>
          <Field
            id='password'
            name='password'
            component={FATextField}
            label='Password'
            placeholder='password'
            type='password'
            validate={composeValidators(required, minLength(6), maxLength(200))}
          />
        </Box>
        <Button variant='filled' type='submit' disabled={loading || pristine}>
          Register
        </Button>
        <Box my={[15, 24]}>
          <Typography
            variant='footnote'
            color='gray.medium'
            textAlign='center'
            fontWeight='normal'
          >
            or register with
          </Typography>
        </Box>
        <GoogleAuthButton
          type='button'
          disabled={loading}
          onOauthSuccess={onOauth}
        />
      </Flex>
    )}
  />
);
