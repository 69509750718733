import { createSlice } from '@reduxjs/toolkit';
import {
  putFeedbacks,
  putCategories,
  removeFeedbackById,
  removeAllFeedbacks,
  putAverageRating,
} from '../actions/feedbacks';
import { Feedback, Category } from 'types';
import uniq from "lodash/uniq";

export interface FeedbacksState {
  ids: number[];
  byIds: {
    [id: number]: Feedback | undefined;
  };
  categories: Category[];
  averageRating?: number;
}

const initialState: FeedbacksState = {
  ids: [],
  byIds: {},
  categories: [],
};

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [putFeedbacks.type]: (
      state,
      { payload: feedbacks }: { payload: Feedback[] }
    ) => {
      const newFeedbackIds = feedbacks.map((f) => f.id);
      const newFeedback = feedbacks.reduce(
        (acc, f) => ({ ...acc, [f.id]: f }),
        {}
      );

      return {
        categories: state.categories,
        ids: uniq(state.ids.concat(newFeedbackIds)),
        byIds: { ...state.byIds, ...newFeedback},
      };
    },
    [putCategories.type]: (
      state,
      { payload: categories }: { payload: Category[] }
    ) => ({
      ...state,
      categories: categories,
    }),
    [removeFeedbackById.type]: (
      state,
      { payload: { id } }: { payload: { id: number } }
    ) => ({
      categories: state.categories,
      ids: state.ids.filter((i) => i !== id),
      byIds: {
        ...state.byIds,
        [id]: undefined,
      },
    }),
    [putAverageRating.type]: (state, { payload }: { payload: number }) => ({
      ...state,
      averageRating: payload,
    }),
    [removeAllFeedbacks.type]: () => initialState,
  },
});
