import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  composeValidators,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);

type Props = {
  internalNotesSlot: ReactNode;
};

export const CreateQrCodeUrlSubform = memo(
  ({ internalNotesSlot }: Props) => (
    <Box>
      <Box>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Which web address would you like this code to lead to?
        </Typography>
      </Box>
      <Box mt='16px'>
        <Field
          id='urlBody'
          name='url_body'
          type='url'
          label='URL of your website or a file*'
          placeholder='http://website.com/'
          component={FATextField}
          validate={composeValidators(required, isUrl, maxLength(200))}
          format={toHttps}
          formatOnBlur
        />
      </Box>
      {internalNotesSlot}
    </Box>
  )
);
