const httpsReg = new RegExp('^(http|https)://', 'i');

export const toHttps = (value: string | undefined) => {
  if (value === undefined || value === null || value === '') {

    return '';
  }

  if (value.length) {
    if (httpsReg.test(value)) {
      return value;
    } else {
      return `https://${value}`;
    }
  }
};
