import folderIcon from 'assets/images/folder-gray.svg';
import addIcon from 'assets/images/plus-blue.svg';
import { MODAL_CREATE_FOLDER, QR_CODE_TYPES } from 'const';
import { useModal } from 'modules/modal';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {maxLength, qrCodeTypeValidation} from 'modules/utils/validators';
import { memo } from 'react';
import { Field, useForm } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { Folder } from 'types';
import { Icon } from 'ui/icon';
import { TextField } from 'ui/input';
import { Select } from 'ui/select';
import { Typography } from 'ui/typography';
import { customFieldAdapter } from "modules/utils/createCustomAdapter";
import { QrCodeTypeSelect } from "ui/qr_code_type_select";
import { useSelector } from "react-redux";
import { selectCurrentPlan } from "store/selectors/user";

const FATextField = fieldAdapter(TextField);
const FASelectField = fieldAdapter(Select);
const FASelectQrCodeTypeField = customFieldAdapter(QrCodeTypeSelect);

type Props = {
  folders: Folder[];
  canChangeQrCodeType: boolean;
  isModify?: boolean
};

const mapFolderToOption = (folder: Folder) => ({
  value: folder.id,
  title: (
    <Typography fontSize='12px' lineHeight='18px' color='gray.black'>
      <Icon width='13px' height='12px' mr='14px' url={folderIcon} />
      {folder.name}
    </Typography>
  ),
});

const FirstItem = () => (
  <Typography
    textAlign='left'
    variant='footnote'
    color='gray.black'
    fontWeight='normal'
  >
    <Icon url={addIcon} width='13px' height='12px' mr='14px' />
    Add new folder
  </Typography>
);

export const InternalNotesSubform = memo(({ folders, canChangeQrCodeType, isModify = false }: Props) => {
  const { showModal } = useModal();
  const { change } = useForm();
  const currentPlan = useSelector(selectCurrentPlan);

  const changeFolder = ({ id }: { id: number }) => {
    change('folder_id', id);
  };

  const onCreate = () => {
    showModal(MODAL_CREATE_FOLDER, { onSubmit: changeFolder });
  };

  return (
    <Box>
      <Box mt={24}>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Internal Notes
        </Typography>
      </Box>
      <Box mt='16px'>
        <Field
          id='name'
          name='name'
          label='Name your code'
          placeholder='Enter name'
          component={FATextField}
          validate={maxLength(200)}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='folder_id'
          name='folder_id'
          component={FASelectField}
          label='Folder'
          placeholder='Select'
          overflow
          options={folders.map(mapFolderToOption)}
          firstItem={<FirstItem />}
          onFirstItemClick={onCreate}
        />
      </Box>
      {(canChangeQrCodeType && !isModify) && (
        <Box mt='16px'>
          <Field
            id='qrCodeType'
            name='qr_code_type'
            component={FASelectQrCodeTypeField}
            label='Type'
            options={QR_CODE_TYPES}
            validate={qrCodeTypeValidation(currentPlan)}
            currentPlan={currentPlan}
          />
        </Box>
      )}
    </Box>
  );
});
