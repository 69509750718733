import closeButton from 'assets/images/close.svg';
import { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { CreateCategoryForm, CreateCategoryValueType } from 'ui/forms';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button p='8px' onClick={onClick}>
    <Icon url={closeButton} width={12} height={12} />
  </Button>
);
type Props = {
  onClose: () => void;
  options: { onSubmit: (args: CreateCategoryValueType) => void; type: string };
};

export const AddCategoryModal = ({ onClose, options }: Props) => {
  const { type, onSubmit } = options;
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = async (v: CreateCategoryValueType) => {
    try {
      setError(undefined);
      onSubmit(v);
      onClose();
    } catch (e) {
      setError(e.message || e);
    }
  };

  return (
    <Card>
      <Box p={24} width={[1, 1, 340]} minHeight={[1, 1, 392]} height='100%'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center'>
            <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
              Create {type}
            </Typography>
          </Flex>
          <Box>
            <CloseButton onClick={onClose} />
          </Box>
        </Flex>
        <CreateCategoryForm
          onSubmit={handleSubmit}
          onClose={onClose}
          type={type}
        />
        {error && (
          <Box mt={16}>
            <Typography variant='footnote' color='red.main' textAlign='center'>
              {error}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};
