import ajax from 'api/utils/ajax';
import closeRedIcon from 'assets/images/close-red.svg';
import imageUploadIcon from 'assets/images/image-upload.svg';
import axios from 'axios';
import { PDF_TYPE } from 'const';
import get from 'lodash/get';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  composeValidators,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Field, useForm, useFormState } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { colors } from 'theme/colors';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { TextField } from 'ui/input';
import ColorInput from 'ui/input/ColorInput';
import { PdfInput } from 'ui/input/PdfInput';
import { Error } from 'ui/labels';
import { Typography } from 'ui/typography';

const FAColorInput = fieldAdapter(ColorInput);
const FATextField = fieldAdapter(TextField);
const FAPdfInput = fieldAdapter(PdfInput);

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 160px;
  padding: 20px 40px;
  border: 1px dashed ${colors.blue.main};
  border-radius: 8px;
  cursor: pointer;

  & > p {
    max-width: 50%;
    word-break: break-word;
    font-size: 14px;
    line-height: 1.6;
    color: ${colors.gray.medium};
  }

  & > span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & button {
    padding: 6px 16px;
    font-size: 12px;
    background-color: ${colors.blue.mediumLight};
  }
`;

const DropzonePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border: 1px solid rgba(148, 154, 167, 0.5);
  border-radius: 8px;

  & p {
    margin: 0;
    color: ${colors.gray.medium};
  }

  & > button {
    margin-right: -8px;
  }
`;

const DropzoneThumbContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 20px 20px 0;
`;

const DropzoneLoader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  background: #fff;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    padding: 14px;
    border: 4px solid #adc9f4;
    opacity: 0.5;
    border-radius: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
  }

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 14px;
    color: #949aa7;
    transform: translate(-50%, -50%);
  }

  & svg {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 37%;
  }
`;

type Props = {
  internalNotesSlot: ReactNode;
};

export type S3File = File & {
  preview?: string;
  s3Key?: string;
};

type UploadThumbFieldProps = {
  handleClickUploaded?: (event: any) => void;
};

const UploadThumbField = ({ handleClickUploaded }: UploadThumbFieldProps) => {
  const { change } = useForm();
  const { values } = useFormState();
  const uploadedImageFile = get(values, 'custom_image_link_pdf');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onUpload = () => {
    inputRef?.current?.click();
  };

  const handleCustomImageUploadAws = useCallback(async (file: File) => {
    try {
      const presignedResponse = await ajax.get('/aws/s3/presigned_urls/new', {
        params: {
          s3_key: file.name,
          content_type: PDF_TYPE,
        },
      });

      const preasignedUrl: string = await presignedResponse.data.presigned_url;
      const fileUrl: string = presignedResponse.data.file_url;

      if (!file) {
        return null;
      }

      await axios.put(preasignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      const filePreview: S3File = Object.assign(file, {
        preview: URL.createObjectURL(file),
        s3Key: encodeURI(fileUrl),
      });

      return filePreview;
    } catch (err) {
      console.log('handleCustomImageUploadAws err =>', err);
    }
  }, []);

  const onCustomImageChange = useCallback(
    async (event: any) => {
      const file = await event.target.files[0];
      const uploadedCustomImage = await handleCustomImageUploadAws(file);

      if (uploadedCustomImage) {
        change('custom_image_link_pdf', uploadedCustomImage.s3Key);
        change('preview_image_index_pdf', -1);
      }
    },
    [change, handleCustomImageUploadAws]
  );

  const onReset = useCallback(() => {
    change('custom_image_link_pdf', undefined);
    change('preview_image_index_pdf', undefined);
  }, [change]);

  return (
    <DropzoneThumbContainer>
      <Field
        id='preview_image_index_pdf'
        name='preview_image_index_pdf'
        isFile={!!uploadedImageFile}
        file={uploadedImageFile}
        component={FAPdfInput}
        onUpload={onUpload}
        onReset={onReset}
        handleClickUploaded={handleClickUploaded}
      />
      <Field
        id='custom_image_link_pdf'
        name='custom_image_link_pdf'
        component={(props: Object) => (
          <input
            ref={inputRef}
            type={'file'}
            accept={'.jpg, .jpeg, .png'}
            {...props}
            style={{ width: 0, visibility: 'hidden' }}
            onChange={onCustomImageChange}
            onClick={(event: any) => {
              event.target.value = null;
            }}
          />
        )}
      />
    </DropzoneThumbContainer>
  );
};

export const CreateQrCodePdfSubform = memo(({ internalNotesSlot }: Props) => {
  const { change } = useForm();
  const { values } = useFormState();
  const fileLinkValue = get(values, 'file_link_pdf');
  const [documentFileSize, setDocumentFileSize] = useState<number>(0);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDropFileRequiredError, setIsDropFileRequiredError] = useState(false);
  const [isDropFileTooLargeError, setIsDropFileTooLargeError] = useState('');

  const radius = 41;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = useMemo(
    () => circumference - (progress / 100) * circumference,
    [progress, circumference]
  );

  const handlePdfUpload = useCallback(async (file: File) => {
    try {
      const presignedResponse = await ajax.get('/aws/s3/presigned_urls/new', {
        params: {
          s3_key: file.name,
          content_type: PDF_TYPE,
        },
      });

      const preasignedUrl: string = presignedResponse.data.presigned_url;
      const fileUrl: string = presignedResponse.data.file_url;

      await axios.put(preasignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (uploadProgress) => {
          const percentCompleted = Math.round(
            (uploadProgress.loaded * 100) / uploadProgress.total
          );

          setProgress(percentCompleted);
          setIsLoader(
            uploadProgress.loaded === uploadProgress.total ? false : true
          );
        },
      });

      const filePreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
        s3Key: encodeURI(fileUrl),
      });

      return filePreview as S3File;
    } catch (err) {
      console.log('handlePdfUpload err =>', err);
    }
  }, []);

  const handleOnDropAccepted = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setIsLoader(true);
      setIsDropFileRequiredError(false);
      setIsDropFileTooLargeError('');
      setDocumentFileSize(file ? file.size : 0);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        let fileUploaded = await handlePdfUpload(acceptedFiles[0]);
        if (fileUploaded) {
          change('file_link_pdf', fileUploaded?.s3Key);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePdfUpload]
  );

  const handleOnDropRejected = useCallback((rejectedFiles) => {
    const file = rejectedFiles[0];

    const fileError = file.errors[0].code;
    console.log('fileError =>', fileError);

    if (fileError === 'file-too-large') {
      setIsDropFileTooLargeError(
        'You are trying to upload file more then 20MB. Please upload file less then 20MB.'
      );
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    multiple: false,
    maxSize: 2e7, // 20mb
    onDropRejected: handleOnDropRejected,
    onDropAccepted: handleOnDropAccepted,
    onFileDialogCancel: () => setIsDropFileRequiredError(true),
  });

  const handleClickChoosePreview = useCallback(() => {
    setIsPreviewVisible(!isPreviewVisible);
  }, [isPreviewVisible]);

  const handleClosePreviewImageSection = useCallback(() => {
    change('custom_image_link_pdf', undefined);
    change('preview_image_index_pdf', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickUploadThumb = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      change('preview_image_index_pdf', -1);
    },
    [change]
  );

  useEffect(() => {
    if (!!fileLinkValue) {
      setIsPreviewVisible(true);
    }
  }, [fileLinkValue]);

  return (
    <Box>
      <Box>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Basic Information
        </Typography>
      </Box>
      <Box mt='16px'>
        <Field
          id='company_pdf'
          name='company_pdf'
          label='Company'
          placeholder='Enter company'
          component={FATextField}
          validate={required}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='headline_pdf'
          name='headline_pdf'
          label='Headline'
          placeholder='Enter name'
          component={FATextField}
          validate={required}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='description_pdf'
          name='description_pdf'
          label='Description'
          placeholder='More information about your gallery'
          component={FATextField}
          validate={required}
        />
      </Box>
      <Box mt='16px'>
        <Field
          id='website_pdf'
          name='website_pdf'
          label='Website'
          placeholder='https://yourwebsite.com'
          component={FATextField}
          validate={composeValidators(required, isUrl, maxLength(200))}
          format={toHttps}
          formatOnBlur
        />
      </Box>
      <Box mt='16px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Upload PDF
        </Typography>
      </Box>
      <Box mt={16}>
        <Field
          id='file_link_pdf'
          name='file_link_pdf'
          label='pdf'
          validate={required}
          render={(props) => (
            <>
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                {isLoader ? (
                  <DropzoneLoader>
                    <svg height={radius * 2} width={radius * 2}>
                      <circle
                        fill='transparent'
                        stroke='#3278E4'
                        strokeWidth={stroke}
                        strokeDasharray={circumference + ' ' + circumference}
                        style={{ strokeDashoffset }}
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                      />
                    </svg>

                    <span>
                      {documentFileSize &&
                        `${(documentFileSize / (1024 * 1024)).toFixed(2)}MB...`}
                    </span>
                  </DropzoneLoader>
                ) : (
                  <p>
                    {fileLinkValue !== undefined
                      ? decodeURI(fileLinkValue).split('/').pop()
                      : 'Upload a PDF file (up to 20MB)'}
                  </p>
                )}
                <span>
                  <Icon url={imageUploadIcon} width={56} height={56} mb={16} />
                  <Button variant={'text'}>
                    {fileLinkValue !== undefined
                      ? 'Change file'
                      : 'Upload file'}
                  </Button>
                </span>
              </DropzoneContainer>
              {isDropFileTooLargeError && (
                <Error>{isDropFileTooLargeError}</Error>
              )}
              {isDropFileRequiredError && !isDropFileTooLargeError && (
                <Error>{props.meta.error}</Error>
              )}
            </>
          )}
        />
      </Box>
      <Box mt='16px'>
        {!isPreviewVisible ? (
          <Flex justifyContent={'flex-end'}>
            <Box>
              <Button
                onClick={handleClickChoosePreview}
                variant='text'
                style={{ padding: 0, textDecoration: 'underline' }}
              >
                Choose preview image
              </Button>
            </Box>
          </Flex>
        ) : (
          <Box>
            <DropzonePreviewContainer>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                pb={16}
              >
                <Typography
                  variant='footnote'
                  color='gray.medium'
                  fontWeight={400}
                >
                  Choose preview image
                </Typography>
                <Button p='8px' onClick={handleClosePreviewImageSection}>
                  <Icon url={closeRedIcon} width={9} height={9} />
                </Button>
              </Flex>
              <Flex flexWrap={'wrap'} mt={2}>
                <UploadThumbField
                  handleClickUploaded={(e) => handleClickUploadThumb(e)}
                />
              </Flex>
            </DropzonePreviewContainer>
          </Box>
        )}
      </Box>

      <Box mt='16px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Color palette
        </Typography>
      </Box>
      <Box
        mt='16px'
        sx={{
          display: 'grid',
          columnGap: '12px',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Box>
          <Field
            id='background_color_pdf'
            name='background_color_pdf'
            label='Background'
            component={FAColorInput}
          />
        </Box>
        <Box>
          <Field
            id='text_color_pdf'
            name='text_color_pdf'
            label='Text'
            component={FAColorInput}
          />
        </Box>
        <Box mt={16}>
          <Field
            id='button_color_pdf'
            name='button_color_pdf'
            label='Button'
            component={FAColorInput}
          />
        </Box>
      </Box>
      <Box mt='16px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Welcome Screen
        </Typography>
      </Box>
      <Box mt='16px'>Welcome</Box>
      {internalNotesSlot}
    </Box>
  );
});
