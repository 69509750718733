import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);

type Props = {
  internalNotesSlot: ReactNode;
};

export const CreateQrCodeTextSubform = memo(({ internalNotesSlot }: Props) => (
  <Box>
    <Box>
      <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
        Add Content
      </Typography>
    </Box>
    <Box mt='16px'>
      <Field
        as='textarea'
        id='textBody'
        name='text_body'
        label='Text (up to 300 characters)*'
        placeholder='Enter your text here'
        rows='7'
        component={FATextField}
        validate={composeValidators(required, maxLength(300))}
      />
    </Box>
    {internalNotesSlot}
  </Box>
));
