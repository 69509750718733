import ajax from './utils/ajax';

export type GetCountryResponse = {
  [country: string]: string;
};

export const getCountryList = async (): Promise<GetCountryResponse> => {
  const { data } = await ajax.get('/countries');

  return data;
};

type GetStateRequest = {
  countryCode: string;
};

type GetStateResponse = {
  [country: string]: string;
};

export const getStateList = async ({
  countryCode,
}: GetStateRequest): Promise<GetStateResponse> => {
  const { data } = await ajax.get(`/states?country_code=${countryCode}`);

  return data;
};

type GetCityRequest = GetStateRequest & { stateCode: string };

export type GetCityResponse = {
  [country: string]: string;
};

export const getCityList = async ({
  countryCode,
  stateCode,
}: GetCityRequest): Promise<GetCityResponse> => {
  const { data } = await ajax.get(
    `/cities?country_code=${countryCode}&state_code=${stateCode}`
  );

  return data;
};
