import { useNotification } from 'hooks/useNotification';
import get from 'lodash/get';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { IconSelect } from 'ui/input/IconInput';
import { Notification } from 'ui/notification';
import { Typography } from 'ui/typography';

const FAIconInput = fieldAdapter(IconSelect);

export const ModifyDesignIconSubform = () => {
  const { change } = useForm();
  const { values } = useFormState();
  const imgFile = get(values, 'logo_image');
  const [error, setError] = useState<string | undefined>();
  const {
    key,
    isNotificationVisible,
    handleCloseNotification,
  } = useNotification(error);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onUpload = () => {
    inputRef?.current?.click();
  };
  const onSetLogoFlag = (v: boolean) => {
    change('set_logo', v);
  };
  const onFileUpload = async (event: any) => {
    setError(undefined);
    try {
      const file = event.target.files[0];
      const size = event.target.files[0].size;

      if (size >= 2e7) {
        setError('Please, upload image less then 20 MB');
      } else {
        onSetLogoFlag(true);
        change('logo_image', file);
        change('logo_type', undefined);
      }
    } catch (error) {
      setError(error);
      console.log('Error onFileUpload icon =>', error);
    }
  };
  const onReset = () => {
    onSetLogoFlag(false);
    change('logo_image', undefined);
    change('logo_type', -1);
  };

  return (
    <Box>
      <Notification
        key={key}
        message={error}
        type='error'
        isNotificationVisible={isNotificationVisible}
        onClose={handleCloseNotification}
      />
      <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
        Select
      </Typography>
      <Box mt='16px'>
        <Field
          id='logoType'
          name='logo_type'
          isFile={!!imgFile}
          file={imgFile}
          component={FAIconInput}
          onUpload={onUpload}
          onReset={onReset}
          onSetLogoFlag={onSetLogoFlag}
        />
        <Field id='setLogo' name='set_logo' component={() => null} />
        <Field
          id='setLogo'
          name='logo_image'
          component={(props: Object) => (
            <input
              ref={inputRef}
              {...props}
              type='file'
              style={{ width: 0, visibility: 'hidden' }}
              onChange={onFileUpload}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};
