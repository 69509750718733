import axios from './ajax';

export const getFile = async (url: string) => {
  const { data: blob } = await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    withCredentials: false,
  });

  return blob;
};
