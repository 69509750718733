import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_PLANS_FETCH_ALL,
  ACTION_PLANS_UPPGRADE,
  ACTION_PLANS_REMOVE_ALL,
  ACTION_PLANS_PUT,
  ACTION_PLANS_CANCEL_DOWNGRADE,
} from 'const';
import { Plan } from 'types';

export const fetchPlans = createAction(ACTION_PLANS_FETCH_ALL);

export const removeAllPlans = createAction(ACTION_PLANS_REMOVE_ALL);

type PutPlansActionPayload = Plan[];
export const putPlans = createAction<PutPlansActionPayload>(ACTION_PLANS_PUT);

export type UpgradePlanActionPayload = { planId: string | undefined };
export const upgradePlanRoutine = createRoutine<UpgradePlanActionPayload>(
  ACTION_PLANS_UPPGRADE
);
export const upgradePlanRoutinePromiseCreator = promisifyRoutine(
  upgradePlanRoutine
);

export const cancelPlanDowngradeRoutine = createRoutine(
  ACTION_PLANS_CANCEL_DOWNGRADE
);
export const cancelPlanDowngradeRoutinePromiseCreator = promisifyRoutine(
  cancelPlanDowngradeRoutine
);
