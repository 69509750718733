import CheckIcon from 'assets/images/check-2.svg';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const LabelText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.gray.black};
  line-height: 18px;
  margin-left: 8px;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckboxUI = styled.span`
  position: relative;
  background: #f7f7f7;
  border: 1px solid #e6e9ef;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &::after {
    content: '';
    display: none;
    position: absolute;
    background-color: transparent;
    background-image: url(${CheckIcon});
    background-position: center 5px;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.checked::after {
    display: block;
  }
`;

type Props = {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  label?: string;
  onChange?: any;
};

export const Checkbox = ({
  id,
  label,
  checked,
  defaultChecked,
  disabled,
  ...rest
}: Props) => (
  <Label htmlFor={id}>
    <HiddenInput
      id={id}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      {...rest}
      type='checkbox'
    />
    <CheckboxUI className={checked ? 'checked' : ''} />
    {label && <LabelText>{label}</LabelText>}
  </Label>
);
