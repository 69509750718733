import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import { setAppState } from '../actions/app';
import { removeUser } from '../actions/user';
import { removeAllFolders } from '../actions/folders';
import {
  login,
  loginOAuth,
  register,
  logout,
  resetToken,
  storeToken,
  LoginPayload,
  RegisterPayload,
  ConfirmPayload,
  registerOAuth,
  confirmEmail,
  ResendConfirmPayload,
  resendConfirmEmail,
} from '../actions/auth';
import { fetchFullCurrent } from '../actions/user';
import {
  authenticate,
  register as fetchRegister,
  oAuthLogin,
  oAuthRegister,
} from 'api/auth';
import { saveToken, removeToken } from 'api/utils/localStorage';
import { resendEmailConfirmation, sendConfirmationToken } from "../../api/confirmations";

function* signIn({ payload }: PayloadAction<LoginPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const {
      data: { token, confirmed },
    } = yield call(authenticate, payload);

    yield put(setAppState({ confirmed }));

    yield call(saveToken, { token });
    yield put(storeToken({ token }));
    yield put(fetchFullCurrent());

    yield put(setAppState({ loading: false }));
  } catch (e) {
    yield put(
      setAppState({
        loading: false,
        error: e.message,
      })
    );
  }
}

function* signInOAuth({ payload }: PayloadAction<{ tokenId: string }>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const token = yield call(oAuthLogin, payload);

    yield call(saveToken, { token });
    yield put(storeToken({ token }));
    yield put(fetchFullCurrent());

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(
      setAppState({
        loading: false,
        error:
          'You entered an incorrect username or password. Please try again or use the password recovery form.',
      })
    );
  }
}

function* signUp({ payload }: PayloadAction<RegisterPayload>) {
  try {
    yield put(setAppState({ loading: true, afterSignup: true, error: null }));
    const {
      data: { token, confirmed },
    } = yield call(fetchRegister, payload);

    yield call(saveToken, { token });
    yield put(storeToken({ token }));
    yield put(fetchFullCurrent());

    yield put(setAppState({ loading: false, error: null, confirmed }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* signUpOAuth({ payload }: PayloadAction<{ tokenId: string }>) {
  try {
    yield put(setAppState({ error: null, afterSignup: true, loading: true }));
    const token = yield call(oAuthRegister, payload);

    yield call(saveToken, { token });
    yield put(storeToken({ token }));
    yield put(fetchFullCurrent());

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* confirmationEmail({ payload }: PayloadAction<ConfirmPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const {
      data: { token, confirmed },
    } = yield call(sendConfirmationToken, payload);

    yield put(setAppState({ confirmed }));

    yield call(saveToken, { token });
    yield put(storeToken({ token }));
    yield put(fetchFullCurrent());

    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(
      setAppState({
        loading: false,
        error: e.message,
      })
    );
  }
}

function* resendConfirmationEmail({payload}: PayloadAction<ResendConfirmPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null, success: null }));
    yield call(resendEmailConfirmation, payload)
    yield put(setAppState({ error: null, loading: false, success: "The email was successfully sent!" }));
  } catch (e) {
    yield put(
      setAppState({
        loading: false,
        error: e.message,
        success: null,
      })
    );
  }
}

function* resetStore() {
  yield put(setAppState({ loading: false, error: undefined, success: null }));
  yield put(resetToken());
  yield call(removeToken);
  yield put(removeUser());
  yield put(removeAllFolders());
  window.location.href = '/login';
}

export default function* watcher() {
  yield takeLatest(login.type, signIn);
  yield takeLatest(loginOAuth.type, signInOAuth);
  yield takeLatest(register.type, signUp);
  yield takeLatest(registerOAuth.type, signUpOAuth);
  yield takeLatest(logout.type, resetStore);
  yield takeLatest(confirmEmail.type, confirmationEmail)
  yield takeLatest(resendConfirmEmail.type, resendConfirmationEmail)
}
