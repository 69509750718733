import parse from 'color-parse';
import { RgbaColor } from 'react-colorful';

export const convertPageToOffset = (
  page: number = 1,
  perPage: number = 100
) => {
  const limit = perPage;
  const offset = (page - 1) * perPage;

  return { offset, limit };
};

export const convertFileToBase64 = (imageBlob: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      const base64data = reader.result as string;

      resolve(base64data);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export const convertHexToRgbaStr = (hex: string) => {
  const value = hex.replace('#', '');
  var r = parseInt(
    value.length == 3 ? value.slice(0, 1).repeat(2) : value.slice(0, 2),
    16
  );
  var g = parseInt(
    value.length == 3 ? value.slice(1, 2).repeat(2) : value.slice(2, 4),
    16
  );
  var b = parseInt(
    value.length == 3 ? value.slice(2, 3).repeat(2) : value.slice(4, 6),
    16
  );

  return 'rgba(' + r + ', ' + g + ', ' + b + ', 1)';
};

export const convertColorStrToRgbaObj = (rgba: string): RgbaColor => {
  const { values, alpha } = parse(rgba);

  if (values) {
    return {
      r: values[0],
      g: values[1],
      b: values[2],
      a: alpha,
    };
  }

  return { r: 0, g: 0, b: 0, a: 1 };
};

export const convertRgbaObjToRgbaStr = (hex: RgbaColor) => {
  return 'rgba(' + hex.r + ', ' + hex.g + ', ' + hex.b + ', ' + hex.a + ')';
};
