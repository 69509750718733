import {
  useRef,
  ChangeEvent,
  BaseSyntheticEvent,
  FunctionComponent,
  createElement,
} from 'react';
import { FieldRenderProps } from 'react-final-form';

// TODO: fix FieldRenderProps generic types
type Props = FieldRenderProps<any, any> & {
  triggerSlot: FunctionComponent<any>;
};

export const FileUpload = ({
  input: { onChange, value },
  meta: { error, touched },
  triggerSlot,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  const onUploadFile = () => {
    inputRef?.current?.click();
  };

  return (
    <div>
      {createElement(triggerSlot, {
        onClick: onUploadFile,
        value,
        error,
        touched,
      })}
      <input
        type='file'
        style={{ width: 0, height: 0, visibility: 'hidden' }}
        ref={inputRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const files = event?.target?.files;

          if (files !== null) {
            onChange(files[0]);
          }
        }}
        onClick={(event: BaseSyntheticEvent<HTMLInputElement>) => {
          event.target.value = null;
        }}
        {...props.input}
        {...props}
      />
    </div>
  );
};
