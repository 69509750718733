import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import { InviteResponse } from '../types';
import { Invite } from 'types';

export const mapResponseToInvite = (res: InviteResponse): Invite =>
  compose(
    omit([
      'user_id',
      'exp_month',
      'exp_year',
      'token_id',
      'card_id',
      'created_at',
      'updated_at',
    ]),
    (res) => ({
      ...res,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
      accountId: res.account_id,
      timeZone: res.time_zone,
      stripeCustomerIud: res.stripe_customer_id,
      invitedById: res.invited_by_id,
    })
  )(res);
