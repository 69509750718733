import { createSlice, Slice } from '@reduxjs/toolkit';
import { resetToken, storeToken, StoreTokenPayload } from '../actions/auth';
import { getToken } from 'api/utils/localStorage';
export interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: getToken(),
};

export const authSlice: Slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [storeToken.type]: (_, action: { payload: StoreTokenPayload }) => ({
      token: action.payload.token,
    }),
    [resetToken.type]: () => initialState,
  },
});
