import {
  ROUTE_PROFILE,
  ROUTE_PROFILE_BILLING,
  ROUTE_PROFILE_SETTINGS,
} from 'const';
import { useResponsive } from 'modules/utils/useResponsive';
import { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Box, Flex } from 'rebass/styled-components';
import { fetchFullCurrent } from 'store/actions/user';
import { Card } from 'ui/card';
import { ProfileMenu as Menu } from 'ui/profile/Menu';
import { WrapperSpinner } from 'ui/spinner';
import { withSuspense } from './AppRoutes';
import { PrivateRoute } from './PrivateRoute';

export const profileRoutes = [
  ROUTE_PROFILE,
  ROUTE_PROFILE_BILLING,
  ROUTE_PROFILE_SETTINGS,
];

const BillingSettings = lazy(() => import('pages/account/settings/billing'));
const AccountSettings = lazy(() => import('pages/account/settings/edit'));
const CommonAccountSettings = lazy(() =>
  import('pages/account/settings/settings')
);

const ProfileRoutes = () => {
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();

  useEffect(() => {
    dispatch(fetchFullCurrent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      maxWidth={848}
      m='0 auto'
      px='16px'
      mb='32px'
      pb='32px'
      pt={60 + 32}
      minHeight='100vh'
    >
      <Card height={['unset', '100%']}>
        <Flex
          height={['unset', '100%']}
          flexDirection={['column', 'column', 'row']}
        >
          {!isMobile ? <Menu /> : null}
          <Box p={24} width={1}>
            <Switch>
              <PrivateRoute
                exact
                path={ROUTE_PROFILE}
                render={(props) =>
                  withSuspense(
                    <AccountSettings {...props} />,
                    <WrapperSpinner />
                  )
                }
              />
              <PrivateRoute
                exact
                path={ROUTE_PROFILE_BILLING}
                render={() =>
                  withSuspense(<BillingSettings />, <WrapperSpinner />)
                }
              />
              <PrivateRoute
                exact
                path={ROUTE_PROFILE_SETTINGS}
                render={() =>
                  withSuspense(<CommonAccountSettings />, <WrapperSpinner />)
                }
              />
            </Switch>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export default ProfileRoutes;
