import { createElement, FunctionComponent, PropsWithChildren } from 'react';
import arrayMutators from 'final-form-arrays';
import {
  FieldArray as RFFIeldArray,
  FieldArrayProps,
} from 'react-final-form-arrays';

export const fieldArrayMutators = arrayMutators;

type Item = { name: string };

type Props<T> = {
  renderer: FunctionComponent<T>;
  itemProps?: any;
};

export const FieldArray = <T extends Item>({
  name,
  renderer,
  validate,
  itemProps = {},
}: PropsWithChildren<Props<T>> & FieldArrayProps<any, HTMLElement>) => (
  <RFFIeldArray name={name} validate={validate}>
    {({ fields }) =>
      fields.map((name, idx) =>
        createElement(renderer, {
          key: name,
          name,
          index: idx,
          ...(fields.value[idx] || {}),
          ...itemProps,
        })
      )
    }
  </RFFIeldArray>
);
