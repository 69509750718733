import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { NavLink } from 'ui/link';
import { List, Item } from 'ui/list';
import { Icon } from 'ui/icon';
import { themeGet } from 'theme';
import { mq } from 'theme/breakpoints';
import {
  ROUTE_LOGOUT,
  ROUTE_PROFILE,
  ROUTE_PROFILE_BILLING,
  ROUTE_PROFILE_SETTINGS,
} from 'const';

import profileIcon from 'assets/images/profile.svg';
import billingIcon from 'assets/images/finance.svg';
import settingsIcon from 'assets/images/settings.svg';
import logoutIcon from 'assets/images/logout.svg';

const Root = styled(Flex)`
  ${mq.md} {
    border-right: 1px ${themeGet('colors.gray.light2')} solid;
  }
`;

export const ProfileMenu = () => (
  <Root
    height={['unset', 'unset', '100hv']}
    flexDirection='column'
    justifyContent='space-between'
    pt={25}
    minWidth={202}>
    <List>
      <Item
        as={NavLink}
        to={ROUTE_PROFILE}
        variant='footnote'
        color='gray.black'
        fontWeight='normal'
        textDecoration='none'
        pl={25}
        exact>
        <Icon url={profileIcon} mr='8px' />
        Profile
      </Item>
      <Item
        as={NavLink}
        to={ROUTE_PROFILE_BILLING}
        variant='footnote'
        color='gray.black'
        fontWeight='normal'
        textDecoration='none'
        pl={25}
        exact>
        <Icon url={billingIcon} mr='8px' />
        Billing
      </Item>
      <Item
        as={NavLink}
        to={ROUTE_PROFILE_SETTINGS}
        variant='footnote'
        color='gray.black'
        fontWeight='normal'
        textDecoration='none'
        pl={25}
        exact>
        <Icon url={settingsIcon} mr='8px' />
        Settings
      </Item>
    </List>
    <Box mb={25}>
      <Item
        as={NavLink}
        to={ROUTE_LOGOUT}
        variant='footnote'
        color='red.text'
        fontWeight='normal'
        textDecoration='none'
        pl={25}
        exact>
        <Icon url={logoutIcon} mr='8px' />
        Log Out
      </Item>
    </Box>
  </Root>
);
