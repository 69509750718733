import styled, { DefaultTheme } from 'styled-components';
import {
  color,
  ColorProps,
  padding,
  PaddingProps,
  variant,
  width,
  WidthProps,
} from 'styled-system';

interface ButtonProps {
  variant?: 'filled' | 'outlined' | 'text' | 'plain';
  disabled?: boolean;
  theme?: DefaultTheme;
}

const commonProperties = (theme: DefaultTheme) => ({
  padding: ['10px 12px', '11px 16px'],
  borderRadius: '10px',
  width: '100%',
});

const createButtonVariants = (theme: DefaultTheme) => ({
  variants: {
    plain: {
      padding: 0,
      margin: 0,
    },
    filled: {
      ...commonProperties(theme),
      backgroundColor: theme.colors.blue.main,
      color: theme.colors.white.main,
    },
    outlined: {
      ...commonProperties(theme),
      border: `1px solid ${theme.colors.blue.main}`,
      backgroundColor: 'transparent',
      color: theme.colors.gray.black,
    },
    text: {
      ...commonProperties(theme),
      border: 'none',
      background: 'none',
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.colors.blue.main,
      '&:disabled, &.disabled': {
        color: theme.colors.gray.medium,
        opacity: 0.5,
      },
    },
  },
});

type Props = ButtonProps & PaddingProps & ColorProps & WidthProps;

export const Button = styled('button')<Props>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
    backgroundColor: 'transparent',
    textAlign: 'center',
    border: 'none',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    lineHeight: '22px',
    fontWeight: theme.fontWeights.normal,
    fontSize: theme.fontSizes[2],
    '&:disabled, &.disabled': {
      opacity: 0.5,
      cursor: 'default',
    },
  }),
  ({ theme }) => variant(createButtonVariants(theme)),
  padding,
  color,
  width
);

Button.defaultProps = {
  variant: 'plain',
  type: 'button',
};
