import { QrCodeListItem, QrCodeFormValues } from 'types';
import { QrCodeFormValuesRequest } from '../types';
import { getFile } from '../utils/files';
import {
  URL_TYPE,
  TEXT_TYPE,
  EMAIL_TYPE,
  SMS_TYPE,
  WHATSAPP_TYPE,
  VCARD_TYPE,
  SOCIAL_MEDIA_TYPE,
  FEEDBACK_TYPE,
  SOCIAL_MEDIAS,
  IMAGE_TYPE,
  PDF_TYPE,
} from 'const';

const toNumberOrNot = (v?: string | number | null): number | undefined =>
  v !== null && v !== undefined ? Number(v) : undefined;

export const mapQrCodeItemToFormValues = async (
  item: QrCodeListItem
): Promise<QrCodeFormValues> => {
  const common = {
    id: item.id,
    content_type: item.content_type,
    qr_color: item.customize_parameters.color.qr,
    background_color: item.customize_parameters.color.background,
    border_color: item.customize_parameters.color.border,
    font_color: item.customize_parameters.color.font,
    basic_edges: !!item.customize_parameters.basic_edges,
    all_edge_shape: toNumberOrNot(item.customize_parameters.all_edge_shape),
    all_edge_inner_color: item.customize_parameters.all_edge_inner_color,
    all_edge_outer_color: item.customize_parameters.all_edge_outer_color,
    top_left_edge_shape: toNumberOrNot(
      item.customize_parameters.top_left_edge_shape
    ),
    top_left_edge_inner_color:
      item.customize_parameters.top_left_edge_inner_color,
    top_left_edge_outer_color:
      item.customize_parameters.top_left_edge_outer_color,
    top_right_edge_shape: toNumberOrNot(
      item.customize_parameters.top_right_edge_shape
    ),
    top_right_edge_inner_color:
      item.customize_parameters.top_right_edge_inner_color,
    top_right_edge_outer_color:
      item.customize_parameters.top_right_edge_outer_color,
    bottom_left_edge_shape: toNumberOrNot(
      item.customize_parameters.bottom_left_edge_shape
    ),
    bottom_left_edge_inner_color:
      item.customize_parameters.bottom_left_edge_inner_color,
    bottom_left_edge_outer_color:
      item.customize_parameters.bottom_left_edge_outer_color,
    set_logo: item.customize_parameters.set_logo,
    logo_type: toNumberOrNot(item.customize_parameters.logo_type),
    set_default_icon: item.customize_parameters.set_default_icon,
    default_icon: item.customize_parameters.default_icon,
    template: item.customize_parameters.template,
    template_text: item.customize_parameters.template_text,
    font_size: item.customize_parameters.font_size,
    qr_code_type: item.dynamic ? 'dynamic' : 'static',
    folder_id: item.folder_id,
    name: item.name,
    internal_url: item.internal_url,
    image_src: item.image_src,
  };

  let logo_image;

  if (common.set_logo && item.logo_url) {
    const buf = (await getFile(item.logo_url)) as ArrayBuffer;

    logo_image = new File([buf], 'logo');
  }

  let content = {};

  if (common.content_type === URL_TYPE) {
    content = {
      url_body: item.content.text,
    };
  }

  if (common.content_type === TEXT_TYPE) {
    content = {
      text_body: item.content.text,
    };
  }

  if (common.content_type === EMAIL_TYPE) {
    content = {
      email_address: item.content.address,
      email_title: item.content.title,
      email_body: item.content.text,
    };
  }

  if (common.content_type === SMS_TYPE) {
    content = {
      sms_body: item.content.text,
      sms_phone: item.content.phone,
    };
  }

  if (common.content_type === VCARD_TYPE) {
    content = {
      vcard_name: item.content.firstname,
      vcard_surname: item.content.lastname,
      vcard_phone: item.content.work_phone,
      vcard_email: item.content.email,
      vcard_country: item.content.country,
      vcard_state: item.content.state,
      vcard_city: item.content.city,
      vcard_street: item.content.street,
      vcard_zip: item.content.zip,
      vcard_org: item.content.org,
      vcard_role: item.content.role,
      vcard_url: item.content.url,
    };
  }

  if (common.content_type === SOCIAL_MEDIA_TYPE) {
    const links = item.content.links?.map((v) => {
      const socialMedia = SOCIAL_MEDIAS.find((i) => i.type === v.type_index);

      return {
        ...socialMedia,
        url: v.url,
        text: v.text,
        type: v.type_index,
      };
    });

    let custom_image;

    if (item.content.custom_image) {
      const buf = (await getFile(item.content.custom_image)) as ArrayBuffer;

      custom_image = new File([buf], 'logo');
    }

    content = {
      image_index: item.content.image_index,
      headline: item.content.headline,
      description: item.content.description,
      background_social_color: item.content.background_social_color,
      button_social_color: item.content.button_social_color,
      text_social_color: item.content.text_social_color,
      set_share_button: item.content.set_share_button,
      custom_image,
      links,
    };
  }

  if (common.content_type === FEEDBACK_TYPE) {
    const categories = item.content.categories?.map((i) => {
      const subcategories = i.sub_categories?.map((subI) => ({
        categoryName: subI.name,
        weight: subI.weight,
      }));

      return {
        categoryName: i.name,
        weight: i.weight,
        subcategories,
      };
    });
    content = {
      background_feedback_color: item.content.background_feedback_color,
      name_feedback: item.content.name_feedback,
      title_feedback: item.content.title_feedback,
      email_feedback: item.content.email_feedback,
      website_feedback: item.content.website_feedback,
      categories,
    };
  }

  if (common.content_type === IMAGE_TYPE) {
    content = {
      headline_image: item.content.headline_image,
      description_image: item.content.description_image,
      website_image: item.content.website_image,
      button_text_image: item.content.button_text_image,
      button_link_image: item.content.button_link_image,
      files_links_image: item.content.files_links_image,
      background_color_image: item.content.background_color_image,
      text_color_image: item.content.text_color_image,
    };
  }

  if (common.content_type === PDF_TYPE) {
    content = {
      company_pdf: item.content.company_pdf,
      headline_pdf: item.content.headline_pdf,
      description_pdf: item.content.description_pdf,
      website_pdf: item.content.website_pdf,
      file_link_pdf: item.content.file_link_pdf,
      custom_image_link_pdf: item.content.custom_image_link_pdf,
      preview_images_links_pdf: item.content.preview_images_links_pdf,
      preview_image_index_pdf: item.content.preview_image_index_pdf,
      background_color_pdf: item.content.background_color_pdf,
      text_color_pdf: item.content.text_color_pdf,
      button_color_pdf: item.content.button_color_pdf,
    };
  }

  if (common.content_type === WHATSAPP_TYPE) {
    content = {
      text_whatsapp: item.content.text,
      phone_whatsapp: item.content.phone,
    };
  }

  return { ...common, ...content, logo_image };
};

export const mapFormValuesToReq = (
  formValues: QrCodeFormValues
): QrCodeFormValuesRequest => {
  const links = formValues.links?.map((v, idx) => ({
    url: v.url,
    text: v.text,
    type_index: v.type,
    weight: idx,
  }));

  const categories = formValues.categories?.map((v, idx) => ({
    name: v.categoryName,
    weight: idx,
    sub_categories: v.subcategories?.map((subV, subIdx) => ({
      name: subV.categoryName,
      weight: subIdx,
    })),
  }));
  const qrCodeType = formValues.qr_code_type === 'dynamic';

  const qrCodePayload = {
    ...formValues,
    links,
    categories,
    dynamic: qrCodeType,
    customize_parameters: {
      color: {
        qr: formValues.qr_color,
        font: formValues.font_color,
        border: formValues.border_color,
        background: formValues.background_color,
      },
      set_logo: formValues.set_logo,
      logo_type: formValues.logo_type,
      // frame icon
      set_default_icon: formValues.set_default_icon,
      default_icon: formValues.default_icon,
      // frame text
      template: formValues.template,
      template_text: formValues.template_text,
      font_size: formValues.font_size,
      // edges
      basic_edges: formValues.basic_edges,
      all_edge_shape: formValues.all_edge_shape,
      all_edge_inner_color: formValues.all_edge_inner_color,
      all_edge_outer_color: formValues.all_edge_outer_color,
      top_left_edge_shape: formValues.top_left_edge_shape,
      top_left_edge_inner_color: formValues.top_left_edge_inner_color,
      top_left_edge_outer_color: formValues.top_left_edge_outer_color,
      top_right_edge_shape: formValues.top_right_edge_shape,
      top_right_edge_inner_color: formValues.top_right_edge_inner_color,
      top_right_edge_outer_color: formValues.top_right_edge_outer_color,
      bottom_left_edge_shape: formValues.bottom_left_edge_shape,
      bottom_left_edge_inner_color: formValues.bottom_left_edge_inner_color,
      bottom_left_edge_outer_color: formValues.bottom_left_edge_outer_color,
    },
  };

  return qrCodePayload;
};

export const mapFormValuesToFormData = (
  formValues: QrCodeFormValues
): FormData => {
  const fd = new FormData();

  const {
    customize_parameters,
    logo_image,
    custom_image,
    links,
    categories,
    files_links_image,
    ...rest
  } = mapFormValuesToReq(formValues);

  const keys = Object.keys(rest) as Array<keyof typeof rest>;

  keys.forEach((key) => {
    const value = rest[key];

    if (value) {
      fd.append(key, value.toString());
    }
  });

  if (logo_image) {
    fd.append('logo_image', logo_image);
  }

  if (custom_image) {
    fd.append('custom_image', custom_image);
  }

  if (links) {
    fd.append('links', JSON.stringify(links));
  }

  if (categories) {
    fd.append('categories', JSON.stringify(categories));
  }

  if (files_links_image) {
    fd.append('files_links_image', JSON.stringify(files_links_image));
  }

  fd.append('customize_parameters', JSON.stringify(customize_parameters));

  return fd;
};
