import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import { CardResponse } from '../types';
import { Card } from 'types';

export const mapResponseToCard = (res: CardResponse): Card =>
  compose(
    omit([
      'user_id',
      'exp_month',
      'exp_year',
      'token_id',
      'card_id',
      'created_at',
      'updated_at',
    ]),
    (res) => ({
      ...res,
      userId: res.user_id,
      expMonth: res.exp_month,
      expYear: res.exp_year,
      tokenId: res.token_id,
      cardId: res.card_id,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
    })
  )(res);
