import { ROUTE_FORGOT_PASSWORD } from 'const';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  required,
} from 'modules/utils/validators';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { GoogleAuthButton } from 'ui/button/GoogleAuthButton';
import { Checkbox } from 'ui/checkbox';
import { TextField } from 'ui/input';
import { Link } from 'ui/link';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);
const FACheckbox = fieldAdapter(Checkbox);

export type LoginFormValuesType = {
  email: string;
  password: string;
  remember: boolean;
};

type LoginProps = {
  onSubmit: (args: LoginFormValuesType) => void;
  onOauth: (args: any) => void;
  loading: boolean;
};

export const LoginForm = ({ onSubmit, onOauth, loading }: LoginProps) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine }) => (
      <Flex flexDirection='column' as='form' onSubmit={handleSubmit}>
        <Box mb='16px'>
          <Field
            id='email'
            name='email'
            type='email'
            component={FATextField}
            label='Email'
            placeholder='empty@gmail.com'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mb='16px'>
          <Field
            id='password'
            name='password'
            component={FATextField}
            label='Password'
            placeholder='password'
            type='password'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Flex mb={[20, 66]} alignItems='center' justifyContent='space-between'>
          <Field
            id='remember'
            name='remember'
            component={FACheckbox}
            label='Remember me'
            type='checkbox'
          />
          <Link
            to={ROUTE_FORGOT_PASSWORD}
            variant='footnote'
            color='gray.medium'
            textDecoration='none'
          >
            Forgot your password?
          </Link>
        </Flex>
        <Button variant='filled' type='submit' disabled={loading || pristine}>
          Login
        </Button>
        <Box my={[15, 24]}>
          <Typography
            variant='footnote'
            color='gray.medium'
            textAlign='center'
            fontWeight='normal'
          >
            or login with
          </Typography>
        </Box>
        <GoogleAuthButton
          type='button'
          disabled={loading}
          onOauthSuccess={onOauth}
        />
      </Flex>
    )}
  />
);
