import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useAuth = () => {
  const selectToken = createSelector(
    (state: RootState) => state.auth.token,
    (items) => items
  );
  const token = useSelector(selectToken);

  return Boolean(token);
};
