export const URL_TYPE = 'url';
export const TEXT_TYPE = 'text';
export const EMAIL_TYPE = 'email';
export const VCARD_TYPE = 'vcard';
export const SMS_TYPE = 'sms';
export const WHATSAPP_TYPE = 'whatsapp';
export const SOCIAL_MEDIA_TYPE = 'social_media';
export const FEEDBACK_TYPE = 'feedback';
export const BULK_URL_TYPE = 'bulk_url';
export const IMAGE_TYPE = 'image';
export const PDF_TYPE = 'pdf';

export const DYNAMIC_QR_CODES = [
  URL_TYPE,
  BULK_URL_TYPE,
]

export const DYNAMIC_TYPES = [
  URL_TYPE,
  SOCIAL_MEDIA_TYPE,
  FEEDBACK_TYPE,
  IMAGE_TYPE,
  PDF_TYPE,
];
export const STATIC_TYPES = [
  TEXT_TYPE,
  EMAIL_TYPE,
  VCARD_TYPE,
  SMS_TYPE,
  WHATSAPP_TYPE,
];
