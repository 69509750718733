import styled from 'styled-components';
import {
  color,
  background,
  width,
  height,
  space,
  borderRadius,
  ColorProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  BackgroundProps,
  BorderRadiusProps,
} from 'styled-system';
import { themeGet } from 'theme';

type IconProps = { url: string } & WidthProps &
  HeightProps &
  BackgroundProps &
  SpaceProps &
  BorderRadiusProps;

export const Icon = styled.span<IconProps>`
  display: inline-block;
  width: 20px;
  height: 21px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;

  ${background};
  ${width};
  ${height};
  ${space};
  ${borderRadius};
`;

export const UsernameIcon = styled.div<{ avatar?: string } & ColorProps>`
  background-color: ${themeGet('colors.white.main')};
  background-image: ${({ avatar }) => (avatar ? `url(${avatar})` : 'none')};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  color: ${themeGet('colors.blue.main')};
  font-size: ${themeGet('fontSizes[3]')};
  height: 36px;
  line-height: 2.2;
  text-align: center;
  width: 36px;

  ${color};

  ${({ avatar }) => (avatar ? 'color: transparent;' : '')};
`;
