import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button } from 'ui/button';
import { Error } from 'ui/labels';
import { Icon } from 'ui/icon';
import googleIcon from 'assets/images/google.svg';

const { REACT_APP_GOOGLE_CLIENT_ID = '' } = process.env;

interface GoogleButtonProps {
  onOauthSuccess: (args: any) => void;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
}

export const GoogleAuthButton = ({
  type = 'button',
  disabled,
  onOauthSuccess,
}: GoogleButtonProps) => {
  const [error, setError] = useState();
  const onFailure = ({ details }: { details: any }) => {
    setError(details);
  };

  return (
    <GoogleLogin
      clientId={REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <div>
          <Button
            variant='outlined'
            type={type}
            disabled={renderProps.disabled || disabled}
            onClick={renderProps.onClick}>
            <Icon height='20px' width='20px' url={googleIcon} mr='14px' />
            Login with Google
          </Button>
          {error && <Error>{error}</Error>}
        </div>
      )}
      buttonText='Login'
      onSuccess={onOauthSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    />
  );
};
