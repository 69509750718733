import { CardElement } from '@stripe/react-stripe-js';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Root, Label, InputUI } from './index';

type Props = {
  label: string;
};

export const CardInput = ({ label }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <Root>
      <Label>{label}</Label>
      <InputUI as='div'>
        <CardElement
          options={{
            hideIcon: true,
            style: {
              base: {
                fontSize: theme.fontSizes[2],
                fontFamily: theme.fonts.poppins,
                color: theme.colors.gray.black,
                backgroundColor: theme.colors.gray.light,
                padding: '16px',
                '::placeholder': {
                  color: theme.colors.gray.medium,
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </InputUI>
    </Root>
  );
};
