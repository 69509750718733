import getOr from 'lodash/fp/getOr';
import styled from 'styled-components';
import { Typography } from 'ui/typography';

export const Error = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.red.text};
  line-height: 15px;
  margin-top: 4px;
`;

const TYPE_LABEL_COLORS = {
  sms: { color: '#C4FFC3', label: 'Sms' },
  whatsapp: { color: '#C4FFC3', label: 'WhatsApp' },
  text: { color: '#FFD7F4', label: 'Text' },
  email: { color: '#C4FFC3', label: 'Email' },
  url: { color: '#C3FFFF', label: 'URL' },
  vcard: { color: '#FFD7F4', label: 'vCard' },
  social_media: { color: '#E2D5FF', label: 'Social Media' },
  feedback: { color: '#C3FFFF', label: 'Feedback' },
  image: { color: '#C3FFFF', label: 'Images' },
  pdf: { color: '#C3FFFF', label: 'PDF' },
};

export type TypeLabelVariant =
  | 'sms'
  | 'whatsapp'
  | 'text'
  | 'email'
  | 'url'
  | 'vcard'
  | 'social_media'
  | 'feedback'
  | 'image'
  | 'pdf';

type TypeLabelProps = {
  variant: TypeLabelVariant;
};

const TypeLabelUI = styled(Typography)<{ bg: string }>`
  min-width: 69px;
  padding: 2px 10px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;

  background-color: ${({ bg }) => bg};
`;

export const TypeLabel = (props: TypeLabelProps) => {
  const { color, label } = getOr(
    TYPE_LABEL_COLORS.text,
    props.variant,
    TYPE_LABEL_COLORS
  );

  return (
    <TypeLabelUI
      as='div'
      fontSize='10px'
      lineHeight='15px'
      color='gray.black'
      bg={color}
    >
      {label}
    </TypeLabelUI>
  );
};
