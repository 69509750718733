import { ReactNode, useCallback, useEffect, useState } from 'react';

export const useNotification = (
  message: string | ReactNode | undefined,
  isPermanent?: boolean
) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [key, setKey] = useState(0);

  const handleCloseNotification = useCallback(() => {
    setIsNotificationVisible(false);
  }, []);

  useEffect(() => {
    if (message && !isPermanent) {
      handleCloseNotification();
      setKey(key + 1);

      const show = setTimeout(() => {
        setIsNotificationVisible(true);
      }, 100);

      const notification = setTimeout(handleCloseNotification, 3000);

      return () => {
        clearTimeout(notification);
        clearTimeout(show);
      };
    }

    if (message && isPermanent) {
      setKey(key + 1);
      setIsNotificationVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseNotification, message]);

  return {
    key,
    isNotificationVisible,
    handleCloseNotification,
  };
};
