import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_APP_SET_STATE,
  ACTION_SHOW_MODAL,
  ACTION_CLOSE_MODAL,
  ACTION_APP_FETCH_COUNTRIES,
  ACTION_APP_FETCH_STATES,
  ACTION_APP_FETCH_CITIES,
} from 'const';

export type SetStateActionPlayload = {
  afterSignup: boolean;
  loading: boolean;
  hasMore: boolean;
  error: string | null;
  countries: Array<any>;
  states: Array<any>;
  cities: Array<any>;
  confirmed: boolean;
  success: string | null;
};

export const setAppState = createAction<Partial<SetStateActionPlayload>>(
  ACTION_APP_SET_STATE
);

export type ShowModalPayload = {
  type: string;
  options?: any;
};

export const showModal = createAction<ShowModalPayload>(ACTION_SHOW_MODAL);

export type CloseModalPayload = {
  type: string;
};

export const closeModal = createAction<CloseModalPayload>(ACTION_CLOSE_MODAL);

export const fetchCountries = createAction(ACTION_APP_FETCH_COUNTRIES);
export const fetchStates = createAction<{ countryCode: string }>(
  ACTION_APP_FETCH_STATES
);
export const fetchCities = createAction<{
  countryCode: string;
  stateCode: string;
}>(ACTION_APP_FETCH_CITIES);
