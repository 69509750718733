import { createSlice, Slice } from '@reduxjs/toolkit';
import {
  setAppState,
  SetStateActionPlayload,
  showModal,
  ShowModalPayload,
  closeModal,
  CloseModalPayload,
} from '../actions/app';

export interface AppState {
  afterSignup?: boolean;
  loading?: boolean;
  hasMore?: boolean;
  error?: Object | null;
  modal?: {
    type: string;
    options?: any;
  };
  countries: Array<{ title: string; value: string }>;
  states: Array<{ title: string; value: string; code: string }>;
  cities: Array<{ title: string; value: string; code: string }>;
  confirmed: boolean;
}

const initialState: AppState = {
  afterSignup: false,
  loading: false,
  hasMore: false,
  countries: [],
  states: [],
  cities: [],
  confirmed: false,
};

export const appSlice: Slice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [setAppState.type]: (
      state: AppState,
      { payload }: { payload: SetStateActionPlayload }
    ) => ({
      ...state,
      ...payload,
    }),
    [showModal.type]: (
      state: AppState,
      action: { payload: ShowModalPayload }
    ) => ({
      ...state,
      modal: action.payload,
    }),
    [closeModal.type]: (
      state: AppState,
      action: { payload: CloseModalPayload }
    ) => ({
      ...state,
      modal: undefined,
    }),
  },
});
