import qrCodePausedImage from 'assets/images/qr-code-paused.svg';
import { ROUTE_ROOT } from 'const';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Flex } from 'rebass/styled-components';
import { Link } from 'ui/link';
import { Typography } from 'ui/typography';

export const PagePaused = ({ history }: RouteComponentProps) => (
  <Flex flexDirection='column' alignItems='center' pt={80}>
    <Typography
      fontWeight='extraBold'
      fontSize='36px'
      lineHeight='46.08px'
      color='gray.black'
    >
      Oops..
    </Typography>
    <Box mt={32}>
      <Typography
        fontWeight='bold'
        variant={['h3Mobile', 'h3']}
        color='gray.black'
        textAlign='center'
      >
        This QR code is disabled.
      </Typography>
    </Box>
    <Box mt={22}>
      <img src={qrCodePausedImage} alt='' />
    </Box>
    <Box mt={50}>
      <Typography
        fontWeight='bold'
        variant='subhead'
        color='gray.black'
        textAlign='center'
      >
        If you are the owner of this code, please go to{' '}
        <Link color='blue.main' textDecoration='none' to={ROUTE_ROOT}>
          supercode.com
        </Link>{' '}
        to restore it.
      </Typography>
    </Box>
  </Flex>
);
