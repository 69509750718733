import styled from 'styled-components';

export const VericalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #f1f1f1;
  margin: 1px 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
  margin: 1px 0;
  padding: 0.5px 0;
`;
