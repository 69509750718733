import format from 'date-fns/format';

export const formatTimerTime = (sec: number) => {
  return format(new Date(2020, 12, 21, 0, 0, sec, 0), 'mm:ss');
};

export const formatDate = (
  date: string | Date,
  pattern: string = 'd MMM yyyy'
) => {
  if (typeof date === 'string') {
    return format(new Date(date), pattern);
  }

  return format(date, pattern);
};
