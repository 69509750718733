import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { convertPageToOffset } from 'modules/utils/convert';
import { setAppState } from '../actions/app';
import {
  fetchAllFeedbacks,
  FetchAllFeedbacksActionPayload,
  putFeedbacks,
  putAverageRating,
  fetchCategories,
  putCategories,
  removeFeedback,
  removeFeedbackById,
  RemoveFeedbackActionPayload,
} from '../actions/feedbacks';
import {
  getFeedbacks,
  getFeedbackCategories,
  deleteFeedback,
  GetFeedbacksResponse,
} from 'api/feedback';
import { Category } from 'types';

function* fetchFeedbacks({
  payload,
}: PayloadAction<FetchAllFeedbacksActionPayload>) {
  try {
    const { qrCodeId, page, perPage, sort } = payload;

    yield put(setAppState({ loading: true, error: null }));

    const { limit, offset } = convertPageToOffset(page, perPage);
    const { averageRating, feedbacks }: GetFeedbacksResponse = yield call(
      getFeedbacks,
      {
        qrCodeId,
        limit,
        offset,
        sort,
      }
    );

    const hasMore = feedbacks.length === payload.perPage;

    yield put(putFeedbacks(feedbacks));
    yield put(putAverageRating(averageRating));

    yield put(setAppState({ loading: false, hasMore, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* fetchFeedbackCategories() {
  try {
    yield put(setAppState({ error: null, loading: true }));
    const entites: Category[] = yield call(getFeedbackCategories);

    yield put(putCategories(entites));
    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* removeFeedbackSaga({
  payload,
}: PayloadAction<RemoveFeedbackActionPayload>) {
  try {
    yield put(setAppState({ error: null, loading: true }));

    const { feedbackIds } = payload;

    yield call(deleteFeedback, payload);

    for (const id of feedbackIds) {
      yield put(removeFeedbackById({ id }));
    }
    yield put(setAppState({ error: null, loading: false }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

export default function* watcher() {
  yield takeLatest(fetchAllFeedbacks.type, fetchFeedbacks);
  yield takeLatest(fetchCategories.type, fetchFeedbackCategories);
  yield takeLatest(removeFeedback.type, removeFeedbackSaga);
}
