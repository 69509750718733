export const breakpoints = ['576px', '768px', '1024px', '1290px'];

export const bps = Object.freeze({
  xs: breakpoints[0],
  sm: breakpoints[1],
  md: breakpoints[2],
  xl: breakpoints[3],
});

export type Breakpoints = typeof bps;

type MqType = Record<keyof Breakpoints, string>;

const keys = Object.keys(bps) as Array<keyof Breakpoints>;

export const mq: MqType = keys.reduce(
  (acc, key) => ({
    ...acc,
    [key]: `@media (min-width: ${bps[key]})`,
  }),
  {} as MqType
);
