import resetIcon from 'assets/images/close-red.svg';
import addIcon from 'assets/images/plus-gray.svg';
import styled from 'styled-components';
import { colors } from 'theme/colors';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  border-radius: 10px;
  border: 1px solid transparent;
  overflow: hidden;
  transition: border 0.2s;

  &:hover {
    border: 1px solid ${colors.blue.main};
  }

  & img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const ButtonTextWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

const ResetButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  background-color: ${colors.white.main};
  border: 1px solid #e6e9ef;
  z-index: 1;
  border-radius: 50%;
  transform: translate(50%, -50%);
`;

type Props = {
  isFile: boolean;
  file?: string;
  onUpload: () => void;
  onReset: () => void;
  handleClickUploaded: () => void;
};

export const PdfInput = ({
  file,
  isFile,
  onUpload,
  onReset,
  handleClickUploaded,
}: Props) => (
  <Root>
    {isFile ? (
      <ButtonWrapper>
        <ImageButton onClick={handleClickUploaded}>
          <img src={file} alt={file} />
        </ImageButton>
        <ResetButton onClick={() => onReset()}>
          <Icon url={resetIcon} width='9px' height='9px' />
        </ResetButton>
      </ButtonWrapper>
    ) : (
      <ImageButton onClick={onUpload}>
        <Icon url={addIcon} width='14px' height='14px' />
        <ButtonTextWrapper>
          <Typography variant='footnote' color='gray.medium'>
            Upload
          </Typography>
        </ButtonTextWrapper>
      </ImageButton>
    )}
  </Root>
);
