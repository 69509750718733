import { formatDate } from 'modules/utils/formatDate';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { mq } from 'theme/breakpoints';
import { colors } from 'theme/colors';
import { Link } from 'ui/link';
import { Typography } from 'ui/typography';

const Root = styled.div`
  width: 100%;
  background-color: ${colors.red.main};
  padding: 4px 8px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1258px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${mq.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const RetryLink = styled(Link)`
  display: inline-block;
  margin-top: -16px;
  margin-left: 20px;
  padding: 3px 16px;
  background-color: ${themeGet('colors.white.main')};
  font-size: 12px;
  line-height: 18px;
  color: ${themeGet('colors.gray.black')};
  border-radius: 6px;
  text-decoration: none;

  ${mq.sm} {
    margin-top: 0;
  }
`;

const SuspendedTypography = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  pastDueDate: Date;
  pausedDynamicQrCodes: number;
  planName: string;
};

const codesPhrase = (pausedDynamicQrCodes: number) => {
  if (pausedDynamicQrCodes > 0) {
    return `: ${pausedDynamicQrCodes} dynamic ${
      pausedDynamicQrCodes > 1 ? 'codes are' : 'code is'
    } now paused`;
  } else {
    return '';
  }
};

const buildSuspendedText = ({ planName, pastDueDate, pausedDynamicQrCodes }: Props) => {
  return 'Your ' +
    planName +
    ' subscription has been suspended at ' +
    formatDate(pastDueDate) +
    '. Check your payment details or your subscription will be canceled in few weeks.';
};

export const PastDue = ({ planName, pastDueDate, pausedDynamicQrCodes }: Props) => (
  <Root>
    <Container>
      <Typography fontSize='10px' lineHeight='15px' color='white.main'>
        {buildSuspendedText({ planName, pastDueDate, pausedDynamicQrCodes })}
      </Typography>
      <SuspendedTypography fontSize='10px' lineHeight='15px' color='white.main'>
        Check your card details
        {/* <RetryLink to={ROUTE_UPGRADE_PLAN}>Upgrade</UpgradeLink> */}
      </SuspendedTypography>
    </Container>
  </Root>
);
