import { useState } from 'react';
import { Box } from 'rebass/styled-components';
import { Card } from 'ui/card';
import { Error } from 'ui/labels';
import { Confirm } from 'ui/modal/Confirm';
import { deleteAnalytics } from 'api/stats';

type Props = {
  onClose: () => void;
  options: { id: number, afterSubmitting: () => void };
};

// TODO: fix callback prop
export const ResetScansModal = ({ onClose, options: { id, afterSubmitting } }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleRemove = async () => {
    try {
      setLoading(true);
      setError(undefined);
      await deleteAnalytics({ id });
      afterSubmitting();
      onClose();
    } catch (e) {
      setError(e?.message || 'Failed to reset scans');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Confirm
        heading='Reset all scans'
        subheading='All statistics for this code will be permanently reset. Want to reset?'
        submitText='Reset'
        onCalcel={onClose}
        onSubmit={handleRemove}
        loading={loading}
      />
      {error && (
        <Box px={24} pb={24}>
          <Error>{error}</Error>
        </Box>
      )}
    </Card>
  );
};
