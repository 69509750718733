import { useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { Button } from 'ui/button';
import { Typography } from 'ui/typography';
import { Notification } from "ui/notification";
import { useNotification } from "hooks/useNotification";

const AvatarPlaceholder = styled.div<{ src?: string }>`
  background: #ebf1fc;
  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    `};
  border-radius: 10px;
  padding: 21px 0;
  text-align: center;
  width: 80px;
  height: 80px;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  color: #3278e4;
`;

type Props = {
  shortName: string;
  onChange: (v?: File) => void;
  value?: File | string;
};

export const AvatarInput = ({ shortName, onChange, value }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string>()
  const {
    key,
    isNotificationVisible,
    handleCloseNotification,
  } = useNotification(error);
  const image = value
    ? typeof value === 'string'
      ? value
      : URL.createObjectURL(value)
    : undefined;

  const onUpload = () => {
    inputRef?.current?.click();
  };

  const onFileUpload = (event: any) => {
    setError(undefined)
    const file = event.target.files[0];
    if (file?.size > 5e6) {
      setError("Please, upload image less then 5Mb")
      return
    }

    onChange(file);
  };

  const onFileReset = () => {
    onChange(undefined);
  };

  return (
    <Flex alignItems='center'>
      <Notification
        key={key}
        message={error}
        type='error'
        isNotificationVisible={isNotificationVisible}
        onClose={handleCloseNotification}
      />
      <Box mr='16px'>
        <AvatarPlaceholder src={image}>
          {image ? '' : shortName}
        </AvatarPlaceholder>
        <input
          ref={inputRef}
          type='file'
          style={{ width: 0, height: 0, visibility: 'hidden', float: 'left' }}
          onChange={onFileUpload}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
      </Box>
      <Box>
        <Box mb='8px'>
          <Typography color='gray.medium' fontSize='10px' lineHeight='15px'>
            Upload a photo of no more than 5 MB
          </Typography>
        </Box>
        <Flex>
          <Box mr={'8px'}>
            <Button
              onClick={onUpload}
              variant='filled'
              backgroundColor='blue.mediumLight'
              padding={['6px 30px', '6px 30px']}
            >
              <Typography color='blue.main' fontSize='12px' lineHeight='18px'>
                Upload
              </Typography>
            </Button>
          </Box>
          {image && (
            <Box>
              <Button
                onClick={onFileReset}
                variant='filled'
                backgroundColor='red.light'
                padding={['6px 30px', '6px 30px']}
              >
                <Typography color='red.text' fontSize='12px' lineHeight='18px'>
                  Delete
                </Typography>
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
