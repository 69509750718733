import edge1 from 'assets/edges/form-0.png';
import edge13 from 'assets/edges/form-12.png';
import edge2 from 'assets/edges/form-1.png';
import edge6 from 'assets/edges/form-5.png';
import edge3 from 'assets/edges/form-2.png';
import edge4 from 'assets/edges/form-3.png';
import edge5 from 'assets/edges/form-4.png';
import edge7 from 'assets/edges/form-6.png';
import edge8 from 'assets/edges/form-7.png';
import edge9 from 'assets/edges/form-8.png';
import edge10 from 'assets/edges/form-9.png';
import edge11 from 'assets/edges/form-10.png';
import edge12 from 'assets/edges/form-11.png';

import logo0 from 'assets/logos/icon_logo0.png';
import logo1 from 'assets/logos/icon_logo1.png';
import logo2 from 'assets/logos/icon_logo2.png';

import frame0 from 'assets/frames/frame_0.svg';
import frame1 from 'assets/frames/frame_1.svg';
import frame2 from 'assets/frames/frame_2.svg';

import {
  URL_TYPE,
  TEXT_TYPE,
  EMAIL_TYPE,
  SMS_TYPE,
  WHATSAPP_TYPE,
  VCARD_TYPE,
  SOCIAL_MEDIA_TYPE,
  FEEDBACK_TYPE,
  BULK_URL_TYPE,
} from './qrCodeTypes';

export const QR_CODES_SLECT_FORM_OPTIONS = [
  URL_TYPE,
  TEXT_TYPE,
  EMAIL_TYPE,
  SMS_TYPE,
  WHATSAPP_TYPE,
  VCARD_TYPE,
  SOCIAL_MEDIA_TYPE,
  FEEDBACK_TYPE,
  BULK_URL_TYPE,
];

export const QR_CODES_EDGES = [
  { image: edge1, type: 'RECT_DOTS', value: 0 },
  { image: edge13, type: 'ROUND_DOTS', value: 12 },
  { image: edge2, type: 'ROUND', value: 1 },
  { image: edge6, type: 'ROUNDED', value: 5 },
  { image: edge3, type: 'TOP_LEFT_ROUND_CORNER', value: 2 },
  { image: edge4, type: 'TOP_RIGHT_ROUND_CORNER', value: 3 },
  { image: edge5, type: 'BOTTOM_LEFT_ROUND_CORNER', value: 4 },
  { image: edge7, type: 'LEAF_LEFT', value: 6 },
  { image: edge8, type: 'LEAF_RIGHT', value: 7 },
  { image: edge9, type: 'TOP_LEFT_CORNER', value: 8 },
  { image: edge10, type: 'TOP_RIGHT_CORNER', value: 9 },
  { image: edge11, type: 'BOTTOM_LEFT_CORNER', value: 10 },
  { image: edge12, type: 'BOTTOM_RIGHT_CORNER', value: 11 },
];

export const QR_CODES_EDGES_BY_VALUE: {
  [key: number]: { image: any; type: string; value: number };
} = QR_CODES_EDGES.reduce((acc, edge) => ({ ...acc, [edge.value]: edge }), {});

export const QR_CODES_EDGES_BY_TYPE: {
  [key: string]: { image: any; type: string; value: number };
} = QR_CODES_EDGES.reduce((acc, edge) => ({ ...acc, [edge.type]: edge }), {});

export const QR_CODES_LOGOS = [
  { image: logo0, value: 1 },
  { image: logo1, value: 2 },
  { image: logo2, value: 3 },
];

export const QR_CODES_LOGOS_BY_VALUE: {
  [key: number]: { image: any; value: number };
} = QR_CODES_LOGOS.reduce((acc, logo) => ({ ...acc, [logo.value]: logo }), {});

const INIT_FRAME_WIDTH = 100;

const getTopImageIntoFrameInsetion = (
  frameWidth: number,
  frameHeight: number
) => {
  const relativeWidthRation = frameWidth / INIT_FRAME_WIDTH;
  const x1 = 7 * relativeWidthRation;
  const y1 = 7 * relativeWidthRation;
  const imageWidth = 86 * relativeWidthRation;

  return { x1, y1, imageWidth };
};

const getBottomImageIntoFrameInsetion = (
  frameWidth: number,
  frameHeight: number
) => {
  const relativeWidthRation = frameWidth / INIT_FRAME_WIDTH;
  const x1 = 7 * relativeWidthRation;
  const imageWidth = 86 * relativeWidthRation;
  const y1 = frameHeight - x1 - imageWidth;

  return { x1, y1, imageWidth };
};

export const QR_CODE_FRAMES = [
  {
    image: frame0,
    value: 'FULL_BORDER_BOTTOM_TEMPLATE',
    heightRelation: 210 / 167,
    frameWidth: 1000,
    getImageInsertion: getTopImageIntoFrameInsetion,
  },
  {
    image: frame1,
    value: 'TRIANGLE_BOTTOM_TEMPLATE',
    heightRelation: 1.365,
    frameWidth: 1000,
    getImageInsertion: getTopImageIntoFrameInsetion,
  },
  {
    image: frame2,
    value: 'TRIANGLE_TOP_TEMPLATE',
    heightRelation: 1.365,
    frameWidth: 1000,
    getImageInsertion: getBottomImageIntoFrameInsetion,
  },
];

export const QR_CODES_NO_FRAME = 'STANDARD_TEMPLATE';

export const QR_CODES_FRAMES_BY_VALUE: {
  [key: string]: {
    image: any;
    value: string;
    heightRelation: number;
    frameWidth: number;
    getImageInsertion: (
      frameWidth: number,
      frameHeight: number
    ) => { x1: number; y1: number; imageWidth: number };
  };
} = QR_CODE_FRAMES.reduce(
  (acc, frame) => ({ ...acc, [frame.value]: frame }),
  {}
);

export const QR_CODES_FONT_SIZES = [
  { value: 75, title: 75 },
  { value: 100, title: 100 },
  { value: 125, title: 125 },
  { value: 150, title: 150 },
];

const STATIC = 'static';
const DYNAMIC = 'dynamic';
const REDEEMABLE = 'redeemable';

export const QR_CODE_BULK_OPTIONS = [
  { value: DYNAMIC, title: 'Generate dynamic codes from file' },
  { value: STATIC, title: 'Generate static codes from file' },
  { value: REDEEMABLE, title: 'Generate bulk codes, redeemable once' },
];

export const QR_CODE_BULK_DELIMETER_OPTIONS = [
  { value: ',', title: 'Comma' },
  { value: ';', title: 'Semicolon' },
];

export const QR_CODE_CHUNK_SIZE = 25;

export const QR_CODE_TYPES = [
  { value: DYNAMIC, title: 'Dynamic' },
  { value: STATIC, title: 'Static' },
];
