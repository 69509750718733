import { useSelector } from 'react-redux';
import { selectCurrentPlan } from 'store/selectors/user';
import {
  URL_TYPE,
  TEXT_TYPE,
  EMAIL_TYPE,
  VCARD_TYPE,
  SMS_TYPE,
  WHATSAPP_TYPE,
} from 'const';
import { PlanPermissions } from 'types';

export const QR_CODES_DEFAULT_PLAN: PlanPermissions = {
  staticQrCodesLimit: null,
  dynamicQrCodesLimit: null,
  download: {
    types: [],
    highQuality: false,
  },
  types: [URL_TYPE, TEXT_TYPE, EMAIL_TYPE, VCARD_TYPE, SMS_TYPE, WHATSAPP_TYPE],
  foldersAccess: false,
  analytics: {
    type: null,
    download: [],
    permissions: [],
  },
  users: null,
};

export const useFeatureAccess = () => {
  const plan = useSelector(selectCurrentPlan);

  const currentPlanPermissions = plan?.permissions || QR_CODES_DEFAULT_PLAN

  return {
    isFolderAccessTrue: currentPlanPermissions.foldersAccess,
    allowedTypes: currentPlanPermissions.types,
  };
};
