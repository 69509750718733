import { ROUTE_LOGIN } from 'const';
import { useAuth } from 'modules/utils/useAuth';
import { Redirect, Route, RouteProps } from 'react-router';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  let isAuth = useAuth();

  if (isAuth) {
    return <Route {...rest} component={component} />;
  }
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            state: { from: location },
          }}
        />
      )}
    />
  );
};
