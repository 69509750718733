import closeButton from 'assets/images/close.svg';
import leftButton from 'assets/images/left-arrow.svg';
import { MODAL_SAVE_TO_FOLDER, ROUTE_UPGRADE_PLAN } from 'const';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from 'rebass/styled-components';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Dispatch } from 'store';
import { createFolderRoutinePromiseCreator } from 'store/actions/folders';
import { Folder } from 'types';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { EditFolderForm, EditFolderValueType } from 'ui/forms';
import { Icon } from 'ui/icon';
import { Link } from 'ui/link';
import { Typography } from 'ui/typography';
import { useModal } from '../index';

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button p='8px' onClick={onClick}>
    <Icon url={closeButton} width={12} height={12} />
  </Button>
);

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <Button p='8px' onClick={onClick}>
    <Icon url={leftButton} width={8} height={12} />
  </Button>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  create: bindPromiseCreators(createFolderRoutinePromiseCreator, dispatch),
});

const enhance = connect(null, mapDispatchToProps);

type Props = {
  onClose: () => void;
  options: {
    previousModal?: string;
    previousOptions?: any;
    onSubmit?: (args: { id: number }) => void;
  };
  create: (args: { name: string }) => PromiseLike<Folder>;
};

export const CreateModalModal = enhance(
  ({ onClose, create, options }: Props) => {
    const { previousModal, previousOptions, onSubmit } = options;
    const { showModal } = useModal();
    const [error, setError] = useState<string | undefined>();

    const goBack = () => {
      showModal(MODAL_SAVE_TO_FOLDER, previousOptions);
    };

    const handleClose = () => {
      if (previousModal) {
        goBack();

        return;
      }

      onClose();
    };

    const handleSubmit = async (v: EditFolderValueType) => {
      try {
        setError(undefined);
        const folder: Folder = await create(v);

        if (onSubmit) {
          onSubmit({ id: folder.id });
        }

        handleClose();
      } catch (e) {
        setError(e.message || e);
      }
    };

    return (
      <Card>
        <Box p={24} width={[1, 1, 340]} minHeight={[1, 1, 392]} height='100%'>
          <Flex alignItems='center' justifyContent='space-between'>
            <Flex alignItems='center'>
              {previousModal && (
                <Box mr='12px'>
                  <BackButton onClick={goBack} />
                </Box>
              )}
              <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
                Create folder
              </Typography>
            </Flex>
            <Box>
              <CloseButton onClick={onClose} />
            </Box>
          </Flex>
          <EditFolderForm onSubmit={handleSubmit} onClose={handleClose} />
          {error && (
            <Box mt={16}>
              <Typography
                variant='footnote'
                color='red.main'
                textAlign='center'
              >
                Please{' '}
                <Link
                  to={ROUTE_UPGRADE_PLAN}
                  onClick={onClose}
                  variant='footnote'
                  color='red.main'
                >
                  upgrade
                </Link>{' '}
                to create a folder
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }
);
