import { Box } from 'rebass/styled-components';
import { Card } from 'ui/card';
import { WrapperSpinner } from 'ui/spinner';
import { Typography } from 'ui/typography';

type LoadingMadalProps = {
  options: {
    message?: string;
  };
};

export const LoadingModal = ({ options }: LoadingMadalProps) => {
  const { message = 'Please, wait...' } = options;

  return (
    <Card>
      <Box py={24} px={[24, 24, 48]} width={1} maxWidth={340}>
        <Box>
          <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
            {message}
          </Typography>
        </Box>
        <Box>
          <WrapperSpinner size='36px' />
        </Box>
      </Box>
    </Card>
  );
};
