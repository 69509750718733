import { StopPropagationWrapper } from 'modules/utils/stopPropagate';
import { cloneElement, ReactElement, ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { bottom, layout, left, right, top } from 'styled-system';
import { useDropdown } from './useDropdown';

const ANIMATION_NAME = 'dropdown-overlay';

const DropdownContent = styled.div<{ zIndex?: number }>`
  position: absolute;
  z-index: ${({ zIndex = 100 }) => zIndex};
  display: block;
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${layout};

  transition: transform ease-out 100ms, opacity ease-in 50ms;

  &.${ANIMATION_NAME}-enter {
    transform: translateY(-50px);
    opacity: 0;
  }

  &.${ANIMATION_NAME}-exit, &.${ANIMATION_NAME}-enter-active {
    transform: translateY(0);
    opacity: 1;
  }

  &.${ANIMATION_NAME}-exit-active {
    transform: translateY(-50px);
    opacity: 0;
    transition: transform ease-in 100ms, opacity ease-in 70ms;
  }
`;

const DropdownRoot = styled.div<{ block?: boolean }>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  position: relative;
`;

type Position = {
  top?: number | string | string[];
  left?: number | string | string[];
  right?: number | string | string[];
  bottom?: number | string | string[];
};

type DropdownOverlayProps = {
  isOpen: boolean;
  children: ReactNode;
  position?: Position | Position[];
  // TODO: change type of ref
  innerRef: any;
  fullWidth: boolean;
  overflow: boolean;
  zIndex?: number;
};

const OverlayWrapper = styled.div<{ fullWidth: boolean; overflow: number }>(
  ({ fullWidth }) =>
    fullWidth
      ? {
          width: '100%',
          '& > div > div': {
            width: '100%',
          },
        }
      : {},
  ({ overflow }) =>
    overflow
      ? {
          overflowY: 'auto',
          '& > div > div': {
            width: '100%',
            overflow: 'auto',
            maxHeight: '360px',
          },
        }
      : {}
);

export const DropdownOverlay = ({
  isOpen,
  innerRef,
  children,
  position,
  fullWidth,
  overflow,
  zIndex,
}: DropdownOverlayProps) => {
  return (
    <OverlayWrapper fullWidth={fullWidth} overflow={overflow ? 1 : 0}>
      <TransitionGroup>
        {isOpen && (
          <CSSTransition
            in={isOpen}
            classNames={ANIMATION_NAME}
            timeout={250}
            nodeRef={innerRef}
          >
            <DropdownContent ref={innerRef} zIndex={zIndex} {...position}>
              {children}
            </DropdownContent>
          </CSSTransition>
        )}
      </TransitionGroup>
    </OverlayWrapper>
  );
};

type DropdownProps = {
  isOpen: boolean;
  toggleSlot: ReactElement<any>;
  onToggle: (v: boolean) => void;
  children: ReactNode;
  position?: Position | Position[];
  block?: boolean;
  fullWidth?: boolean;
  overflow?: boolean;
  zIndex?: number;
};

export const Dropdown = ({
  isOpen,
  toggleSlot,
  onToggle,
  position,
  children,
  block,
  fullWidth = false,
  overflow = false,
  zIndex,
}: DropdownProps) => {
  const [toggleProps, dropdownRef] = useDropdown(isOpen, onToggle);

  return (
    <DropdownRoot block={block}>
      {cloneElement(toggleSlot, toggleProps)}
      <StopPropagationWrapper>
        <DropdownOverlay
          fullWidth={fullWidth}
          overflow={overflow}
          zIndex={zIndex}
          isOpen={isOpen}
          position={position}
          innerRef={dropdownRef}
        >
          {children}
        </DropdownOverlay>
      </StopPropagationWrapper>
    </DropdownRoot>
  );
};
