import globalIcon from 'assets/social_logos/global.svg';
import fbIcon from 'assets/social_logos/facebook.svg';
import twitterIcon from 'assets/social_logos/twitter.svg';
import instagramIcon from 'assets/social_logos/instagram.svg';
import snapchatIcon from 'assets/social_logos/global.svg';

import youtubeIcon from 'assets/social_logos/youtube.svg';
import pinterestIcon from 'assets/social_logos/pinterest.svg';
import githubIcon from 'assets/social_logos/github.svg';
import tumblerIcon from 'assets/social_logos/tumblr.svg';
import lineIcon from 'assets/social_logos/line.svg';

import googlePlusIcon from 'assets/social_logos/google-plus.svg';
import tripAdvisorIcon from 'assets/social_logos/tripadvisor.svg';

import linkedinIcon from 'assets/social_logos/linkedin.svg';
import xingIcon from 'assets/social_logos/xing.svg';
import flickerIcon from 'assets/social_logos/flickr.svg';
import vimeoIcon from 'assets/social_logos/vimeo.svg';
import dribbbleIcon from 'assets/social_logos/dribble.svg';

import vkIcon from 'assets/social_logos/vk.svg';
import skypeIcon from 'assets/social_logos/skype.svg';
import redditIcon from 'assets/social_logos/reddit.svg';
import wechatIcon from 'assets/social_logos/wechat.svg';

import socialMediaBackground1 from 'assets/social_bgs/template1.png';
import socialMediaBackground2 from 'assets/social_bgs/template2.png';
import socialMediaBackground3 from 'assets/social_bgs/template3.png';

export const SOCIAL_MEDIAS = [
  { type: '0', title: 'Global page', logo: globalIcon },
  {
    type: '1',
    title: 'Facebook page',
    url: 'https://facebook.com',
    logo: fbIcon,
  },
  {
    type: '2',
    title: 'Twitter',
    url: 'https://twitter.com',
    logo: twitterIcon,
  },
  {
    type: '3',
    title: 'Instagram',
    url: 'https://instagram.com',
    logo: instagramIcon,
  },
  {
    type: '4',
    title: 'Snapchat',
    url: 'https://snapchat.com',
    logo: snapchatIcon,
  },
  {
    type: '5',
    title: 'Youtube page',
    url: 'https://youtube.com',
    logo: youtubeIcon,
  },
  {
    type: '6',
    title: 'Pinterest page',
    url: 'https://pinterest.com',
    logo: pinterestIcon,
  },
  {
    type: '7',
    title: 'Github page',
    url: 'https://github.com',
    logo: githubIcon,
  },
  {
    type: '8',
    title: 'Tumblr page',
    url: 'https://tumblr.com',
    logo: tumblerIcon,
  },
  { type: '9', title: 'Line page', url: 'https://Line.me', logo: lineIcon },
  {
    type: '10',
    title: 'TripAdvisor page',
    url: 'https://tripadvisor.com',
    logo: tripAdvisorIcon,
  },
  {
    type: '11',
    title: 'Google plus page',
    url: 'https://aboutme.google.com"',
    logo: googlePlusIcon,
  },
  {
    type: '12',
    title: 'Linkedin page',
    url: 'https://linkedin.com',
    logo: linkedinIcon,
  },
  { type: '13', title: 'Xing page', url: 'https://xing.com', logo: xingIcon },
  {
    type: '14',
    title: 'Flickr page',
    url: 'https://flickr.com',
    logo: flickerIcon,
  },
  {
    type: '15',
    title: 'Vimeo page',
    url: 'https://vimeo.com',
    logo: vimeoIcon,
  },
  {
    type: '16',
    title: 'Dribble page',
    url: 'https://dribbble.com',
    logo: dribbbleIcon,
  },
  { type: '17', title: 'VK page', url: 'https://vk.com', logo: vkIcon },
  {
    type: '18',
    title: 'Skype',
    url: 'https://skype.com',
    logo: skypeIcon,
  },
  {
    type: '19',
    title: 'Reddit page',
    url: 'https://reddit.com',
    logo: redditIcon,
  },
  {
    type: '20',
    title: 'Wechat',
    url: 'https://weechat.org',
    logo: wechatIcon,
  },
];

export const SOCIAL_MEDIAS_BY_TYPES: {
  [type: string]: { type: string; title: string; url: string; logo?: string };
} = SOCIAL_MEDIAS.reduce((acc, item) => ({ ...acc, [item.type]: item }), {});

export const SOCIAL_MEDIA_BACKGROUNDS = [
  {
    image_index: 1,
    template: socialMediaBackground1,
  },
  {
    image_index: 2,
    template: socialMediaBackground2,
  },
  {
    image_index: 3,
    template: socialMediaBackground3,
  },
];
