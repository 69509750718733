import { Flex, Box } from 'rebass/styled-components';
import { SkeletonProvider, Skeleton } from 'ui/skeleton';

export const DetailsSkeleton = () => (
  <SkeletonProvider>
    <Box pt={32}>
      <Box maxWidth={391}>
        <Skeleton height={41} />
      </Box>
      <Box maxWidth={539} mt={16}>
        <Skeleton height={26} borderRadius='6px' />
      </Box>
      <Flex mt={23} alignItems='flex-start'>
        <Box width={1} maxWidth={518} mr={26}>
          <Skeleton height={672} />
        </Box>
        <Box width={1} maxWidth={331}>
          <Skeleton height={188} />
        </Box>
      </Flex>
    </Box>
  </SkeletonProvider>
);
