import { Card } from 'ui/card';
import { Confirm } from 'ui/modal/Confirm';

type Props = {
  onClose: () => void;
  options: { count: number };
};

export const RemoveFeedbackModal = ({ onClose, options: { count } }: Props) => {
  return (
    <Card>
      <Confirm
        heading='Delete Feedback'
        subheading={`The selected items (${count}) will be deleted permanently. Delete?`}
        onCalcel={onClose}
        onSubmit={() => {}}
      />
    </Card>
  );
};
