import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { setAppState } from '../actions/app';
import {
  putPlans,
  fetchPlans,
  upgradePlanRoutine,
  UpgradePlanActionPayload,
  cancelPlanDowngradeRoutine,
} from '../actions/plans';
import { getPlans, updatePlan, cancelPlanDowngrade } from 'api/plans';

function* fetchActualPlans() {
  try {
    yield put(setAppState({ loading: true, error: null }));

    const data = yield call(getPlans);

    yield put(putPlans(data));
    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message || e, loading: false }));
  }
}

function* upgradePlan({ payload }: PayloadAction<UpgradePlanActionPayload>) {
  try {
    yield put(upgradePlanRoutine.request());
    yield call(updatePlan, payload);
    yield put(upgradePlanRoutine.success());
  } catch (e) {
    yield put(upgradePlanRoutine.failure(e.message || e));
  } finally {
    yield put(upgradePlanRoutine.fulfill());
  }
}

function* cancelDowngrade() {
  try {
    yield put(cancelPlanDowngradeRoutine.request());
    yield call(cancelPlanDowngrade);
    yield put(cancelPlanDowngradeRoutine.success());
  } catch (e) {
    yield put(upgradePlanRoutine.failure(e.message || e));
  } finally {
    //
  }
}

export default function* watcher() {
  yield takeLatest(fetchPlans.type, fetchActualPlans);
  yield takeLatest(upgradePlanRoutine.TRIGGER, upgradePlan);
  yield takeLatest(cancelPlanDowngradeRoutine.TRIGGER, cancelDowngrade)
}
