import {
  ROUTE_ACCEPT_INTIVATION,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGN_UP,
  ROUTE_CONFIRM_USER_EMAIL,
} from 'const';
import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { logout } from 'store/actions/auth';
import { CommonSkeleton, withSuspense } from './AppRoutes';
import { AuthRoute } from './AuthRoute';
import { PrivateRoute } from './PrivateRoute';

const CommonAuthPage = lazy(() => import('pages/auth/common'));
const RequestResetPwdPage = lazy(() => import('pages/auth/request-password'));
const ResetPwdPage = lazy(() => import('pages/auth/reset-password'));
const InvitationPage = lazy(() => import('pages/invitation'));
const ConfirmEmailPage = lazy(() => import('pages/confirmation'))

export const authRoutes = [
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_SIGN_UP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_ACCEPT_INTIVATION,
  ROUTE_CONFIRM_USER_EMAIL
];

const AuthRoutes = () => {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route
        exact
        path={[ROUTE_SIGN_UP, ROUTE_LOGIN]}
        render={(props) =>
          withSuspense(<CommonAuthPage {...props} />, <CommonSkeleton />)
        }
      />
      <AuthRoute
        exact
        path={ROUTE_FORGOT_PASSWORD}
        render={() => withSuspense(<RequestResetPwdPage />, <CommonSkeleton />)}
      />
      <AuthRoute
        exact
        path={ROUTE_RESET_PASSWORD}
        render={(props) =>
          withSuspense(<ResetPwdPage {...props} />, <CommonSkeleton />)
        }
      />
      <AuthRoute
        exact
        path={ROUTE_ACCEPT_INTIVATION}
        render={(props) =>
          withSuspense(<InvitationPage {...props} />, <CommonSkeleton />)
        }
      />
      <Route
        exact
        path={ROUTE_LOGOUT}
        component={() => {
          dispatch(logout());

          return null;
        }}
      />
      <PrivateRoute
        exact
        path={ROUTE_CONFIRM_USER_EMAIL}
        render={(props) =>
          withSuspense(<ConfirmEmailPage {...props} />, <CommonSkeleton />)
        }
      />
    </Switch>
  );
};
export default AuthRoutes;
