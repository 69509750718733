import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { appSlice, AppState } from './reducers/app';
import { authSlice, AuthState } from './reducers/auth';
import { userSlice, UserState } from './reducers/user';
import { foldersSlice, FoldersState } from './reducers/folders';
import { plansSlice, PlansState } from './reducers/plans';
import { qrCOdesSlice, QrCodessState } from './reducers/qrCodes';
import { feedbacksSlice, FeedbacksState } from './reducers/feedbacks';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    folders: foldersSlice.reducer,
    plans: plansSlice.reducer,
    qrCodes: qrCOdesSlice.reducer,
    feedbacks: feedbacksSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<
  (
    ...args: any
  ) => {
    app: AppState;
    auth: AuthState;
    user: UserState;
    folders: FoldersState;
    plans: PlansState;
    qrCodes: QrCodessState;
    feedbacks: FeedbacksState;
  }
>;
export type Dispatch = typeof store.dispatch;
