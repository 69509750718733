import { createSlice } from '@reduxjs/toolkit';
import { putPlans, removeAllPlans } from '../actions/plans';
import { Plan, Plans } from 'types';

export interface PlansState {
  recommendation?: string;
  plans: Plan[]
  ids: number[];
  byIds: {
    [id: number]: Plan | undefined;
  };
}

const initialState: PlansState = {
  plans: [],
  recommendation: '',
  ids: [],
  byIds: {},
};

export const plansSlice = createSlice({
  name: 'plans',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [putPlans.type]: (state, { payload: plansData }: { payload: Plans }) => {
      const plans = plansData.plans;
      const newIds = plans.map((f) => f.id);
      const newPlans = plans.reduce(
        (acc, f) => ({ ...acc, [f.id]: f }),
        {}
      );

      return {
        recommendation: plansData.recommendation,
        plans: plans,
        ids: newIds,
        byIds: newPlans,
      };
    },
    [removeAllPlans.type]: () => initialState,
  },
});
