import { range } from 'modules/utils/range';
import { Box, Flex } from 'rebass/styled-components';
import { Skeleton, SkeletonProvider } from 'ui/skeleton';

const ITEMS_RANGE = range(10);

export const QrCodeListSkeleton = () => (
  <SkeletonProvider>
    <Box maxWidth={1270} mx={'auto'} py={24} pt={56}>
      <Flex alignItems='center' justifyContent='space-between'>
        <Box width={['48%', 1]} maxWidth={272}>
          <Skeleton height={48} />
        </Box>
        <Box width={['48%', 1]} maxWidth={132}>
          <Skeleton height={36} />
        </Box>
      </Flex>
      <Box mt={16} maxWidth={351}>
        <Skeleton height={32} borderRadius='6px' />
      </Box>
      <Flex mt={16} alignItems='center' justifyContent='space-between'>
        <Box width={['48%', 1]} maxWidth={126}>
          <Skeleton height={24} borderRadius='6px' />
        </Box>
        <Box width={['48%', 1]} maxWidth={60}>
          <Skeleton height={18} borderRadius='6px' />
        </Box>
      </Flex>
      <Box
        mt={16}
        sx={{
          display: 'grid',
          gridGap: '24px',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          padding: ['24px', 0],
        }}
      >
        {ITEMS_RANGE.map((key) => (
          <Box key={key}>
            <Skeleton height={208} />
          </Box>
        ))}
      </Box>
    </Box>
  </SkeletonProvider>
);
