import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_QRCODE_ADD_TO_FOLDER,
  ACTION_QRCODE_ARCHIVE,
  ACTION_QRCODE_CHECK,
  ACTION_QRCODE_CHECK_ALL,
  ACTION_QRCODE_CREATE,
  ACTION_QRCODE_FETCH,
  ACTION_QRCODE_FETCH_ALL,
  ACTION_QRCODE_PAUSE,
  ACTION_QRCODE_PUT_LIST,
  ACTION_QRCODE_PUT_SIZES,
  ACTION_QRCODE_REMOVE,
  ACTION_QRCODE_REMOVE_ALL,
  ACTION_QRCODE_RESTORE,
  ACTION_QRCODE_UNPAUSE,
  ACTION_QRCODE_UPDATE,
  ACTION_QRCODE_UPDATE_BY_ID,
} from 'const';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { QrCodeListItem } from 'types';

export type FetchQrCodesActionPayload = {
  page?: number;
  perPage?: number;
  filter_sd_type?: string;
  filter_type?: string | string[];
  filter_sort?: string;
  search_pattern?: string;
  status?: string;
  folderId?: string;
};
export const fetchQrCodes = createAction<FetchQrCodesActionPayload>(
  ACTION_QRCODE_FETCH
);

export const fetchAllQrCodes = createAction<FetchQrCodesActionPayload>(
  ACTION_QRCODE_FETCH_ALL
);

export type ArchiveQrCodeActionPayload = {
  ids: number[];
  removeAfter?: boolean;
};
export const archiveQrCodes = createAction<ArchiveQrCodeActionPayload>(
  ACTION_QRCODE_ARCHIVE
);

export type RestoreQrCodeActionPayload = {
  ids: number[];
  removeAfter?: boolean;
};
export const restoreQrCodes = createAction<RestoreQrCodeActionPayload>(
  ACTION_QRCODE_RESTORE
);

export type PauseQrCodeActionPayload = {
  ids: number[];
};
export const pauseQrCodes = createAction<PauseQrCodeActionPayload>(
  ACTION_QRCODE_PAUSE
);

export type UnpauseQrCodeActionPayload = {
  ids: number[];
  removeAfter?: boolean;
};
export const unpauseQrCodes = createAction<UnpauseQrCodeActionPayload>(
  ACTION_QRCODE_UNPAUSE
);

export type CheckQrCodeActionPayload = {
  ids: number[];
};
export const checkQrCodes = createAction<CheckQrCodeActionPayload>(
  ACTION_QRCODE_CHECK
);

export type CheckAllQrCodeActionPayload = {
  codes: QrCodeListItem[];
  isCheckedAll: boolean;
};
export const checkAllQrCodes = createAction<CheckAllQrCodeActionPayload>(
  ACTION_QRCODE_CHECK_ALL
);

export type AddToFolderActionPayload = {
  folderName: string;
  folderId: number;
  ids: number[];
};
export const addToFolderQrCodes = createAction<AddToFolderActionPayload>(
  ACTION_QRCODE_ADD_TO_FOLDER
);

export type PutSizesActionPayload = { [type: string]: number };
export const putSizesQrCodes = createAction<PutSizesActionPayload>(
  ACTION_QRCODE_PUT_SIZES
);

export type PutQrCodeActionPayload = QrCodeListItem;

export type PutAllQrCodeActionPayload = QrCodeListItem[];
export const putAllQrCodes = createAction<PutAllQrCodeActionPayload>(
  ACTION_QRCODE_PUT_LIST
);

export type RemoveQrCodeActionPayload = {
  id: number;
};
export const removeQrCode = createAction<RemoveQrCodeActionPayload>(
  ACTION_QRCODE_REMOVE
);
export const removeAllQrCodes = createAction(ACTION_QRCODE_REMOVE_ALL);

export type UpdateQrCodeActionPayload = QrCodeListItem;
export const updateQrCodes = createAction<UpdateQrCodeActionPayload>(
  ACTION_QRCODE_UPDATE
);

export type CreateQrCodeActionPayload = {
  [key: string]: any;
};
export const createQrCodeRoutine = createRoutine<CreateQrCodeActionPayload>(
  ACTION_QRCODE_CREATE
);
export const createQrCodeRoutinePromiseCreator = promisifyRoutine(
  createQrCodeRoutine
);

export type UpdateByIdQrCodeActionPayload = {
  [key: string]: any;
};
export const updateByIdQrCodeRoutine = createRoutine<CreateQrCodeActionPayload>(
  ACTION_QRCODE_UPDATE_BY_ID
);
export const updateByIdQrCodeRoutinePromiseCreator = promisifyRoutine(
  updateByIdQrCodeRoutine
);
