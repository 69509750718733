import { ReactNode } from 'react';
import {
  Link as RouterLink,
  LinkProps,
  NavLink as RouterNavLink,
  NavLinkProps,
} from 'react-router-dom';
import { Typography, TypographyProps } from 'ui/typography';

export const Link = ({ children, ...args }: LinkProps & TypographyProps) => (
  <Typography as={RouterLink} {...args}>
    {children}
  </Typography>
);

export const ExternalLink = ({
  children,
  href,
  target,
  download,
  onMouseUp,
  ...args
}: TypographyProps & {
  children: ReactNode;
  download?: boolean | string;
  href: string;
  target?: string;
  onMouseUp?: () => void;
}) => (
  <a
    href={href}
    target={target}
    download={download}
    style={{ textDecoration: 'none' }}
    rel='nofollow noopener noreferrer'
    onMouseUp={onMouseUp}
  >
    <Typography textDecoration='none' {...args}>
      {children}
    </Typography>
  </a>
);

export const NavLink = ({
  children,
  ...args
}: NavLinkProps & TypographyProps) => (
  <Typography as={RouterNavLink} {...args}>
    {children}
  </Typography>
);
