import ajax from './utils/ajax';
import { stringify } from 'modules/utils/qs';
import { mapResponseToFeedback } from './mappers/feedbacks';
import { FeedbackResponse } from './types';
import { Feedback, Category } from 'types';

type GetFeedbacksRequest = {
  qrCodeId: number;
  sort?: string;
  limit: number;
  offset: number;
};

export type GetFeedbacksResponse = {
  feedbacks: Feedback[];
  averageRating: number;
};

export const getFeedbacks = async ({
  qrCodeId,
  sort,
  limit,
  offset,
}: GetFeedbacksRequest): Promise<GetFeedbacksResponse> => {
  const {
    data: { feedbacks, average_rating },
  }: {
    data: { feedbacks: FeedbackResponse[]; average_rating: number };
  } = await ajax.get(
    `/feedbacks${stringify({ qr_code_id: qrCodeId, sort, limit, offset })}`
  );

  return {
    feedbacks: feedbacks.map(mapResponseToFeedback),
    averageRating: average_rating,
  };
};

export const getFeedbackCategories = async (): Promise<Category[]> => {
  const { data: categories }: { data: Category[] } = await ajax.get(
    '/feedbacks/categories'
  );

  return categories;
};

type DeleteFeedbackRequest = {
  qrCodeId: number;
  feedbackIds: number[];
};

export const deleteFeedback = async ({
  qrCodeId,
  feedbackIds,
}: DeleteFeedbackRequest) => {
  await ajax.delete(
    `/feedbacks${stringify({
      qr_code_id: qrCodeId,
      feedback_ids: feedbackIds,
    })}`
  );
};
