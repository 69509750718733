import {
  convertColorStrToRgbaObj,
  convertRgbaObjToRgbaStr,
} from 'modules/utils/convert';
import { memo, useCallback, useState } from 'react';
import { RgbaColor } from 'react-colorful';
import styled from 'styled-components';
import { Card } from 'ui/card';
import { ColorPicker } from 'ui/color-picker';
import { Dropdown } from 'ui/dropdown';
import { InputProps, TextField } from 'ui/input';

const Color = styled.div.attrs<{ color?: string }>((props) => ({
  style: {
    backgroundColor: props.color,
  },
}))`
  position: absolute;
  right: 8px;
  top: 32px;
  width: 32px;
  height: 32px;
  border: 1px solid #e6e9ef;
  box-sizing: border-box;
  border-radius: 4px;
  pointer-events: none;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(TextField)`
  padding: 15px 48px 15px 16px;
  background-color: #fff;
`;

type ToggleInputProps = InputProps & {
  value?: string | undefined;
};

const ToggleInput = ({
  id,
  label,
  value,
  errorText,
  ...rest
}: ToggleInputProps) => (
  <InputWrapper>
    <StyledInput
      label={label}
      id={id}
      autoComplete='false'
      errorText={errorText}
      value={value}
      {...rest}
    />
    <Color color={value} />
  </InputWrapper>
);

type Props = InputProps & {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  presetColors?: RgbaColor[];
};

const ColorInput = ({
  label,
  value,
  presetColors,
  onChange,
  ...props
}: Props) => {
  const [isOpen, onToggle] = useState(false);

  const handleChange = useCallback(
    (v: RgbaColor) => {
      const str = convertRgbaObjToRgbaStr(v);

      onChange(str);
    },
    [onChange]
  );

  return (
    <Dropdown
      block
      zIndex={1000}
      fullWidth
      position={{ top: 'calc(100% + 8px)', left: 0 }}
      toggleSlot={
        <ToggleInput
          label={label}
          value={value}
          onChange={onChange}
          {...props}
        />
      }
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Card>
        <ColorPicker
          value={convertColorStrToRgbaObj(value ? value : '')}
          onChange={handleChange}
          presetColors={presetColors}
        />
      </Card>
    </Dropdown>
  );
};

export default memo(ColorInput);

ColorInput.defaultProps = {
  presetColors: [
    convertColorStrToRgbaObj('#32363D'),
    convertColorStrToRgbaObj('#949AA7'),
    convertColorStrToRgbaObj('#FFFFFF'),
    convertColorStrToRgbaObj('#E64646'),
    convertColorStrToRgbaObj('#62C962'),
    convertColorStrToRgbaObj('#3278E4'),
    convertColorStrToRgbaObj('#DE9F42'),
    convertColorStrToRgbaObj('#FC579C'),
  ],
};
