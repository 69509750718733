import { Flex, Box } from 'rebass/styled-components';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { Link } from 'ui/link';
import { CardInput } from 'ui/input/CardInput';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  required,
  maxLength,
  composeValidators,
} from 'modules/utils/validators';
import { ROUTE_ROOT } from 'const';

const FATextField = fieldAdapter(TextField);

export type CardFormValuesType = {
  fullName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

type CardProps = {
  buttonText?: string;
  onSubmit: (args: CardFormValuesType) => void;
  isSkippable?: boolean;
};

export const CardForm = ({ buttonText, onSubmit, isSkippable }: CardProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid, submitting }) => (
        <Flex flexDirection='column' as='form' onSubmit={handleSubmit}>
          <Box>
            <CardInput label='Credit card information' />
          </Box>
          <Box mt='16px'>
            <Field
              id='fullName'
              name='fullName'
              component={FATextField}
              label='Full Name'
              placeholder='Full Name'
              validate={composeValidators(required, maxLength(200))}
            />
          </Box>
          <Box mt='16px'>
            <Field
              id='address'
              name='address'
              component={FATextField}
              label='Address'
              placeholder='Address'
              validate={composeValidators(required, maxLength(200))}
            />
          </Box>
          <Box
            mt='16px'
            sx={{
              display: 'grid',
              gridGap: '12px',
              gridRow: '16px',
              gridTemplateColumns: '1fr 128px',
            }}
          ></Box>
          <Box mt={32}>
            <Button
              variant='filled'
              type='submit'
              disabled={invalid || submitting || pristine}
            >
              {buttonText ? buttonText : 'Subscribe Now'}
            </Button>
          </Box>
          {isSkippable === false ? (
            <></>
          ) : (
            <Box mt='16px'>
              <Link
                to={ROUTE_ROOT}
                replace
                textDecoration='none'
                color='blue.main'
                variant='footnote'
              >
                Skip this step and start using Supercode ➞
              </Link>
            </Box>
          )}
        </Flex>
      )}
    />
  );
};
