import { BaseSyntheticEvent, ReactNode } from 'react';

export const StopPropagationWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const onClick = (e: BaseSyntheticEvent) => e.stopPropagation();

  return <div onClick={onClick}>{children}</div>;
};

export const stopPropagate = (cb: Function) => (event: BaseSyntheticEvent) => {
  event.stopPropagation();
  cb();
};
