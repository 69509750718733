import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  isPhoneNumber,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);

type Props = {
  internalNotesSlot: ReactNode;
};

export const CreateQrCodeWhatsappSubform = memo(
  ({ internalNotesSlot }: Props) => (
    <Box>
      <Box>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Add Content
        </Typography>
      </Box>
      <Box mt='16px'>
        <Field
          id='phoneWhatsapp'
          name='phone_whatsapp'
          label='Recipient phone number*'
          type='tel'
          placeholder='+12120000000'
          component={FATextField}
          validate={composeValidators(required, isPhoneNumber, maxLength(200))}
        />
      </Box>
      <Box mt='16px'>
        <Field
          as='textarea'
          id='whatsappText'
          name='text_whatsapp'
          label='Text (up to 300 characters)*'
          placeholder='Enter your text here'
          rows='7'
          component={FATextField}
          validate={composeValidators(required, maxLength(300))}
        />
      </Box>
      {internalNotesSlot}
    </Box>
  )
);
