export const MODAL_ADD_CARD = 'addCardModal';
export const MODAL_REMOVE_CARD = 'removeCardModal';

export const MODAL_REVOKE_ACCESS = 'revokeAccessModal';
export const MODAL_INVITE_USER = 'inviteUserModal';

export const MODAL_SAVE_TO_FOLDER = 'saveToFolderModal';
export const MODAL_CREATE_FOLDER = 'createFolderModal';
export const MODAL_REMOVE_FOLDER = 'removeFolderModal';
export const MODAL_RENAME_FOLDER = 'renameFolderModal';

export const MODAL_RESET_SCANS = 'resetScansModal';

export const MODAL_SHOW_FEEDBACK = 'showFeedbackModal';
export const MODAL_REMOVE_FEEDBACK = 'removeFeedbackModal';

export const MODAL_LOADING = 'loadingModal';

export const MODAL_ADD_CATEGORY = 'addCategoryModal';

export const MODAL_IMAGE_THUMB = 'modalImageThumb';
