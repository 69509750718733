import resetIcon from 'assets/images/close-red.svg';
import noIcon from 'assets/images/forbid.svg';
import addIcon from 'assets/images/plus-gray.svg';
import { QR_CODES_LOGOS } from 'const';
import styled from 'styled-components';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';

const Root = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 44px);
`;

const IconButton = styled(Button)`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#E6ECF7;' : '#f7f7f7'};

  span {
    background-position: center;
  }
`;

const IconButtonWrapper = styled.div`
  position: relative;
`;

const ResetButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #e6e9ef;
  box-sizing: border-box;
  border-radius: 50%;
`;

type Props = {
  value: number;
  isFile: boolean;
  file?: File;
  onChange: (v: number | undefined) => void;
  onUpload: () => void;
  onReset: () => void;
  onSetLogoFlag: (v: boolean) => void;
};

export const IconSelect = ({
  value,
  file,
  isFile,
  onChange,
  onUpload,
  onReset,
  onSetLogoFlag,
}: Props) => (
  <Root>
    {isFile && file && (
      <IconButtonWrapper>
        <IconButton>
          <Icon url={URL.createObjectURL(file)} width='44px' height='44px' />
        </IconButton>
        <ResetButton onClick={() => onReset()}>
          <Icon url={resetIcon} width='9px' height='9px' />
        </ResetButton>
      </IconButtonWrapper>
    )}

    {!isFile && (
      <IconButton onClick={onUpload}>
        <Icon url={addIcon} width='14px' height='14px' />
      </IconButton>
    )}

    <IconButton selected={value === -1} onClick={() => onReset()}>
      <Icon url={noIcon} width='20px' height='20px' />
    </IconButton>

    {QR_CODES_LOGOS.map((logo: { image: string; value: number }) => (
      <IconButtonWrapper key={logo.value}>
        <IconButton
          onClick={() => {
            onReset();
            onSetLogoFlag(true);
            onChange(logo.value);
          }}
          selected={logo.value === value}
        >
          <Icon url={logo.image} width='30px' height='30px' />
        </IconButton>
        {value === logo.value && (
          <ResetButton onClick={() => onReset()}>
            <Icon url={resetIcon} width='9px' height='9px' />
          </ResetButton>
        )}
      </IconButtonWrapper>
    ))}
  </Root>
);
