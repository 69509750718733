import { stringify } from 'modules/utils/qs';
import { QrCodeFormValues, QrCodeListItem } from 'types';
import { mapFormValuesToFormData } from './mappers/qrcodes';
import ajax from './utils/ajax';

export const patchQrCodeById = async (payload: QrCodeFormValues) => {
  await ajax.patch(`/qr_codes/${payload.id}`, mapFormValuesToFormData(payload));
};

export type CreateQrCodeRequest = {
  content_type:
    | 'url'
    | 'text'
    | 'vcard'
    | 'email'
    | 'sms'
    | 'whatsapp'
    | 'social_media'
    | 'feedback'
    | 'image'
    | 'pdf';
};

export const createQrCode = async (
  payload: QrCodeFormValues
): Promise<CreateQrCodeRequest> => {
  const { data: list } = await ajax.post(
    '/qr_codes',
    mapFormValuesToFormData(payload)
  );

  return list;
};

type FetchQrCodesRequest = {
  limit?: number;
  offset?: number;
  filter_sd_type?: string;
  filter_type?: string | string[];
  filter_sort?: string;
  search_pattern?: string;
  status?: string;
  folder_id?: string;
};

export const fetchQrCodes = async (
  query: FetchQrCodesRequest
): Promise<{
  qr_codes: QrCodeListItem[];
  sizes_by_type: { [type: string]: number };
  total: number;
}> => {
  const {
    data: { qr_codes, sizes_by_type, total },
  } = await ajax.get(`/qr_codes${stringify(query, { arrayFormat: 'comma' })}`);

  return { qr_codes, sizes_by_type, total };
};

export const getQrCodeById = async (id: string): Promise<QrCodeListItem> => {
  const { data } = await ajax.get(`/qr_codes/${id}`);

  return data;
};

type AddToFolderRequest = {
  ids: number[];
  folderId: number;
};
export const addCodesToFolder = async ({
  ids,
  folderId,
}: AddToFolderRequest) => {
  await ajax.patch('/qr_codes/update_folder', {
    folder_id: folderId,
    qr_code_ids: ids,
  });
};

export const getNewInternalLink = async (): Promise<{
  internal_url: string;
  token: string;
}> => {
  const {
    data: { internal_url, token },
  } = await ajax.get('/internal_links/new');

  return { internal_url, token };
};

type PatchQrCodeStatusRequest = { status: string; ids: number[] };

export const patchQrCodeStatus = async ({
  status,
  ids,
}: PatchQrCodeStatusRequest): Promise<{}> => {
  const { data } = await ajax.patch('/qr_codes/update_status', {
    status: status,
    qr_code_ids: ids,
  });

  return data;
};

type PatchQrCodeActivityRequest = { status: string; ids: number[] };

export const patchQrCodeActivity = async ({
  status,
  ids,
}: PatchQrCodeActivityRequest): Promise<{}> => {
  const { data } = await ajax.patch('/internal_links/update_status', {
    status: status,
    qr_code_ids: ids,
  });

  return data;
};
