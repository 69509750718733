import { Flex, Box } from 'rebass/styled-components';
import { Form, Field } from 'react-final-form';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  required,
  maxLength,
} from 'modules/utils/validators';

const FATextField = fieldAdapter(TextField);

export type RequestResetPasswordFormValuesType = {
  email: string;
};

type RequestResetPasswordFormProps = {
  onSubmit: (args: RequestResetPasswordFormValuesType) => void;
};

export const RequestResetPwdForm = ({
  onSubmit,
}: RequestResetPasswordFormProps) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <Flex flexDirection='column' as='form' onSubmit={handleSubmit}>
        <Box mb='32px'>
          <Field
            id='email'
            type='email'
            name='email'
            component={FATextField}
            label='Email'
            placeholder='name@company.com'
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Button
          variant='filled'
          type='submit'
          disabled={submitting || pristine}>
          Reset Password
        </Button>
      </Flex>
    )}
  />
);
