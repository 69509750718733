import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_FEEDBACKS_FETCH_ALL,
  ACTION_FEEDBACKS_FETCH_CATEGORIES,
  ACTION_FEEDBACKS_PUT,
  ACTION_FEEDBACKS_PUT_RATING,
  ACTION_FEEDBACKS_PUT_CATEGORIES,
  ACTION_FEEDBACKS_REMOVE,
  ACTION_FEEDBACKS_REMOVE_BY_ID,
  ACTION_FEEDBACKS_REMOVE_ALL,
} from 'const';
import { Feedback, Category } from 'types';

export type FetchAllFeedbacksActionPayload = {
  qrCodeId: number;
  page: number;
  perPage: number;
  sort?: string;
};

export const fetchAllFeedbacks = createAction<FetchAllFeedbacksActionPayload>(
  ACTION_FEEDBACKS_FETCH_ALL
);

export const fetchCategories = createAction(ACTION_FEEDBACKS_FETCH_CATEGORIES);

type PutCategoriesActionPayload = Category[];

export const putCategories = createAction<PutCategoriesActionPayload>(
  ACTION_FEEDBACKS_PUT_CATEGORIES
);

type PutFeedbacksActionPayload = Feedback[];

export const putFeedbacks = createAction<PutFeedbacksActionPayload>(
  ACTION_FEEDBACKS_PUT
);

export const putAverageRating = createAction<number>(
  ACTION_FEEDBACKS_PUT_RATING
);

export type RemoveFeedbackActionPayload = {
  qrCodeId: number;
  feedbackIds: number[];
};

export const removeFeedback = createAction<RemoveFeedbackActionPayload>(
  ACTION_FEEDBACKS_REMOVE
);

export const removeFeedbackById = createAction<{ id: number }>(
  ACTION_FEEDBACKS_REMOVE_BY_ID
);

export const removeAllFeedbacks = createAction(ACTION_FEEDBACKS_REMOVE_ALL);
