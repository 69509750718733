import { memo, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { Tabs } from 'ui/tabs';
import { When } from 'ui/when';
import { ModifyDesignCodeSubform } from './ModifyDesignCodeSubform';
import { ModifyDesignFrameSubform } from './ModifyDesignFrameSubform';
import { ModifyDesignIconSubform } from './ModifyDesignIconSubform';

const TABS = [
  { value: 'code', label: 'Code' },
  { value: 'frame', label: 'Frame' },
  { value: 'logo', label: 'Logo' },
];

export const ModifyDesignSubform = memo(() => {
  const [activeTab, setActiveTab] = useState(TABS[0].value);

  return (
    <Box>
      <Box mb={26}>
        <Tabs active={activeTab} onClick={setActiveTab} tabs={TABS} />
      </Box>
      <When condition={activeTab === TABS[0].value}>
        <ModifyDesignCodeSubform />
      </When>
      <When condition={activeTab === TABS[1].value}>
        <ModifyDesignFrameSubform />
      </When>
      <When condition={activeTab === TABS[2].value}>
        <ModifyDesignIconSubform />
      </When>
    </Box>
  );
});
