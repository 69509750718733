import closeButton from 'assets/images/close.svg';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from 'rebass/styled-components';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Dispatch } from 'store';
import {
  PatchFolderActionPayload,
  patchFolderRoutinePromiseCreator,
} from 'store/actions/folders';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { EditFolderForm, EditFolderValueType } from 'ui/forms';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button p='8px' onClick={onClick}>
    <Icon url={closeButton} width={12} height={12} />
  </Button>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  edit: bindPromiseCreators(patchFolderRoutinePromiseCreator, dispatch),
});

const enhance = connect(null, mapDispatchToProps);

type Props = {
  onClose: () => void;
  options: { id: number; value: string };
  edit: (args: PatchFolderActionPayload) => PromiseLike<void>;
};

export const RenameFolderModal = enhance(
  ({ onClose, edit, options }: Props) => {
    const { id, value } = options;
    const [error, setError] = useState<string | undefined>();

    const handleClose = () => {
      onClose();
    };

    const handleSubmit = async (v: EditFolderValueType) => {
      try {
        setError(undefined);
        await edit({ name: v.name, id });
        handleClose();
      } catch (e) {
        setError(e.message || e);
      }
    };

    return (
      <Card>
        <Box p={24} width={[1, 1, 340]} minHeight={[1, 1, 392]} height='100%'>
          <Flex alignItems='center' justifyContent='space-between'>
            <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
              Rename folder
            </Typography>
            <Box>
              <CloseButton onClick={onClose} />
            </Box>
          </Flex>
          <EditFolderForm
            onSubmit={handleSubmit}
            onClose={onClose}
            initialValues={{ name: value }}
            submitText='Save'
          />
          {error && (
            <Box mt={16}>
              <Typography
                variant='footnote'
                color='red.main'
                textAlign='center'
              >
                {error}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }
);
