import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  required,
} from 'modules/utils/validators';
import { Field, Form } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { Button } from 'ui/button';
import { TextField } from 'ui/input';
import { AvatarInput } from 'ui/input/AvatarInput';
import { Typography } from 'ui/typography';

const FATextField = fieldAdapter(TextField);
const FAAvatarField = fieldAdapter(AvatarInput);

const InfoCardWrapper = styled.div`
  padding: 12px;
  background: #f7f7f7;
  border-radius: 10px;
`;

const InfoCard = ({ id, createdAt }: { id: number; createdAt: string }) => (
  <InfoCardWrapper>
    <Flex justifyContent='space-between' minWidth={185} mb='5px'>
      <Typography color='gray.medium' fontSize='10px' lineHeight='15px'>
        Customer ID
      </Typography>
      <Typography color='gray.black' fontSize='10px' lineHeight='15px'>
        {id}
      </Typography>
    </Flex>
    <Flex justifyContent='space-between'>
      <Typography color='gray.medium' fontSize='10px' lineHeight='15px'>
        Signup Date
      </Typography>
      <Typography color='gray.black' fontSize='10px' lineHeight='15px'>
        {createdAt}
      </Typography>
    </Flex>
  </InfoCardWrapper>
);

const SectionTitle = styled.div`
  margin-top: 14px;

  ${mq.sm} {
    grid-column: 1 / 3;
  }
`;

export type ProfileSettingsFormValuesType = {
  avatar?: File | string;
  firstName: string;
  lastName: string;
  companyName: string;
  phone: boolean;
  webSite: boolean;
  street: string;
  city: string;
  zip: string;
  state: string;
  country: string;
};

type Props = {
  onSubmit: (args: ProfileSettingsFormValuesType) => Promise<any>;
  shortName: string;
  id: number;
  createdAt: string;
  initialValues: {};
};

export const ProfileSettingsForm = ({
  onSubmit,
  shortName,
  id,
  createdAt,
  initialValues,
}: Props) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, pristine, invalid, submitting }) => (
      <Flex flexDirection='column' mt='16px' as='form' onSubmit={handleSubmit}>
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'center']}
          justifyContent='space-between'
          mb={24}
        >
          <Box mb={[24, 0]}>
            <Field
              id='avatar'
              name='avatar'
              component={FAAvatarField}
              shortName={shortName}
            />
          </Box>
          <InfoCard id={id} createdAt={createdAt} />
        </Flex>
        <Box
          sx={{
            display: 'grid',
            columnGap: '12px',
            rowGap: '10px',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          }}
        >
          <SectionTitle>
            <Typography
              color='gray.black'
              fontWeight='bold'
              fontSize='14px'
              lineHeight='160%'
            >
              Name &#38; Company
            </Typography>
          </SectionTitle>
          <Field
            id='firstName'
            name='firstName'
            type='firstName'
            component={FATextField}
            label='First name'
            placeholder='First Name'
            validate={composeValidators(required, maxLength(200))}
          />
          <Field
            id='lastName'
            name='lastName'
            type='lastName'
            component={FATextField}
            label='Last name'
            placeholder='Last Name'
            validate={composeValidators(required, maxLength(200))}
          />
          <Field
            id='companyName'
            name='companyName'
            type='companyName'
            component={FATextField}
            label='Company'
            placeholder='Company Name'
            validate={maxLength(200)}
          />
          <SectionTitle>
            <Typography
              color='gray.black'
              fontWeight='bold'
              fontSize='14px'
              lineHeight='160%'
            >
              Contact information
            </Typography>
          </SectionTitle>
          <Field
            id='phone'
            name='phone'
            type='phone'
            component={FATextField}
            label='Phone'
            placeholder='+1 212 000 00 00'
            validate={maxLength(200)}
          />
          <Field
            id='webSite'
            name='webSite'
            type='webSite'
            component={FATextField}
            label='Website'
            placeholder='http://...'
            validate={maxLength(200)}
          />
          <SectionTitle>
            <Typography
              color='gray.black'
              fontWeight='bold'
              fontSize='14px'
              lineHeight='160%'
            >
              Address
            </Typography>
          </SectionTitle>
          <Field
            id='street'
            name='street'
            type='street'
            component={FATextField}
            label='Street'
            placeholder='Street'
            validate={maxLength(200)}
          />
          <Field
            id='zip'
            name='zip'
            type='zip'
            component={FATextField}
            label='Zip'
            placeholder='Zip'
            validate={maxLength(200)}
          />
          <Field
            id='city'
            name='city'
            type='city'
            component={FATextField}
            label='City'
            placeholder='City'
            validate={maxLength(200)}
          />
          <Field
            id='state'
            name='state'
            type='state'
            component={FATextField}
            label='State'
            placeholder='State'
            validate={maxLength(200)}
          />
          <Field
            id='country'
            name='country'
            type='country'
            component={FATextField}
            label='Country'
            placeholder='Country'
            validate={maxLength(200)}
          />
        </Box>
        <Box mt={40} width='150px'>
          <Button
            variant='filled'
            type='submit'
            disabled={invalid || submitting || pristine}
          >
            Save changes
          </Button>
        </Box>
      </Flex>
    )}
  />
);
