import { useState } from 'react';
import { bindPromiseCreators } from 'redux-saga-routines';
import { connect } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { Card } from 'ui/card';
import { Confirm } from 'ui/modal/Confirm';
import { Typography } from 'ui/typography';
import {
  deleteFolderRoutinePromiseCreator,
  DeleteFolderActionPayload,
} from 'store/actions/folders';

type Props = {
  onClose: () => void;
  options: { id: number; name: string };
  remove: (payload: DeleteFolderActionPayload) => PromiseLike<any>;
};

const enhance = connect(null, (dispatch) => ({
  remove: bindPromiseCreators(deleteFolderRoutinePromiseCreator, dispatch),
}));

export const DeleteFolderModal = enhance(
  ({ onClose, options: { id, name }, remove }: Props) => {
    const [error, setError] = useState<string | undefined>();

    const handleRemove = async () => {
      try {
        await remove({ id });
        onClose();
      } catch (e) {
        setError(e?.message || 'Folder is not removed');
      }
    };

    return (
      <Card>
        <Confirm
          heading='Delete Folder'
          subheading={`Are you sure you want to delete the folder ${name}?`}
          onCalcel={onClose}
          onSubmit={handleRemove}
        />
        {error && (
          <Box pb={24}>
            <Typography variant='footnote' color='red.main' textAlign='center'>
              {error}
            </Typography>
          </Box>
        )}
      </Card>
    );
  }
);
