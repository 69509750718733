import { convertRgbaObjToRgbaStr } from 'modules/utils/convert';
import { memo } from 'react';
import { RgbaColor, RgbaColorPicker } from 'react-colorful';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { Button } from 'ui/button';

type Props = {
  value: RgbaColor | undefined;
  onChange: (v: RgbaColor) => void;
  presetColors?: RgbaColor[];
};

const Root = styled.div`
  background-color: ${themeGet('colors.white.main')};
  border-radius: 10px;
`;

const StyledPicker = styled(RgbaColorPicker)`
  width: 100% !important;

  & .react-colorful__saturation {
    margin-bottom: 14px;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    width: 100%;
    height: 125px;
  }

  & .react-colorful__hue,
  .react-colorful__alpha {
    height: 12px;
    border-radius: 10px !important;
    margin: 0 12px 16px;
  }

  & .react-colorful__saturation-pointer {
    width: 15px;
    height: 15px;
    border: none;
  }

  & .react-colorful__saturation-pointer:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;
    background-color: ${themeGet('colors.white.main')};
    border: 1px solid ${themeGet('colors.gray.black')};
    border-radius: 50%;
  }

  & .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    position: relative;
    width: 10px;
    height: 24px;
    border: 1px solid #32363d;
    border-radius: 4px;
  }

  & .react-colorful__hue-pointer:after,
  .react-colorful__alpha-pointer:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 24px;
    background-color: ${themeGet('colors.white.main')};
    border: 1px solid ${themeGet('colors.gray.black')};
    border-radius: 4px;
  }
`;

const PresetButton = styled(Button)<{ background: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 6px;
  border: 1px solid #e6e9ef;
  background: ${({ background }) => background};
`;

const PresetButtonGroup = styled.div`
  display: flex;
  padding: 0 12px 10px;
  justify-content: space-between;

  ${PresetButton}:last-child {
    margin-right: 0;
  }
`;

export const ColorPicker = memo(({ value, onChange, presetColors }: Props) => (
  <Root>
    <StyledPicker color={value} onChange={onChange} />
    {presetColors && (
      <PresetButtonGroup>
        {presetColors.map((presetColor) => (
          <PresetButton
            key={JSON.stringify(presetColor)}
            background={convertRgbaObjToRgbaStr(presetColor)}
            onClick={() => onChange(presetColor)}
          />
        ))}
      </PresetButtonGroup>
    )}
  </Root>
));
