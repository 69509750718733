import styled from 'styled-components';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';
import { QR_CODE_FRAMES, QR_CODES_NO_FRAME } from 'const';

import noIcon from 'assets/images/forbid.svg';

const Root = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 112px);
`;

const FrameButton = styled(Button)`
  width: 112px;
  height: 98px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#E6ECF7;' : '#f7f7f7'};

  border: 1px solid
    ${({ selected }: { selected?: boolean }) =>
      selected ? '#3278e4;' : '#f7f7f7'};
`;

type Props = {
  value: string;
  onChange: (v: string | undefined) => void;
  onSetFrameFlag: (v: boolean) => void;
};

export const FrameInput = ({
  value,
  onChange,
  onSetFrameFlag,
}: Props) => (
  <Root>
    <FrameButton
      onClick={() => {
        onChange(QR_CODES_NO_FRAME);
      }}
      selected={!value}>
      <Icon url={noIcon} width='27px' height='27px' />
      <Typography variant='footnote' color='gray.medium'>
        No frame
      </Typography>
    </FrameButton>
    {QR_CODE_FRAMES.map((frame) => (
      <FrameButton
        selected={frame.value === value}
        key={frame.value}
        onClick={() => {
          onChange(frame.value);
          onSetFrameFlag(true);
        }}>
        <Icon url={frame.image} width='53px' height='74px' />
      </FrameButton>
    ))}
  </Root>
);
