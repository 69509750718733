const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

type IntercomeArgs = {
  email: string;
  createdAt: string;
  userId: number;
  plan: string;
  role: string;
  user_hash: string;
};

class IntercomWidget {
  widget;

  constructor() {
    this.widget = window.Intercom;
  }

  getWidget() {
    try {
      return this.widget;
    } catch (e) {
      console.log('Intercom was not launched');
      return () => {};
    }
  }

  boot() {
    this.getWidget()('boot', {
      app_id: APP_ID,
    });
    this.getWidget()('update');
  }

  update(userArgs: IntercomeArgs) {
    const { email, user_hash, createdAt, userId, plan, role } = userArgs;

    this.getWidget()('update', {
      app_id: APP_ID,
      email,
      created_at: new Date(createdAt).getUTCMilliseconds(),
      user_id: userId.toString(),
      user_hash: user_hash,
    });
  }

  trackEvent(type: string, metadata?: any) {
    this.getWidget()('trackEvent', type, metadata);
  }
}

export const intercomWidget = new IntercomWidget();
