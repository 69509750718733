import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  composeValidators,
  isEmail,
  isNumber,
  isPhoneNumber,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { TextField } from 'ui/input';
import { Typography } from 'ui/typography';

const FullRow = styled(Box)`
  grid-column: 1 / 3;
`;

const FATextField = fieldAdapter(TextField);

type Props = {
  internalNotesSlot: ReactNode;
};

export const CreateQrCodeVCardSubform = memo(({ internalNotesSlot }: Props) => (
  <Box>
    <Box
      sx={{
        display: 'grid',
        columnGap: '12px',
        rowGap: '16px',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <FullRow>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Contact Information
        </Typography>
      </FullRow>
      <Box>
        <Field
          id='vcardName'
          name='vcard_name'
          label='First name*'
          placeholder='Jorge'
          component={FATextField}
          validate={composeValidators(required, maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardSurname'
          name='vcard_surname'
          label='Last name*'
          placeholder='Mack'
          component={FATextField}
          validate={composeValidators(required, maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardOrg'
          name='vcard_org'
          label='Company'
          placeholder='Google'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardRole'
          name='vcard_role'
          label='Position'
          placeholder='Designer'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </Box>
      <FullRow mt='8px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Contacts
        </Typography>
      </FullRow>
      <FullRow>
        <Field
          id='vcardPhone'
          name='vcard_phone'
          label='Phone'
          type='tel'
          placeholder='+12220000000'
          component={FATextField}
          validate={composeValidators(required, isPhoneNumber, maxLength(200))}
        />
      </FullRow>
      <FullRow>
        <Field
          id='vcardEmail'
          name='vcard_email'
          label='Email'
          type='email'
          placeholder='designergoogle@gmail.com'
          component={FATextField}
          validate={composeValidators(isEmail, maxLength(200))}
        />
      </FullRow>
      <FullRow>
        <Field
          id='vcardUrl'
          name='vcard_url'
          label='Website'
          type='url'
          placeholder='https://facebook.com/designer'
          component={FATextField}
          validate={composeValidators(isUrl, maxLength(200))}
          format={toHttps}
          formatOnBlur
        />
      </FullRow>
      <FullRow mt='8px'>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Location
        </Typography>
      </FullRow>
      <Box>
        <Field
          id='vcardCountry'
          name='vcard_country'
          label='Country'
          placeholder='Country'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardState'
          name='vcard_state'
          label='State'
          placeholder='State'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardCity'
          name='vcard_city'
          label='City'
          placeholder='City'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </Box>
      <Box>
        <Field
          id='vcardZip'
          name='vcard_zip'
          label='ZIP'
          placeholder='233232'
          component={FATextField}
          validate={composeValidators(isNumber, maxLength(200))}
        />
      </Box>
      <FullRow>
        <Field
          id='vcardStreet'
          name='vcard_street'
          label='Street'
          placeholder='Name street'
          component={FATextField}
          validate={composeValidators(maxLength(200))}
        />
      </FullRow>
    </Box>
    {internalNotesSlot}
  </Box>
));
