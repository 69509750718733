import { useState, ReactNode } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Error } from 'ui/labels';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { InputUI, Label, Root } from 'ui/input';
import { Item, TypeQrCodeItem, List } from 'ui/list';
import { Typography } from 'ui/typography';
import { Link } from 'ui/link';

import arrowIcon from 'assets/images/down-arrow.svg';
import { CurrentPlan } from "types";
import { ROUTE_UPGRADE_PLAN } from "const";

export type SelectItem = { value: string; title: string | ReactNode };

const ToggleInputWrapper = styled(InputUI)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

type ToggleProps = {
  selected?: SelectItem;
  placeholder: string;
  dynamicIsNotAvailable: boolean;
};

const ToggleButton = ({ selected, placeholder, dynamicIsNotAvailable, ...props }: ToggleProps) => (
  <Button {...props} width='100%'>
    <ToggleInputWrapper as='div'>
      {selected ? (
        <Typography
          variant='subhead'
          color={(selected.value === 'dynamic' && dynamicIsNotAvailable) ? 'gray.medium' : 'gray.black'}
          textAlign='left'
          lineHeight='1.15'
        >
          {selected.title}
        </Typography>
      ) : (
        <Typography
          variant='subhead'
          color='gray.medium'
          textAlign='left'
          lineHeight='1.15'
        >
          {placeholder}
        </Typography>
      )}

      <Icon url={arrowIcon} ml='10px' height='8px' width='14px' />
    </ToggleInputWrapper>
  </Button>
);

type Props = {
  id: string;
  options: SelectItem[];
  currentPlan: CurrentPlan,
  onChange: (v: any) => void;
  value: any;
  placeholder?: string;
  errorText?: string;
  label?: string;
  firstItem?: ReactNode;
  onFirstItemClick?: () => void;
};

export const QrCodeTypeSelect = ({
  id,
  value,
  onChange,
  options,
  placeholder = '',
  errorText,
  label,
  firstItem,
  onFirstItemClick,
  currentPlan,
  ...props
}: Props) => {
  const selected = options.find((i) => i.value === value);
  const [isOpen, onToggle] = useState(false);
  const handleItemClick = (item: SelectItem) => {
    onChange(item.value);
    onToggle(false);
  };
  const maxDynamicCodes = currentPlan.dynamicCodes.maxValue;
  const currentDynamicCodes = currentPlan.dynamicCodes.current;
  const dynamicIsNotAvailable = (currentDynamicCodes === maxDynamicCodes) || (maxDynamicCodes === 0);

  return (
    <Root className={errorText ? 'selectorError' : ''}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Dropdown
        fullWidth
        position={{ top: 'calc(100% + 8px)', left: 0 }}
        toggleSlot={
          <ToggleButton placeholder={placeholder} selected={selected} dynamicIsNotAvailable={dynamicIsNotAvailable} />
        }
        isOpen={isOpen}
        onToggle={onToggle}
        block
        {...props}
      >
        <Card>
          <Box p='8px 0' width={1}>
            <List>
              {firstItem && (
                <Item onClick={() => onFirstItemClick && onFirstItemClick()}>
                  {firstItem}
                </Item>
              )}
              {options.map((item: SelectItem) => (
                <TypeQrCodeItem onClick={() => handleItemClick(item)} key={item.value}>
                  <Typography
                    textAlign='left'
                    variant='subhead'
                    color={(item.value === 'dynamic' && dynamicIsNotAvailable) ? 'gray.medium' : 'gray.black'}
                    fontWeight='normal'
                  >
                    {item.title}
                  </Typography>
                  {(item.value === 'dynamic' && dynamicIsNotAvailable) && (
                    <Typography
                      textAlign='left'
                      variant='subhead'
                      color='gray.medium'
                      fontWeight='normal'
                    >
                      Purchase a subscription to continue
                    </Typography>
                  )}
                </TypeQrCodeItem>
              ))}
              {options.length === 0 && (
                <Item>
                  <Typography
                    textAlign='left'
                    variant='subhead'
                    color='gray.black'
                    fontWeight='normal'
                  >
                    No options
                  </Typography>
                </Item>
              )}
            </List>
          </Box>
        </Card>
      </Dropdown>
      {errorText && (
        <Error>
          <Link
            color='red.text'
            fontSize='10px'
            lineHeight='15px'
            to={ROUTE_UPGRADE_PLAN}>
            {errorText}
          </Link>
        </Error>
      )}
    </Root>
  );
};
