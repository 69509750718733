import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_USER_PUT,
  ACTION_USER_UPDATE_USER,
  ACTION_USER_UPDATE_ACCOUNT,
  ACTION_USER_FETCH_CURRENT_PLAN,
  ACTION_USER_UPDATE_PASSWORD,
  ACTION_USER_REMOVE,
  ACTION_USER_FETCH,
  ACTION_USER_CURRENT_FETCH,
  ACTION_USER_CREATE_CARD,
  ACTION_USER_FETCH_CARDS,
  ACTION_USER_REMOVE_CARD,
  ACTION_USER_FETCH_INVITES,
  ACTION_USER_CREATE_INVITE,
  ACTION_USER_REVOKE_INVITE,
  ACTION_USER_ACCEPT_INVITATION,
  ACTION_USER_DELETE_AVATAR,
} from 'const';
import { CurrentUser, CurrentAccount, CurrentPlan, Card, Invite } from 'types';

type PutUserActionPayload = {
  account?: CurrentAccount;
  currentUser?: CurrentUser;
  currentPlan?: CurrentPlan;
  cards?: Card[];
  invites?: Invite[];
};

export const putUser = createAction<PutUserActionPayload>(ACTION_USER_PUT);

export const fetchFullCurrent = createAction(ACTION_USER_CURRENT_FETCH);

export const fetchCurrentUser = createAction(ACTION_USER_FETCH);

export type UpdateCurrentUserActionPayload = {
  avatar: File;
  email: string;
  timeZone: string;
};

export const updateCurrentUserRoutine = createRoutine<
  UpdateCurrentUserActionPayload
>(ACTION_USER_UPDATE_USER);
export const updateCurrentUserRoutinePromiseCreator = promisifyRoutine(
  updateCurrentUserRoutine
);
export const deleteAvatarRoutine = createRoutine(ACTION_USER_DELETE_AVATAR)
export const deleteAvatarPromiseCreator = promisifyRoutine(
  deleteAvatarRoutine
);

export type UpdateCurrentAccountActionPayload = CurrentAccount;
export const updateCurrentAccountRoutine = createRoutine<
  UpdateCurrentAccountActionPayload
>(ACTION_USER_UPDATE_ACCOUNT);
export const updateCurrentAccountRoutinePromiseCreator = promisifyRoutine(
  updateCurrentAccountRoutine
);

export type UpdatePasswordActionPayload = { current: string; password: string };
export const updatePasswordRoutine = createRoutine<UpdatePasswordActionPayload>(
  ACTION_USER_UPDATE_PASSWORD
);
export const updatePasswordRoutinePromiseCreator = promisifyRoutine(
  updatePasswordRoutine
);

export type CreateCardActionPayload = { tokenId: string };
export const createCardRoutine = createRoutine<CreateCardActionPayload>(
  ACTION_USER_CREATE_CARD
);
export const createCardRoutinePromiseCreator = promisifyRoutine(
  createCardRoutine
);

export const fetchCards = createAction(ACTION_USER_FETCH_CARDS);

export type RemoveCardActionPayload = { id: number };
export const removeCardRoutine = createRoutine<RemoveCardActionPayload>(
  ACTION_USER_REMOVE_CARD
);
export const removeCardRoutinePromiseCreator = promisifyRoutine(
  removeCardRoutine
);

export const removeUser = createAction(ACTION_USER_REMOVE);

export const fetchCurrentPlan = createAction(ACTION_USER_FETCH_CURRENT_PLAN);

export const fetchInvites = createAction(ACTION_USER_FETCH_INVITES);

export type RevokeInviteActionPayload = { id: number };
export const revokeInviteRoutine = createRoutine<RevokeInviteActionPayload>(
  ACTION_USER_REVOKE_INVITE
);
export const revokeInviteRoutinePromiseCreator = promisifyRoutine(
  revokeInviteRoutine
);

export type CreateInviteActionPayload = { email: string };
export const createInviteRoutine = createRoutine<CreateInviteActionPayload>(
  ACTION_USER_CREATE_INVITE
);
export const createInviteRoutinePromiseCreator = promisifyRoutine(
  createInviteRoutine
);

export type AcceptInviteActionPayload = { token: string; password: string };
export const acceptInviteRoutine = createRoutine<AcceptInviteActionPayload>(
  ACTION_USER_ACCEPT_INVITATION
);
export const acceptInviteRoutinePromiseCreator = promisifyRoutine(
  revokeInviteRoutine
);
