import { Page404 } from 'pages/404';
import { PagePaused } from 'pages/paused';
import { PropsWithChildren } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppRoutes, appRoutes } from './AppRoutes';
import { ScrollTopRoutes } from './ScrollTopRoutes';

export const MainRouter = ({ children }: PropsWithChildren<{}>) => (
  <Router>
    <ScrollTopRoutes />
    {children}
    <Switch>
      <Route exact path={appRoutes} component={AppRoutes} />
      <Route exact path={'/feedback'} component={() => null} />
      <Route exact path={'/paused'} component={PagePaused} />
      <Route path='*' component={Page404} />
    </Switch>
  </Router>
);
