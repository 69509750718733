import { Flex, Box } from 'rebass/styled-components';
import { SkeletonProvider, Skeleton } from 'ui/skeleton';

export const QrCodeAnalyticsSkeleton = () => (
  <SkeletonProvider>
    <Box maxWidth={1270} mx='auto' py={24} mt={96} px='8px'>
      <Box width={1} maxWidth={157} mb={16}>
        <Skeleton height={41} />
      </Box>
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex>
          <Box width={1} maxWidth={180}>
            <Skeleton height={24} />
          </Box>
          <Box width={1} maxWidth={212}>
            <Skeleton height={24} />
          </Box>
        </Flex>
        <Box width={1} maxWidth={84}>
          <Skeleton height={20} />
        </Box>
      </Flex>
      <Box
        mt='16px'
        sx={{
          display: 'grid',
          gridGap: '24px',
          gridTemplateColumns: ['1fr', '1fr', '300px 1fr 1fr '],
        }}>
        <Box maxWidth='100%' sx={{ gridRow: ['1/2', '1/2', '1/3'] }}>
          <Skeleton height={658} />
        </Box>
        <Box>
          <Skeleton height={340} />
        </Box>
        <Box>
          <Skeleton height={340} />
        </Box>
        <Box sx={{ gridColumn: ['1/2', '1/2', '2/4'] }}>
          <Skeleton height={288} />
        </Box>
      </Box>
    </Box>
  </SkeletonProvider>
);
