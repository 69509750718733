import { Flex, Box } from 'rebass/styled-components';
import { SkeletonProvider, Skeleton } from 'ui/skeleton';

export const FeedbackSkeleton = () => (
  <SkeletonProvider>
    <Box maxWidth={1270} mx='auto' py={24} mt={96} px='8px'>
      <Flex mb='12px' alignItems='center' justifyContent='space-between'>
        <Box width={1} maxWidth={561}>
          <Skeleton height={41} />
        </Box>
        <Box width={1} maxWidth={179}>
          <Skeleton height={28} />
        </Box>
      </Flex>
      <Box mb='16px' width={1} maxWidth={126}>
        <Skeleton height={24} />
      </Box>
      <Box
        mt='20px'
        sx={{
          display: 'grid',
          gridGap: '24px',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr '],
        }}
      >
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
        <Box>
          <Skeleton height={193} />
        </Box>
      </Box>
    </Box>
  </SkeletonProvider>
);
