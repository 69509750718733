import { createElement, useCallback, JSXElementConstructor } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from './components/_ModalWrapper';
import { AddCardModal } from './components/AddCardModal';
import { DeleteCardModal } from './components/DeleteCardModal';
import { RevokeAccessModal } from './components/RevokeAccessModal';
import { InviteUserModal } from './components/InviteUserModal';
import { SaveToFolderModal } from './components/SaveToFolderModal';
import { CreateModalModal } from './components/CreateFolderModal';
import { ResetScansModal } from './components/ResetScansModal';
import { FeedbackModal } from './components/FeedbackModal';
import { RemoveFeedbackModal } from './components/RemoveFeedbackModal';
import { LoadingModal } from './components/LoadingModal';
import { DeleteFolderModal } from './components/DeleteFolderModal';
import { RenameFolderModal } from './components/RenameFolderModal';
import { AddCategoryModal } from './components/AddCategoryModal';
import { ImageModal } from './components/ImageModal';
import { selectOpenedModal } from 'store/selectors/app';
import {
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'store/actions/app';
import { RootState, Dispatch } from 'store';

import {
  MODAL_ADD_CARD,
  MODAL_REMOVE_CARD,
  MODAL_REVOKE_ACCESS,
  MODAL_INVITE_USER,
  MODAL_SAVE_TO_FOLDER,
  MODAL_CREATE_FOLDER,
  MODAL_RENAME_FOLDER,
  MODAL_REMOVE_FOLDER,
  MODAL_RESET_SCANS,
  MODAL_SHOW_FEEDBACK,
  MODAL_REMOVE_FEEDBACK,
  MODAL_LOADING,
  MODAL_ADD_CATEGORY,
  MODAL_IMAGE_THUMB,
} from 'const';

type ModalProps = {
  options: any;
  onClose: () => void;
};

const MODALS: {
  [key: string]: JSXElementConstructor<ModalProps>;
} = {
  [MODAL_ADD_CARD]: AddCardModal,
  [MODAL_REMOVE_CARD]: DeleteCardModal,
  [MODAL_REVOKE_ACCESS]: RevokeAccessModal,
  [MODAL_INVITE_USER]: InviteUserModal,
  [MODAL_SAVE_TO_FOLDER]: SaveToFolderModal,
  [MODAL_CREATE_FOLDER]: CreateModalModal,
  [MODAL_RENAME_FOLDER]: RenameFolderModal,
  [MODAL_REMOVE_FOLDER]: DeleteFolderModal,
  [MODAL_RESET_SCANS]: ResetScansModal,
  [MODAL_SHOW_FEEDBACK]: FeedbackModal,
  [MODAL_REMOVE_FEEDBACK]: RemoveFeedbackModal,
  [MODAL_LOADING]: LoadingModal,
  [MODAL_ADD_CATEGORY]: AddCategoryModal,
  [MODAL_IMAGE_THUMB]: ImageModal,
};

export const useModal = () => {
  const dispatch: Dispatch = useDispatch();

  const showModal = (type: string, options?: any) => {
    dispatch(showModalAction({ type, options }));
  };

  const closeModal = (type: string) => {
    dispatch(closeModalAction({ type }));
  };

  return { showModal, closeModal };
};

export const Modal = () => {
  const dispatch: Dispatch = useDispatch();
  const modal = useSelector<
    RootState,
    undefined | { type: string; options?: any }
  >(selectOpenedModal);

  const { preventClose } = modal?.options || {};

  const onClose = useCallback(() => {
    if (modal && !preventClose) {
      dispatch(closeModalAction({ type: modal.type }));
    }
  }, [dispatch, modal, preventClose]);

  if (!modal) {
    return null;
  }

  const Component = MODALS[modal.type];

  return (
    <ModalWrapper id={modal.type} isOpen={true} onRequestClose={onClose}>
      {createElement(Component, { options: modal.options || {}, onClose })}
    </ModalWrapper>
  );
};
