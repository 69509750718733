import vcardIcon from 'assets/images/account.svg';
import emailIcon from 'assets/images/type-email.svg';
import feedbackIcon from 'assets/images/type-feedback.svg';
import imageIcon from 'assets/images/type-image.svg';
import pdfIcon from 'assets/images/type-pdf.svg';
import smsIcon from 'assets/images/type-sms.svg';
import socialMediaIcon from 'assets/images/type-social-media.svg';
import textIcon from 'assets/images/type-text.svg';
import urlIcon from 'assets/images/type-url.svg';
import whatsappIcon from 'assets/images/type-whatsapp.svg';
import { ROUTE_UPGRADE_PLAN } from 'const';
import { FieldRenderProps } from 'react-final-form';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { mq } from 'theme/breakpoints';
import { Button } from 'ui/button';
import { Icon } from 'ui/icon';
import { Tooltip } from 'ui/tooltip';

type Item = { value: string; title: string; allowed: boolean };

const ICONS: { [key: string]: any } = {
  url: urlIcon,
  text: textIcon,
  email: emailIcon,
  sms: smsIcon,
  whatsapp: whatsappIcon,
  vcard: vcardIcon,
  social_media: socialMediaIcon,
  feedback: feedbackIcon,
  bulk_url: urlIcon,
  image: imageIcon,
  pdf: pdfIcon,
};

const Root = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 54px;

  ${mq.xs} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${mq.md} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 65px;
  }
`;

const OptionButton = styled(Button).attrs({
  variant: 'filled',
  bg: 'gray.light',
  color: 'gray.black',
})`
  height: 100%;

  &.selected {
    color: ${themeGet('colors.blue.main')};
    border: 1px solid ${themeGet('colors.blue.main')};
  }
`;

type Props = {
  value: string | undefined;
  onChange: (v: string) => void;
  options: Item[];
};

const SelectQrCodeTypeInput = ({ value, onChange, options }: Props) => {
  const history = useHistory();
  const handleClick = (v: string, allowed: boolean) => {
    if (allowed) {
      onChange(v);
    } else {
      history.push(ROUTE_UPGRADE_PLAN);
    }
  };

  return (
    <Root>
      <Tooltip place='top' effect='solid' />
      {options.map((type: Item) => (
        <div
          key={type.value}
          data-tip={
            type.allowed
              ? undefined
              : 'Please upgrade to create this type of QR codes'
          }
        >
          <OptionButton
            bg='blue.main'
            className={`${type.value === value ? 'selected' : ''} ${
              type.allowed ? '' : 'disabled'
            }`}
            onClick={() => handleClick(type.value, type.allowed)}
          >
            <Icon url={ICONS[type.value]} mr='10px' /> {type.title}
          </OptionButton>
        </div>
      ))}
    </Root>
  );
};

export const SelectQrCodeType = ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, any> & { options: Item[] }) => (
  <SelectQrCodeTypeInput {...input} {...rest} />
);
