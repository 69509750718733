import compose from 'lodash/fp/compose';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import { convertFileToBase64 } from 'modules/utils/convert';
import {
  CurrentUserResponse,
  CurrentAccountResponse,
  CurrentPlanResponse,
  PatchUserRequest,
  PatchAccountRequest,
  PaymentResponse,
} from '../types';
import { CurrentUser, CurrentAccount, CurrentPlan } from 'types';

export const mapResponseToUser = (res: CurrentUserResponse): CurrentUser =>
  compose(
    omit([
      'created_at',
      'updated_at',
      'account_id',
      'time_zone',
      'stripe_customer_id',
      'invited_by_id',
      'user_hash',
    ]),
    (res: CurrentUserResponse) => ({
      ...res,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
      accountId: res.account_id,
      timeZone: res.time_zone,
      stripeCustomerId: res.stripe_customer_id,
      invitedById: res.invited_by_id,
      userHash: res.user_hash,
      payments: res.payments.map((payment: PaymentResponse) => ({
        id: payment.id,
        transactionId: payment.transaction_id,
        amountInCents: payment.amount_in_cents,
        date: payment.date,
        cardType: payment.card_type,
        last4: payment.last_4,
        file: payment.file,
        status: payment.status,
        reason: payment.reason,
        paidPeriod: payment.paid_period,
      })),
    })
  )(res);

type MapUserToRequest =
  | CurrentUser
  | { email: string; timeZone: string; avatar?: File };

export const mapUserToRequest = async (
  entity: MapUserToRequest
): Promise<PatchUserRequest> => {
  const avatarObj: { avatar?: string; avatar_definition?: string } = {};

  if (entity.avatar instanceof File) {
    avatarObj.avatar = await convertFileToBase64(entity.avatar);

    const type = entity.avatar.type;

    avatarObj.avatar_definition = type.substr(type.indexOf('/') + 1);
  }

  return compose(
    omitBy(isNil),
    omit([
      'createdAt',
      'updatedAt',
      'accountId',
      'timeZone',
      'stripeCustomerId',
    ]),
    (res) => ({
      ...res,
      ...avatarObj,
      created_at: res.createdAt,
      updated_at: res.updatedAt,
      account_id: res.accountId,
      time_zone: res.timeZone,
      stripe_customer_id: res.stripeCustomerId,
    })
  )(entity);
};

export const mapResponseToAccount = (
  res: CurrentAccountResponse
): CurrentAccount =>
  compose(
    omit([
      'access_type',
      'first_name',
      'last_name',
      'company_name',
      'web_site',
      'short_url',
      'expire_date',
      'created_at',
      'updated_at',
      'static_count',
      'dynamic_count',
      'next_access_type',
      'expire_job_id',
      'payed_next_plan',
    ]),
    (res: CurrentAccountResponse) => ({
      ...res,
      accessType: res.access_type,
      firstName: res.first_name,
      lastName: res.last_name,
      companyName: res.company_name,
      webSite: res.web_site,
      shortUrl: res.short_url,
      expireDate: res.expire_date,
      createdAt: res.created_at,
      updatedAt: res.updated_at,
      staticCount: res.static_count,
      dynamicCount: res.dynamic_count,
      nextAccessType: res.next_access_type,
      expireJobId: res.expire_job_id,
      payedNextPlan: res.payed_next_plan,
    })
  )(res);

export const mapAccountToRequest = (res: CurrentAccount): PatchAccountRequest =>
  compose(
    omitBy(isNil),
    omit([
      'accessType',
      'firstName',
      'lastName',
      'companyName',
      'webSite',
      'shortUrl',
      'expireDate',
      'createdAt',
      'updatedAt',
      'staticCount',
      'dynamicCount',
      'nextAccessType',
      'expireJobId',
      'payedNextPlan',
    ]),
    (res: CurrentAccount) => ({
      ...res,
      access_type: res.accessType,
      first_name: res.firstName,
      last_name: res.lastName,
      company_name: res.companyName,
      web_site: res.webSite,
      short_url: res.shortUrl,
      expire_date: res.expireDate,
      created_at: res.createdAt,
      updated_at: res.updatedAt,
      static_count: res.staticCount,
      dynamic_count: res.dynamicCount,
      next_access_type: res.nextAccessType,
      expire_job_id: res.expireJobId,
      payed_next_plan: res.payedNextPlan,
    })
  )(res);

export const mapResponseToCurrentPlan = (
  res: CurrentPlanResponse
): CurrentPlan => ({
  planId: res.plan_id,
  planCode: res.plan_code,
  planName: res.plan_name,
  prevPlanName: res.prev_plan_name,
  expiration: res.expiration,
  renewalDate: res.renewal_date,
  pastDueAt: res.past_due_at,
  billingPeriod: res.billing_period,
  pausedDynamicQrCodes: res.paused_dynamic_qr_codes,
  dynamicCodes: {
    current: res.dynamic_codes.current,
    maxValue: res.dynamic_codes.max_value,
  },
  staticCodes: {
    current: res.static_codes.current,
    maxValue: res.static_codes.max_value,
  },
  users: {
    current: res.users.current,
    maxValue: res.users.max_value,
  },
  scans: {
    current: res.scans.current,
    maxValue: res.scans.max_value,
  },
  permissions: {
    staticQrCodesLimit: res.permissions.static_qr_codes_limit,
    dynamicQrCodesLimit: res.permissions.dynamic_qr_codes_limit,
    download: {
      types: res.permissions.download.types,
      highQuality: res.permissions.download.high_quality,
    },
    types: res.permissions.types,
    foldersAccess: res.permissions.folders_access,
    analytics: res.permissions.analytics,
    users: res.permissions.users,
  },
  price: res.price,
  nextPlanName: res.next_plan_name,
});
