import styled from 'styled-components';
import { UsernameIcon } from 'ui/icon';
import { Typography } from 'ui/typography';

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Avatar = styled.div``;

const InfoBox = styled.div`
  margin-left: 8px;
`;

const FullName = styled.div`
  margin-bottom: 2px;
`;

const Email = styled.div``;

export type Props = {
  shortName: string;
  fullName: string;
  email: string;
  avatar?: string;
};

export const AvatarInfo = ({ shortName, fullName, email, avatar }: Props) => (
  <Root>
    <Avatar>
      <UsernameIcon avatar={avatar} bg='blue.mediumLight'>
        {shortName}
      </UsernameIcon>
    </Avatar>
    <InfoBox>
      <FullName>
        <Typography variant='footnote' color='gray.black'>
          {fullName}
        </Typography>
      </FullName>
      <Email>
        <Typography
          variant='footnote'
          fontSize={0}
          lineHeight={1.5}
          color='gray.medium'
        >
          {email}
        </Typography>
      </Email>
    </InfoBox>
  </Root>
);
