import billingIcon from 'assets/images/finance.svg';
import logoutIcon from 'assets/images/logout.svg';
import profileIcon from 'assets/images/profile.svg';
import settingsIcon from 'assets/images/settings.svg';
import {
  ROUTE_LOGOUT,
  ROUTE_PROFILE,
  ROUTE_PROFILE_BILLING,
  ROUTE_PROFILE_SETTINGS,
} from 'const';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { AvatarInfo } from 'ui/avatar-info';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Dropdown } from 'ui/dropdown';
import { Icon, UsernameIcon } from 'ui/icon';
import { Link } from 'ui/link';
import { Item, List } from 'ui/list';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray.light2};
`;

type HeaderDropdownProps = {
  shortName: string;
  fullName: string;
  email: string;
  avatar?: string;
  isOpen: boolean;
  confirmed?: boolean;
  onToggle: (v: boolean) => void;
};

export const HeaderDropdown = ({
  shortName = 'AK',
  fullName,
  email,
  avatar,
  isOpen,
  onToggle,
  confirmed,
}: HeaderDropdownProps) => {
  const onClick = () => onToggle(false);

  return (
    <Dropdown
      position={{ top: ['calc(100% + 5px)', 'calc(100% + 12px)'], right: 0 }}
      toggleSlot={
        <Button>
          <UsernameIcon avatar={avatar}>{shortName}</UsernameIcon>
        </Button>
      }
      zIndex={1052}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Card>
        <Flex minWidth={203} flexDirection='column'>
          <Box p='12px 12px 10px'>
            <AvatarInfo
              shortName={shortName}
              fullName={fullName}
              avatar={avatar}
              email={email}
            />
          </Box>
          <Divider />
          <List>
            <Box p='8px 0'>
              <Item
                as={Link}
                to={ROUTE_PROFILE}
                variant='footnote'
                color='gray.black'
                fontWeight='normal'
                textDecoration='none'
                onMouseUp={onClick}
              >
                <Icon url={profileIcon} mr='8px' />
                Profile
              </Item>
              {confirmed && (
                <>
                  <Item
                    as={Link}
                    to={ROUTE_PROFILE_BILLING}
                    variant='footnote'
                    color='gray.black'
                    fontWeight='normal'
                    textDecoration='none'
                    onMouseUp={onClick}
                  >
                    <Icon url={billingIcon} mr='8px' />
                    Billing
                  </Item>
                  <Item
                    as={Link}
                    to={ROUTE_PROFILE_SETTINGS}
                    variant='footnote'
                    color='gray.black'
                    fontWeight='normal'
                    textDecoration='none'
                    onMouseUp={onClick}
                  >
                    <Icon url={settingsIcon} mr='8px' />
                    Settings
                  </Item>
                </>
              )}
            </Box>
            <Divider />
            <Box p='8px 0'>
              <Item
                as={Link}
                to={ROUTE_LOGOUT}
                variant='footnote'
                color='red.text'
                fontWeight='normal'
                textDecoration='none'
                onMouseUp={onClick}
              >
                <Icon url={logoutIcon} mr='8px' />
                Log Out
              </Item>
            </Box>
          </List>
        </Flex>
      </Card>
    </Dropdown>
  );
};
