import plusIcon from 'assets/images/plus-blue.svg';
import { MODAL_ADD_CATEGORY } from 'const';
import { useModal } from 'modules/modal';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { toHttps } from 'modules/utils/formatFormField';
import {
  arrayNotEmptyItemsRequired,
  arrayRequired,
  composeValidators,
  isUrl,
  maxLength,
  required,
} from 'modules/utils/validators';
import { BaseSyntheticEvent, memo, ReactNode, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'rebass/styled-components';
import { fetchCategories } from 'store/actions/feedbacks';
import { selectFCategories } from 'store/selectors/feedbacks';
import { Button } from 'ui/button';
import { SelectCategory } from 'ui/categories/SelectCategory';
import { FieldArray } from 'ui/field-array';
import { Icon } from 'ui/icon';
import { TextField } from 'ui/input';
import ColorInput from 'ui/input/ColorInput';
import { Typography } from 'ui/typography';

const FAColorInput = fieldAdapter(ColorInput);
const FATextField = fieldAdapter(TextField);

const FACategoryFields = (props: any) => (
  <Box mt='16px'>
    <SelectCategory {...props} />
  </Box>
);

type Props = {
  internalNotesSlot: ReactNode;
};

export const CreateQrCodeFeedbackSubform = memo(
  ({ internalNotesSlot }: Props) => {
    const dispatch = useDispatch();
    const categories = useSelector(selectFCategories);
    const { fields } = useFieldArray('categories');
    const { showModal } = useModal();

    useEffect(() => {
      dispatch(fetchCategories());
    }, []);

    const onAddNewCategory = (e: BaseSyntheticEvent, args = {}) => {
      fields.push(args);
    };

    const onRemoveCategory = (index: number) => {
      fields.remove(index);
    };

    const onChangeCategory = (index: number, value: any) => {
      fields.update(index, value);
    };

    const onMoveUp = (index: number) => {
      fields.move(index, index + 1);
    };

    const onMoveDown = (index: number) => {
      fields.move(index, index - 1);
    };

    const onCreateCategory = (index: number) => {
      showModal(MODAL_ADD_CATEGORY, {
        type: 'category',
        onSubmit: (args: any) => onChangeCategory(index, args),
      });
    };

    const onAddSubCategory = (index: number) => {
      showModal(MODAL_ADD_CATEGORY, {
        type: 'subcategory',
        onSubmit: (args: { label: string }) => {
          const field = fields.value[index];
          fields.update(index, {
            ...field,
            subcategories: [...(field.subcategories || []), args],
          });
        },
      });
    };

    const onRemoveSubCategory = (index: number, subIndex: number) => {
      const field = fields.value[index] as {
        subcategories: Array<{ categoryName: string }>;
      };
      const subcategories = field.subcategories.filter(
        (_, idx) => idx !== subIndex
      );
      fields.update(index, {
        ...field,
        subcategories,
      });
    };

    return (
      <Box>
        <Box>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Color palette
          </Typography>
        </Box>
        <Box
          mt='16px'
          sx={{
            display: 'grid',
            columnGap: '12px',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <Box>
            <Field
              id='background'
              name='background_feedback_color'
              label='Background'
              component={FAColorInput}
            />
          </Box>
        </Box>
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Basic Information
          </Typography>
        </Box>
        <Box mt='16px'>
          <Field
            id='headline'
            name='name_feedback'
            label='Headline*'
            placeholder='Enter name'
            component={FATextField}
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mt='16px'>
          <Field
            id='description'
            name='title_feedback'
            label='Description*'
            placeholder='Select'
            component={FATextField}
            validate={composeValidators(required, maxLength(500))}
          />
        </Box>
        {internalNotesSlot}
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Category
          </Typography>
        </Box>
        <Box mt='16px'>
          <FieldArray
            name='categories'
            renderer={FACategoryFields}
            validate={composeValidators(
              arrayRequired,
              arrayNotEmptyItemsRequired
            )}
            itemProps={{
              categories,
              onChange: onChangeCategory,
              onCreate: onCreateCategory,
              onDelete: onRemoveCategory,
              onMoveUp,
              onMoveDown,
              onAddSubCategory: onAddSubCategory,
              onRemoveSubCategory: onRemoveSubCategory,
            }}
          />
        </Box>
        <Box mt='16px'>
          <Button
            variant='filled'
            backgroundColor='blue.mediumLight'
            color='blue.main'
            onClick={onAddNewCategory}
          >
            <Icon url={plusIcon} width='14px' height='14px' mr='13px' />
            New
          </Button>
        </Box>
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Contact Information
          </Typography>
        </Box>
        <Box mt='16px'>
          <Field
            id='website'
            name='website_feedback'
            label='Website*'
            placeholder='name.com'
            component={FATextField}
            validate={composeValidators(required, isUrl, maxLength(200))}
            format={toHttps}
            formatOnBlur
          />
        </Box>
      </Box>
    );
  }
);
