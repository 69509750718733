import {
  ROUTE_ANALYTICS,
  ROUTE_ARCHIVE,
  ROUTE_BILLING,
  ROUTE_CREATE_QR_CODE,
  ROUTE_DETAILED_PLAN,
  ROUTE_EDIT_QR_CODE,
  ROUTE_FOLDERS,
  ROUTE_FOLDER_QR_CODES,
  ROUTE_MODIFY_QR_CODE,
  ROUTE_QR_CODE_ANALYTICS,
  ROUTE_QR_CODE_FEEDBACKS,
  ROUTE_ROOT,
  ROUTE_UPGRADE_PLAN,
} from 'const';
import Header from 'modules/header';
import { CommonAnalyticsSkeleton } from 'pages/analytics/common/CommonAnalyticsSkeletonPage';
import { QrCodeAnalyticsSkeleton } from 'pages/analytics/qr-code/QrCodeAnalyticsSkeleton';
import { FeedbackSkeleton } from 'pages/feedback/page/FeedbackSkeleton';
import { ListSkeleton } from 'pages/list/ListSkeleton';
import { CreateQrCodePageSkeleton } from 'pages/qr-code/CreateQrCodeSkeleton';
import { DetailsSkeleton } from 'pages/tariff-plans/skeleton/DetailsSkeleton';
import { TariffSkeleton } from 'pages/tariff-plans/skeleton/TariffSkeleton';
import { lazy, ReactChild, ReactNode, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { MainLayout } from 'ui/layout';
import { WrapperSpinner } from 'ui/spinner';
import { authRoutes } from './AuthRoutes';
import { PrivateRoute } from './PrivateRoute';
import { profileRoutes } from './ProfileRoutes';

export const appRoutes = [
  ...authRoutes,
  ...profileRoutes,
  ROUTE_ROOT,
  ROUTE_BILLING,
  ROUTE_DETAILED_PLAN,
  ROUTE_UPGRADE_PLAN,
  ROUTE_FOLDERS,
  ROUTE_FOLDER_QR_CODES,
  ROUTE_ARCHIVE,
  ROUTE_CREATE_QR_CODE,
  ROUTE_ANALYTICS,
  ROUTE_EDIT_QR_CODE,
  ROUTE_MODIFY_QR_CODE,
  ROUTE_QR_CODE_ANALYTICS,
  ROUTE_QR_CODE_FEEDBACKS,
];

const AuthRoutes = lazy(() => import('./AuthRoutes'));
const ProfileRoutes = lazy(() => import('./ProfileRoutes'));
const CreateQrCodePage = lazy(() =>
  import(/* webpackChunkName: "CreateQrCodePage" */ 'pages/qr-code/create')
);
const BillingPage = lazy(() => import('pages/account/billing'));
const FolderListPage = lazy(() => import('pages/folder-list'));
const FolderQrCodesListPage = lazy(() => import('pages/folder'));
const EditQrCodePage = lazy(() => import('pages/qr-code/edit/EditCodePage'));
const ModifyQrCodePage = lazy(() =>
  import('pages/qr-code/modify/ModifyCodePage')
);
const ListPlans = lazy(() => import('pages/tariff-plans/list'));
const DetailedPlan = lazy(() => import('pages/tariff-plans/details'));
const ArchiveListPage = lazy(() => import('pages/archive'));
const CommonAnalyticsPage = lazy(() => import('pages/analytics/common'));
const QrCodeAnalyticsPage = lazy(() => import('pages/analytics/qr-code'));
const FeedbackPage = lazy(() => import('pages/feedback/page'));
const QrCodeList = lazy(() => import('pages/qr-code-list'));

export const withSuspense = (page: ReactNode, skeleton: ReactChild) => (
  <Suspense fallback={skeleton}>{page}</Suspense>
);

export const CommonSkeleton = () => (
  <MainLayout>
    <WrapperSpinner />
  </MainLayout>
);

export const AppRoutes = () => {
  return (
    <Box height='100%'>
      <Header />
      <Switch>
        <Route
          exact
          path={authRoutes}
          render={() => withSuspense(<AuthRoutes />, <CommonSkeleton />)}
        />
        <Route
          exact
          path={profileRoutes}
          render={() => withSuspense(<ProfileRoutes />, <CommonSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_ROOT}
          render={() => withSuspense(<QrCodeList />, <ListSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_CREATE_QR_CODE}
          render={() =>
            withSuspense(<CreateQrCodePage />, <CreateQrCodePageSkeleton />)
          }
        />
        <PrivateRoute
          exact
          path={ROUTE_EDIT_QR_CODE}
          render={() =>
            withSuspense(<EditQrCodePage />, <CreateQrCodePageSkeleton />)
          }
        />
        <PrivateRoute
          exact
          path={ROUTE_MODIFY_QR_CODE}
          render={() =>
            withSuspense(<ModifyQrCodePage />, <CreateQrCodePageSkeleton />)
          }
        />
        <PrivateRoute
          exact
          path={ROUTE_QR_CODE_ANALYTICS}
          render={() =>
            withSuspense(<QrCodeAnalyticsPage />, <QrCodeAnalyticsSkeleton />)
          }
        />
        <PrivateRoute
          exact
          path={ROUTE_QR_CODE_FEEDBACKS}
          render={() => withSuspense(<FeedbackPage />, <FeedbackSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_BILLING}
          render={() => withSuspense(<BillingPage />, <FeedbackSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_UPGRADE_PLAN}
          render={() => withSuspense(<ListPlans />, <TariffSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_DETAILED_PLAN}
          render={() => withSuspense(<DetailedPlan />, <DetailsSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_FOLDERS}
          render={() => withSuspense(<FolderListPage />, <ListSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_FOLDER_QR_CODES}
          render={(props) =>
            withSuspense(
              <FolderQrCodesListPage id={''} {...props} />,
              <ListSkeleton />
            )
          }
        />
        <PrivateRoute
          exact
          path={ROUTE_ARCHIVE}
          render={() => withSuspense(<ArchiveListPage />, <ListSkeleton />)}
        />
        <PrivateRoute
          exact
          path={ROUTE_ANALYTICS}
          render={() =>
            withSuspense(<CommonAnalyticsPage />, <CommonAnalyticsSkeleton />)
          }
        />
      </Switch>
    </Box>
  );
};
