import closeIcon from 'assets/images/close.svg';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Divider } from 'ui/divider';
import { Icon } from 'ui/icon';
import { Typography } from 'ui/typography';

type Props = {
  loading?: boolean;
  heading: string;
  subheading: string;
  submitText?: string;
  onSubmit: () => void;
  onCalcel: () => void;
};

export const Confirm = ({
  loading,
  heading,
  subheading,
  submitText = 'Delete',
  onSubmit,
  onCalcel,
}: Props) => (
  <Box py={18} width={1} maxWidth={340}>
    <Flex px={24} justifyContent='space-between' alignItems='flex-start'>
      <Typography variant={['h3Mobile', 'h3']} color='gray.black'>
        {heading}
      </Typography>
      <Box pt='12px' pr='4px'>
        <Button onClick={onCalcel}>
          <Icon url={closeIcon} width='13px' height='13px' />
        </Button>
      </Box>
    </Flex>
    <Box px={24} mt='10px' mb={24}>
      <Typography variant='subhead' color='gray.medium'>
        {subheading}
      </Typography>
    </Box>
    <Divider />
    <Box
      px={24}
      mt={18}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '12px',
      }}
    >
      <Button
        disabled={loading}
        variant='filled'
        onClick={onCalcel}
        color='gray.black'
        backgroundColor='blue.mediumLight'
      >
        Cancel
      </Button>
      <Button
        disabled={loading}
        variant='filled'
        onClick={onSubmit}
        color='red.text'
        backgroundColor='red.light'
      >
        {submitText}
      </Button>
    </Box>
  </Box>
);
