export const getToken = (): string | null => {
  let token = localStorage.getItem('token');

  return token;
};

export const saveToken = ({ token }: { token: string }) => {
  localStorage.setItem('token', token);
};

export const removeToken = () => {
  localStorage.removeItem('token');
};
