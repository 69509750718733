import { useCallback } from 'react';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import { useLocation, useHistory } from 'react-router-dom';
import { parse, stringify } from 'modules/utils/qs';

export const useQuery = <T extends {}>(): [T, (q: T) => void] => {
  const history = useHistory();
  const location = useLocation();
  const query = parse<T>(location.search);

  const changeQuery = useCallback(
    (newQuery: T) => {
      const search = stringify(omitBy(isNil, { ...query, ...newQuery }));

      history.push({
        pathname: location.pathname,
        search,
      });
    },
    [history, location.pathname, query]
  );

  return [query, changeQuery];
};
