import ajax from 'api/utils/ajax';
import axios from 'axios';
import { SOCIAL_MEDIA_TYPE } from 'const';
import get from 'lodash/get';
import { fieldAdapter } from 'modules/utils/createAdapter';
import {
  composeValidators,
  maxLength,
  required,
} from 'modules/utils/validators';
import { memo, ReactNode, useCallback, useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Box } from 'rebass/styled-components';
import { FieldArray } from 'ui/field-array';
import { SocialMediaDetailsSubform } from 'ui/forms/subforms/SocialMediaDetailsSubform';
import { TextField } from 'ui/input';
import { BackgroundInput } from 'ui/input/BackgroundInput';
import ColorInput from 'ui/input/ColorInput';
import { SocialMediaArraySelect } from 'ui/input/SocialMediaArrayInput';
import { Typography } from 'ui/typography';
import { S3File } from './CreateQrCodePdfSubform';

const FAColorInput = fieldAdapter(ColorInput);
const FATextField = fieldAdapter(TextField);
const FABgInput = fieldAdapter(BackgroundInput);

type Props = {
  internalNotesSlot: ReactNode;
};

const SMDetailedSubform = (props: any) => (
  <Box mt='16px'>
    <SocialMediaDetailsSubform {...props} />
  </Box>
);

export const CreateQrCodeSocialMediaSubform = memo(
  ({ internalNotesSlot }: Props) => {
    const { change } = useForm();
    const { values } = useFormState();
    const { fields } = useFieldArray('links');
    const [isOpen, setOpen] = useState(false);
    const imgFile = get(values, 'custom_image');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const addLink = (v: any) => fields.push(v);
    const removeLink = (i: number) => fields.remove(i);

    const onUpload = useCallback(() => {
      inputRef?.current?.click();
    }, []);

    // const onFileUpload = useCallback(
    //   async (event: any) => {
    //     const file = event.target.files[0];

    //     change('custom_image', file);
    //     change('image_index', undefined);
    //   },
    //   [change]
    // );

    const handleCustomImageUploadAws = useCallback(async (file: File) => {
      try {
        const presignedResponse = await ajax.get('/aws/s3/presigned_urls/new', {
          params: {
            s3_key: file.name,
            content_type: SOCIAL_MEDIA_TYPE,
          },
        });

        const preasignedUrl: string = await presignedResponse.data
          .presigned_url;
        const fileUrl: string = presignedResponse.data.file_url;

        if (!file) {
          return null;
        }

        await axios.put(preasignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        const filePreview: S3File = Object.assign(file, {
          preview: URL.createObjectURL(file),
          s3Key: encodeURI(fileUrl),
        });

        return filePreview;
      } catch (err) {
        console.log('handleCustomImageUploadAws err =>', err);
      }
    }, []);

    const onFileUpload = useCallback(
      async (event: any) => {
        const file = await event.target.files[0];
        const uploadedCustomImage = await handleCustomImageUploadAws(file);

        if (uploadedCustomImage) {
          change('custom_image', uploadedCustomImage.s3Key);
          change('image_index', undefined);
        }
      },
      [change, handleCustomImageUploadAws]
    );

    const onReset = useCallback(() => {
      change('custom_image', undefined);
      change('image_index', undefined);
    }, [change]);

    return (
      <Box>
        <Box>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Basic Information
          </Typography>
        </Box>
        <Box mt='16px'>
          <Field
            id='headline'
            name='headline'
            label='Headline*'
            placeholder='Enter name'
            component={FATextField}
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mt='16px'>
          <Field
            id='description'
            name='description'
            label='Description*'
            placeholder='Select'
            component={FATextField}
            validate={composeValidators(required, maxLength(200))}
          />
        </Box>
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Social Media Channel
          </Typography>
        </Box>
        <Box mt='16px'>
          <SocialMediaArraySelect
            isOpen={isOpen}
            onToggle={setOpen}
            onClick={addLink}
          />
        </Box>
        <FieldArray
          name='links'
          renderer={SMDetailedSubform}
          itemProps={{ onRemove: removeLink }}
        />
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Template
          </Typography>
        </Box>
        <Box mt='16px'>
          <Field
            id='image_index'
            name='image_index'
            isFile={!!imgFile}
            file={imgFile}
            component={FABgInput}
            onUpload={onUpload}
            onReset={onReset}
          />
          <Field
            id='custom_image'
            name='custom_image'
            component={(props: Object) => (
              <input
                ref={inputRef}
                {...props}
                type='file'
                style={{ width: 0, visibility: 'hidden' }}
                onChange={onFileUpload}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
              />
            )}
          />
        </Box>
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Color palette
          </Typography>
        </Box>
        <Box
          mt='16px'
          sx={{
            display: 'grid',
            columnGap: '12px',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <Box>
            <Field
              id='background'
              name='background_social_color'
              label='Background'
              component={FAColorInput}
            />
          </Box>
          <Box>
            <Field
              id='textColor'
              name='text_social_color'
              label='Text'
              component={FAColorInput}
            />
          </Box>
        </Box>
        <Box mt='16px'>
          <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
            Welcome Screen
          </Typography>
        </Box>
        <Box mt='16px'>Welcome</Box>
        {internalNotesSlot}
      </Box>
    );
  }
);
