import { UI_HEADER_LINKS } from 'const';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { NavLink } from 'ui/link';
import { LogoLink } from '.';

const Root = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const HeaderLinkUI = styled(NavLink).attrs({
  textDecoration: 'none',
  variant: 'footnote',
})`
  position: relative;
  color: ${({ theme }) => theme.colors.blue.light};
  transition: color 0.2s;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.white.main};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.white.main};
    height: 3px;
    width: 0;
    transition: width 0.3s;
    margin-top: 8px;
  }

  &.active:after {
    width: 100%;
  }
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 18px 0 0;
  padding: 0;
  text-indent: 0;
  list-style-type: 0;

  &:last-child {
    margin-right: 0;
  }

  ${mq.md} {
    margin-right: 28px;
  }
`;

const RootMobile = styled.div<{ isMobileMenuCollapsed?: boolean }>`
  position: fixed;
  top: 47px;
  left: 0;
  min-height: calc(100vh - 47px);
  height: auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3278e4;
  z-index: 1051;
  overflow: hidden;
  transition: all 0.2s;
  opacity: ${({ isMobileMenuCollapsed }) =>
    isMobileMenuCollapsed ? `0` : '1'};
  visibility: ${({ isMobileMenuCollapsed }) =>
    isMobileMenuCollapsed ? `hidden` : 'visible'};
  transform: ${({ isMobileMenuCollapsed }) =>
    isMobileMenuCollapsed ? `translateX(-5%)` : 'translateX(0%)'};

  & > a {
    display: flex;
    justify-content: center;
    width: 100;
    padding: 2em 0;
    border-bottom: 1px solid rgb(241 241 241 / 40%);
  }

  ul {
    margin: 0;
    padding: 2em 3em 0;
    list-style-type: none;

    li {
      align-items: flex-start;
      margin-bottom: 8px;
      padding: 12px 0;

      a {
        font-size: 14px;
      }

      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }
`;

type HeaderLinkProps = {
  link: string;
  title: string;
  exact?: boolean;
  handleMobileMenuClose?: () => void;
};

const HeaderLinksItem = ({
  link,
  title,
  exact = true,
  handleMobileMenuClose,
}: HeaderLinkProps) => (
  <LinkWrapper>
    <HeaderLinkUI onClick={handleMobileMenuClose} exact={exact} to={link}>
      {title}
    </HeaderLinkUI>
  </LinkWrapper>
);

type LinksProps = {
  isMobile?: boolean;
  isMobileMenuCollapsed?: boolean;
  handleMobileMenuClose?: any;
  confirmed?: boolean;
};

export const Links = ({
  isMobile,
  isMobileMenuCollapsed,
  handleMobileMenuClose,
  confirmed,
}: LinksProps) => {

  if (!confirmed) {
    return null;
  }

  return (
    isMobile ? (
      <RootMobile isMobileMenuCollapsed={isMobileMenuCollapsed}>
        <LogoLink confirmed={confirmed} />
        <ul>
          {UI_HEADER_LINKS.map((linkObj: HeaderLinkProps) => (
            <HeaderLinksItem
              key={linkObj.link}
              handleMobileMenuClose={handleMobileMenuClose}
              {...linkObj}
            />
          ))}
        </ul>
      </RootMobile>
    ) : (
      <Root>
        {UI_HEADER_LINKS.map((linkObj: HeaderLinkProps) => (
          <HeaderLinksItem key={linkObj.link} {...linkObj} />
        ))}
      </Root>
    )
  )
};
