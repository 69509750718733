import get from 'lodash/get';
import isNil from 'lodash/isNil';

export const getOr = <T>(obj: any, path: string, defaultValue: T): T => {
  const v = get<T>(obj, path, defaultValue);

  if (isNil(v)) {
    return defaultValue;
  }

  return v;
};
