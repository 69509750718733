import { useState, ReactNode } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Error } from 'ui/labels';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { InputUI, Label, Root } from 'ui/input';
import { Item, List } from 'ui/list';
import { Typography } from 'ui/typography';

import arrowIcon from 'assets/images/down-arrow.svg';

export type SelectItem = { value: string; title: string | ReactNode };

const ToggleInputWrapper = styled(InputUI)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

type ToggleProps = {
  selected?: SelectItem;
  placeholder: string;
};

const ToggleButton = ({ selected, placeholder, ...props }: ToggleProps) => (
  <Button {...props} width='100%'>
    <ToggleInputWrapper as='div'>
      {selected ? (
        <Typography
          variant='subhead'
          color='gray.black'
          textAlign='left'
          lineHeight='1.15'
        >
          {selected.title}
        </Typography>
      ) : (
        <Typography
          variant='subhead'
          color='gray.medium'
          textAlign='left'
          lineHeight='1.15'
        >
          {placeholder}
        </Typography>
      )}

      <Icon url={arrowIcon} ml='10px' height='8px' width='14px' />
    </ToggleInputWrapper>
  </Button>
);

type Props = {
  id: string;
  options: SelectItem[];
  onChange: (v: any) => void;
  value: any;
  placeholder?: string;
  errorText?: string;
  label?: string;
  firstItem?: ReactNode;
  onFirstItemClick?: () => void;
};

export const Select = ({
  id,
  value,
  onChange,
  options,
  placeholder = '',
  errorText,
  label,
  firstItem,
  onFirstItemClick,
  ...props
}: Props) => {
  const selected = options.find((i) => i.value === value);
  const [isOpen, onToggle] = useState(false);
  const handleItemClick = (item: SelectItem) => {
    onChange(item.value);
    onToggle(false);
  };

  return (
    <Root>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Dropdown
        fullWidth
        position={{ top: 'calc(100% + 8px)', left: 0 }}
        toggleSlot={
          <ToggleButton placeholder={placeholder} selected={selected} />
        }
        isOpen={isOpen}
        onToggle={onToggle}
        block
        {...props}
      >
        <Card>
          <Box p='8px 0' width={1}>
            <List>
              {firstItem && (
                <Item onClick={() => onFirstItemClick && onFirstItemClick()}>
                  {firstItem}
                </Item>
              )}
              {options.map((item: SelectItem) => (
                <Item onClick={() => handleItemClick(item)} key={item.value}>
                  <Typography
                    textAlign='left'
                    variant='subhead'
                    color='gray.black'
                    fontWeight='normal'
                  >
                    {item.title}
                  </Typography>
                </Item>
              ))}
              {options.length === 0 && (
                <Item>
                  <Typography
                    textAlign='left'
                    variant='subhead'
                    color='gray.black'
                    fontWeight='normal'
                  >
                    No options
                  </Typography>
                </Item>
              )}
            </List>
          </Box>
        </Card>
      </Dropdown>
      {errorText && <Error>{errorText}</Error>}
    </Root>
  );
};
