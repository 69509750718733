import get from 'lodash/get';
import { RootState } from 'store/index';
import { Folder } from 'types';

export const selectFolders = (state: RootState): Folder[] => {
  const ids = get(state, 'folders.ids', []);

  return ids.map((id: number) => get(state, `folders.byIds.${id}`));
};

export const selectFolderById = (id: number) => (
  state: RootState
): Folder => {
  return get(state, `folders.byIds.${id}`);
};
