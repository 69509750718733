import ajax from './utils/ajax';

type SendConfirmationTokenRequest = {
  confirmation_token: string;
};

type ResendEmailConfirmationRequest = {
  email: string;
};

type SendConfirmationTokenResponse = {
  data: {
    token: string;
    confirmed: boolean;
  };
};

type ResendEmailConfirmationResponse = {
  data: {
    email: string;
  };
};


export const sendConfirmationToken = (payload: SendConfirmationTokenRequest): Promise<SendConfirmationTokenResponse> => {
  return ajax.post('/confirmation', payload);
};

export const resendEmailConfirmation = (payload: ResendEmailConfirmationRequest): Promise<ResendEmailConfirmationResponse> => {
  return ajax.post('/confirmation/resend', payload)
};