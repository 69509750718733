import { PlansResponse } from '../types';
import { Plans } from 'types';

export const mapResponseToPlans = (res: PlansResponse): Plans => ({
  recommendation: res.recommendation,
  plans: res.plans.map((plan) => ({
    ...plan,
    id: plan.id,
    discountPlan: plan.discount_plan,
    permissions: {
      staticQrCodesLimit: plan.permissions.static_qr_codes_limit,
      dynamicQrCodesLimit: plan.permissions.dynamic_qr_codes_limit,
      download: {
        types: plan.permissions.download.types,
        highQuality: plan.permissions.download.high_quality,
      },
      types: plan.permissions.types,
      foldersAccess: plan.permissions.folders_access,
      analytics: plan.permissions.analytics,
      users: plan.permissions.users,
    },
    features: plan.features,
    planCode: plan.plan_code,
    planId: plan.plan_id,
    discountedPrice: plan.discounted_price,
    billingPeriod: plan.billing_period,
  })),
});
