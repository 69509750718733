import LogoTitleImage from 'assets/images/logo-title.svg';
import LogoImage from 'assets/images/logo.png';
import { ROUTE_CONFIRM_USER_EMAIL, ROUTE_ROOT, UI_HEADER_HEIGHT, UI_HEADER_HEIGHT_MOBILE } from 'const';
import { memo, ReactNode } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { mq } from 'theme/breakpoints';
import { Link } from 'ui/link';

const Layout = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${UI_HEADER_HEIGHT_MOBILE};
  background: ${({ theme }) => theme.colors.blue.main};
  z-index: 1000;

  ${mq.sm} {
    position: absolute;
    height: ${UI_HEADER_HEIGHT};
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.md} {
    max-width: 1274px;
  }
`;

const Logo = styled.img`
  width: 16px;
  height: 20px;
  margin-right: 6px;
`;

const LogoTitle = styled.img`
  width: 89px;
  height: 24px;
`;

const SearchSlot = styled.div`
  width: calc(100% - 66px);
  margin-right: 20px;
`;

const DropdownSlot = styled.div`
  width: 36px;
`;

type Props = {
  confirmed?: boolean;
}

export const LogoLink = ({ confirmed }: Props) => (
  <LogoWrapper to={confirmed ? ROUTE_ROOT : ROUTE_CONFIRM_USER_EMAIL} aria-label='Supercode'>
    <Logo src={LogoImage} alt='' />
    <LogoTitle src={LogoTitleImage} alt='Supercode' />
  </LogoWrapper>
);

type HeaderProps = {
  isMobile?: boolean;
  confirmed?: boolean;
  searchSlot?: ReactNode;
  linksSlot?: ReactNode;
  userDropdownSlot?: ReactNode;
  upgrageSlot?: ReactNode | null;
  pastDueSlot?: ReactNode | null;
  burgerSlot?: ReactNode;
};

export const Header = memo(
  ({
    isMobile,
    searchSlot,
    linksSlot,
    userDropdownSlot,
    upgrageSlot,
    pastDueSlot,
    burgerSlot,
    confirmed,
  }: HeaderProps) =>
    isMobile ? (
      <Layout>
        <Container>
          {linksSlot && linksSlot}
          {burgerSlot && burgerSlot}

          {(userDropdownSlot || searchSlot) && (
            <Flex alignItems='center' justifyContent='flex-end'>
              {searchSlot && <SearchSlot>{searchSlot}</SearchSlot>}
              {userDropdownSlot && (
                <DropdownSlot>{userDropdownSlot}</DropdownSlot>
              )}
            </Flex>
          )}
        </Container>
        {upgrageSlot}
        {pastDueSlot}
      </Layout>
    ) : (
      <Layout>
        <Container>
          <LogoLink confirmed={confirmed} />
          {linksSlot && (
            <Box pl={['0', '50px', '80px', '190px']}>{linksSlot}</Box>
          )}
          {(userDropdownSlot || searchSlot) && (
            <Flex alignItems='center'>
              {searchSlot && <SearchSlot>{searchSlot}</SearchSlot>}
              {userDropdownSlot && (
                <DropdownSlot>{userDropdownSlot}</DropdownSlot>
              )}
            </Flex>
          )}
        </Container>
        {upgrageSlot}
        {pastDueSlot}
      </Layout>
    )
);
