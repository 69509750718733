import { QR_CODES_FONT_SIZES } from 'const';
import { fieldAdapter } from 'modules/utils/createAdapter';
import { maxLength } from 'modules/utils/validators';
import { Field, useForm } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { TextField } from 'ui/input';
import ColorInput from 'ui/input/ColorInput';
import { FrameInput } from 'ui/input/FrameInput';
import { Select } from 'ui/select';
import { Typography } from 'ui/typography';

const FullRow = styled(Box)`
  grid-column: 1 / 3;
`;

const FAColorInput = fieldAdapter(ColorInput);
const FAFrameInput = fieldAdapter(FrameInput);
const FATextField = fieldAdapter(TextField);
const FASelect = fieldAdapter(Select);

export const ModifyDesignFrameSubform = () => {
  const { change } = useForm();

  const onSetFrameFlag = (v: boolean) => {
    change('set_default_icon', v);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: '12px',
        rowGap: '16px',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <FullRow>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Type
        </Typography>
      </FullRow>
      <FullRow>
        <Box mt='16px'>
          <Field
            id='template'
            name='template'
            component={FAFrameInput}
            onSetFrameFlag={onSetFrameFlag}
          />

          <Field
            id='setFefaultIcon'
            name='set_default_icon'
            component={() => null}
          />
        </Box>
      </FullRow>
      <FullRow>
        <Flex mt='14px'>
          <Box mr='15px' width={1}>
            <Field
              id='templateText'
              name='template_text'
              label='Custom text'
              component={FATextField}
              validate={maxLength(20)}
            />
          </Box>
          <Box width={112}>
            <Field
              id='fontSize'
              label='Size'
              name='font_size'
              fullWidth
              options={QR_CODES_FONT_SIZES}
              component={FASelect}
            />
          </Box>
        </Flex>
      </FullRow>
      <FullRow>
        <Typography variant={['h4Mobile', 'h4']} color='gray.black'>
          Color
        </Typography>
      </FullRow>
      <Box>
        <Field
          id='borderColor'
          name='border_color'
          label='Frame'
          component={FAColorInput}
        />
      </Box>
      <Box>
        <Field
          id='textColor'
          name='font_color'
          label='Text'
          component={FAColorInput}
        />
      </Box>
    </Box>
  );
};
