import styled from 'styled-components';
import { Button } from 'ui/button';

const BurgerWrapper = styled(Button)<{ isMobileMenuCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;

  span {
    display: inline-block;
    width: 28px;
    height: 2px;
    margin-bottom: 6px;
    background-color: #fff;
    border-radius: 6px;
    transition: all 0.3s;

    &:first-child {
      margin-top: ${({ isMobileMenuCollapsed }) =>
        isMobileMenuCollapsed ? 0 : '0'};
      transform: ${({ isMobileMenuCollapsed }) =>
        isMobileMenuCollapsed ? `rotate(0)` : `rotate(45deg)`};
    }

    &:last-child {
      margin-top: ${({ isMobileMenuCollapsed }) =>
        isMobileMenuCollapsed ? 0 : '-16px'};
      margin-bottom: 0;
      transform: ${({ isMobileMenuCollapsed }) =>
        isMobileMenuCollapsed ? `rotate(0)` : `rotate(-45deg)`};
    }

    &:nth-child(2) {
      transform: ${({ isMobileMenuCollapsed }) =>
        isMobileMenuCollapsed ? `scale(1)` : `scale(0)`};
    }
  }
`;

type BurgerButtonProps = {
  isMobileMenuCollapsed: boolean;
  toggleMobileMenuCollapse: () => void;
};

export const BurgerButton = ({
  isMobileMenuCollapsed,
  toggleMobileMenuCollapse,
}: BurgerButtonProps) => {
  return (
    <BurgerWrapper
      isMobileMenuCollapsed={isMobileMenuCollapsed}
      onClick={toggleMobileMenuCollapse}
    >
      <span />
      <span />
      <span />
    </BurgerWrapper>
  );
};
