import ajax from './utils/ajax';
import { stringify } from 'modules/utils/qs';
import { mapResponseToFolder } from './mappers/folders';
import { FolderResponse } from './types';
import { Folder } from 'types';

type CreateFolderRequest = {
  name: string;
};

export const postFolder = async (
  payload: CreateFolderRequest
): Promise<Folder> => {
  const { data: folder }: { data: FolderResponse } = await ajax.post(
    '/folders',
    payload
  );

  return mapResponseToFolder(folder);
};

type FetchFoldersRequest = {
  searchPattern?: string;
  limit?: number;
  offset?: number;
};

export const getFolders = async ({
  searchPattern,
  limit = 100,
  offset = 0,
}: FetchFoldersRequest): Promise<Folder[]> => {
  const queryStr = stringify({ search_pattern: searchPattern, limit, offset });
  const { data: folders }: { data: FolderResponse[] } = await ajax.get(
    `/folders${queryStr}`
  );

  return folders.map(mapResponseToFolder);
};

export const getFolder = async (id: string): Promise<Folder> => {
  const { data: folder } = await ajax.get(`/folders/${id}`);

  return mapResponseToFolder(folder);
};

export const deleteForder = async (id: number) => {
  await ajax.delete(`/folders/${id}`);
};

export const patchForder = async (paylaod: {
  id: number;
  name: string;
}): Promise<Folder> => {
  const { data: folder } = await ajax.patch(`/folders/${paylaod.id}`, paylaod);

  return mapResponseToFolder(folder);
};
