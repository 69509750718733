export const ROUTE_ROOT = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_SIGN_UP = '/signup';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_ACCEPT_INTIVATION = '/accept-invitation';

export const ROUTE_BILLING = '/billing';
export const ROUTE_DETAILED_PLAN = '/plans/:planCode';
export const ROUTE_UPGRADE_PLAN = '/upgrade';

export const ROUTE_ANALYTICS = '/analytics';
export const ROUTE_FOLDERS = '/folders';
export const ROUTE_FOLDER_QR_CODES = '/folders/:id';
export const ROUTE_ARCHIVE = '/archive';

export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_BILLING = '/profile/billing';
export const ROUTE_PROFILE_SETTINGS = '/profile/settings';

export const ROUTE_QR_CODE_LIST = '/qr-codes';
export const ROUTE_CREATE_QR_CODE = '/qr-codes/create';
export const ROUTE_EDIT_QR_CODE = '/qr-codes/:id/edit';
export const ROUTE_MODIFY_QR_CODE = '/qr-codes/:id/modify';
export const ROUTE_QR_CODE_ANALYTICS = '/qr-codes/:id/analytics';
export const ROUTE_QR_CODE_FEEDBACKS = '/qr-codes/:id/feedbacks';

export const ROUTE_CONFIRM_USER_EMAIL = '/confirmation'
export const ROUTE_RESEND_CONFIRM_USER_EMAIL = '/confirmation/resend'

export const AUTH_TABS = [
  {
    label: 'Login',
    value: ROUTE_LOGIN,
  },
  {
    label: 'Registration',
    value: ROUTE_SIGN_UP,
  },
];

export const QR_CODE_LIST_TABS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Static',
    value: 'static',
  },
  {
    label: 'Dynamic',
    value: 'dynamic',
  },
];
