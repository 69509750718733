import { colors } from './colors';

export const fontSizes = [
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '44px',
];

export const fontWeights = {
  normal: 400,
  bold: 500,
  extraBold: 600,
  black: 700,
};

export const fonts = {
  poppins: '"Poppins", sans-serif',
};

const defaultProperties = {
  margin: 0,
  padding: 0,
  color: colors.gray.black,
};

export const typography = {
  h1: {
    ...defaultProperties,
    fontSize: '32px',
    fontWeight: fontWeights.black,
    lineHeight: '55px',
  },
  h1Mobile: {
    ...defaultProperties,
    fontSize: '26px',
    fontWeight: fontWeights.black,
    lineHeight: 1.6,
  },
  h2: {
    ...defaultProperties,
    fontSize: '32px',
    fontWeight: fontWeights.black,
    lineHeight: '40px',
  },
  h2Mobile: {
    ...defaultProperties,
    fontSize: '24px',
    fontWeight: fontWeights.black,
    lineHeight: 1.6,
  },
  h3: {
    ...defaultProperties,
    fontSize: fontSizes[5],
    fontWeight: fontWeights.bold,
    lineHeight: '38px',
  },
  h3Mobile: {
    ...defaultProperties,
    fontSize: fontSizes[4],
    fontWeight: fontWeights.bold,
    lineHeight: 1.6,
  },
  h4: {
    ...defaultProperties,
    fontSize: fontSizes[4],
    fontWeight: fontWeights.bold,
    lineHeight: '27px',
  },
  h4Mobile: {
    ...defaultProperties,
    fontSize: fontSizes[3],
    fontWeight: fontWeights.bold,
    lineHeight: 1.6,
  },
  body: {
    ...defaultProperties,
    fontSize: fontSizes[3],
    fontWeight: fontWeights.normal,
    lineHeight: '26px',
  },
  subhead: {
    ...defaultProperties,
    fontSize: fontSizes[2],
    fontWeight: fontWeights.normal,
    lineHeight: '22px',
  },
  footnote: {
    ...defaultProperties,
    fontSize: fontSizes[1],
    fontWeight: fontWeights.bold,
    lineHeight: '18px',
  },
};

export type TypographyVariants =
  | 'h1'
  | 'h1Mobile'
  | 'h2'
  | 'h2Mobile'
  | 'h3'
  | 'h3Mobile'
  | 'h4'
  | 'h4Mobile'
  | 'body'
  | 'subhead'
  | 'footnote';
export type Typography = typeof typography;
export type FontWeights = typeof fontWeights;
export type Fonts = typeof fonts;
