export const required = (value: string | undefined | null) =>
  value ? undefined : 'Required';

export const arrayRequired = (value?: any[]) =>
  value?.length ? undefined : 'Required';

export const arrayNotEmptyItemsRequired = (value: any[]) => {
  for (let i = 0; i < value.length; i++) {
    const item = value[i];

    if (typeof item === 'object' && Object.keys(item).length === 0) {
      return 'Required';
    }
  }

  return undefined;
};

export const qrCodeTypeValidation =(currentPlan: any) => (value: any) => {
  const maxDynamicCodes = currentPlan.dynamicCodes.maxValue;
  const currentDynamicCodes = currentPlan.dynamicCodes.current;
  if (value === 'dynamic' && maxDynamicCodes === 0) {
    return  'Purchase a subscription to continue'
  }

  if (value === 'dynamic' && currentDynamicCodes === maxDynamicCodes) {
    return  'Upgrade to better subscription to continue'
  }

  return undefined;
}

export const minLength = (min: number) => (value: string | undefined | null) =>
  value !== null &&
  value !== undefined &&
  value.length !== 0 &&
  value.length < min
    ? `Password length should be greater than ${min} symbols`
    : undefined;

export const maxLength = (max: number) => (value: string | undefined | null) =>
  value !== null &&
  value !== undefined &&
  value.length !== 0 &&
  value.length > max
    ? `Length should not be greater than ${max} symbols`
    : undefined;

export const isEmail = (value: string | undefined | null) => {
  if (!value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return undefined;
  }

  return 'Incorrect email address!';
};

export const isUrl = (value: string | undefined | null) => {
  if (
    !value ||
    /https?:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/.test(
      value
    )
  ) {
    return undefined;
  }

  return 'Incorrect url!';
};

export const isPhoneNumber = (phoneNumber: string) => {
  const phoneRegexp = /\+[1-9]\d{10}/;
  return phoneRegexp.test(phoneNumber) ? undefined : 'Incorrect phone number';
};

export const isNumber = (value: any) =>
  value && isNaN(value as number) ? 'Must be a number' : undefined;

type ValidationResultType = string | undefined;
type ValidatorFunctionType = (arg: any) => ValidationResultType;

export const composeValidators = (...validators: ValidatorFunctionType[]) => (
  value: any
) =>
  validators.reduce(
    (error: ValidationResultType, validator: ValidatorFunctionType) =>
      error || validator(value),
    undefined
  );
