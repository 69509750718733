import { DYNAMIC_QR_CODES, URL_TYPE } from 'const';
import { AutoSaveDebounce } from 'modules/forms/AutoSaveDebounce';
import {
  createElement,
  FunctionComponent,
  memo,
  ReactNode,
  useMemo,
} from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Box, Flex } from 'rebass/styled-components';
import { Folder, QrCodeFormValues } from 'types';
import { Button } from 'ui/button';
import { fieldArrayMutators } from 'ui/field-array';
import { BulkUploadSubform } from 'ui/forms/subforms/BulkUploadSubform';
import { CreateQrCodeEmailSubform } from 'ui/forms/subforms/CreateQrCodeEmailSubform';
import { CreateQrCodeFeedbackSubform } from 'ui/forms/subforms/CreateQrCodeFeedbackSubform';
import { CreateQrCodeSmsSubform } from 'ui/forms/subforms/CreateQrCodeSmsSubform';
import { CreateQrCodeSocialMediaSubform } from 'ui/forms/subforms/CreateQrCodeSocialMediaSubform';
import { CreateQrCodeTextSubform } from 'ui/forms/subforms/CreateQrCodeTextSubform';
import { CreateQrCodeUrlSubform } from 'ui/forms/subforms/CreateQrCodeUrlSubform';
import { CreateQrCodeVCardSubform } from 'ui/forms/subforms/CreateQrCodeVCardSubform';
import { InternalNotesSubform } from 'ui/forms/subforms/InternalNotesSubform';
import { ModifyDesignSubform } from 'ui/forms/subforms/ModifyDesignSubform';
import { SelectQrCodeTypeSubform } from 'ui/forms/subforms/SelectQrCodeTypeSubform';
import { Tooltip } from 'ui/tooltip';
import { When } from 'ui/when';
import { CreateQrCodeImageSubform } from './subforms/CreateQrCodeImageSubform';
import { CreateQrCodePdfSubform } from './subforms/CreateQrCodePdfSubform';
import { CreateQrCodeWhatsappSubform } from './subforms/CreateQrCodeWhatsappSubform';

export const MAP_TYPE_TO_FORM: { [key: string]: FunctionComponent<any> } = {
  url: CreateQrCodeUrlSubform,
  text: CreateQrCodeTextSubform,
  email: CreateQrCodeEmailSubform,
  sms: CreateQrCodeSmsSubform,
  whatsapp: CreateQrCodeWhatsappSubform,
  vcard: CreateQrCodeVCardSubform,
  social_media: CreateQrCodeSocialMediaSubform,
  feedback: CreateQrCodeFeedbackSubform,
  bulk_url: BulkUploadSubform,
  image: CreateQrCodeImageSubform,
  pdf: CreateQrCodePdfSubform,
};

type FormProps = {
  step: number;
  steps: number;
  initialValues: {};
  onNext: (args: QrCodeFormValues) => void;
  onSubmit: (args: QrCodeFormValues) => void;
  render: (props: FormRenderProps<QrCodeFormValues>) => ReactNode;
};

export const CreateQrCodeWizardForm = ({
  step,
  steps,
  initialValues,
  onNext,
  onSubmit,
  render,
}: FormProps) => {
  const isLastStep = step === steps - 1;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={isLastStep ? onSubmit : onNext}
      mutators={{
        ...fieldArrayMutators,
      }}
      render={(renderProps) => render(renderProps)}
      subscription={{
        values: true,
        submitError: true,
        submitting: true,
        valid: true,
        invalid: true,
      }}
    />
  );
};

type ContentProps = {
  step: number;
  steps: number;
  qrCodeType: string | undefined;
  contentType?: string;
  folders: Folder[];
  isStaticCodesAllowed: boolean;
  isDynamicCodesAllowed: boolean;
  onPrev: () => void;
  onSave: (args: QrCodeFormValues) => void;
};

export const CreateQrCodeWizardFormContent = memo(
  ({
    step,
    steps,
    folders,
    contentType = URL_TYPE,
    isStaticCodesAllowed,
    isDynamicCodesAllowed,
    onPrev,
    onSave,
    handleSubmit,
    submitting,
    invalid,
    qrCodeType,
    form,
  }: ContentProps & Partial<FormRenderProps<QrCodeFormValues>>) => {
    const isFirstStep = useMemo(() => step === 0, [step]);
    const isLastStep = useMemo(() => step === steps - 1, [step, steps]);
    const contentTypeSubform = useMemo(() => MAP_TYPE_TO_FORM[contentType], [
      contentType,
    ]);
    const canChangeQrCodeType = DYNAMIC_QR_CODES.includes(contentType);
    const internalNotesSlot = <InternalNotesSubform folders={folders} canChangeQrCodeType={canChangeQrCodeType} />;
    const isNextBtnDisabled = useMemo(
      () =>
        invalid ||
        submitting ||
        (!isStaticCodesAllowed && !isDynamicCodesAllowed) ||
        (qrCodeType === 'static' && !isStaticCodesAllowed) ||
        (qrCodeType === 'dynamic' &&
          !isDynamicCodesAllowed &&
          contentType !== URL_TYPE),
      [
        contentType,
        qrCodeType,
        invalid,
        isDynamicCodesAllowed,
        isStaticCodesAllowed,
        submitting,
      ]
    );

    return (
      <Flex
        as='form'
        onSubmit={handleSubmit}
        flexDirection='column'
        justifyContent='space-between'
      >
        <AutoSaveDebounce onSave={onSave} />
        <Box>
          <When condition={step === 0}>
            <SelectQrCodeTypeSubform
              isStaticCodesAllowed={isStaticCodesAllowed}
              isDynamicCodesAllowed={isDynamicCodesAllowed}
            />
          </When>
          <When condition={step === 1}>
            {createElement(contentTypeSubform, {
              internalNotesSlot,
              folders,
              isStaticCodesAllowed,
              isDynamicCodesAllowed,
            })}
          </When>
          <When condition={step === 2}>
            <Box pt='10px'>
              <ModifyDesignSubform />
            </Box>
          </When>
        </Box>
        <Flex mt={30} mb={50} justifyContent={['space-between', 'flex-start']}>
          <Box width={1} maxWidth={['initial', 150]} mr='12px'>
            <Button
              variant='filled'
              bg='blue.mediumLight'
              color='blue.main'
              onClick={onPrev}
              disabled={submitting}
            >
              {isFirstStep ? 'Cancel' : 'Back'}
            </Button>
          </Box>
          <Box width={1} maxWidth={['initial', 150]}>
            <Tooltip event='hover' />
            <Button
              data-tip='Submit all changes'
              variant='filled'
              type='submit'
              disabled={isNextBtnDisabled}
            >
              {isLastStep ? 'Save' : 'Next'}
            </Button>
          </Box>
        </Flex>
      </Flex>
    );
  }
);
