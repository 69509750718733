import { Flex, Box } from 'rebass/styled-components';
import { SkeletonProvider, Skeleton } from 'ui/skeleton';

export const TariffSkeleton = () => (
  <SkeletonProvider>
    <Box>
      <Box maxWidth={391}>
        <Skeleton height={41} />
      </Box>
      <Box maxWidth={343} mt={32}>
        <Skeleton height={36} />
      </Box>
      <Flex
        mt={64}
        flexWrap='wrap'
        flexDirection={['column', 'row']}
        alignItems={['center', 'flex-end']}>
        <Box
          width={1}
          maxWidth={['unset', '45%', 302]}
          mr={[0, 20]}
          mt={[23, 0]}>
          <Skeleton height={418} />
        </Box>
        <Box
          width={1}
          maxWidth={['unset', '45%', 302]}
          mr={[0, 20]}
          mt={[23, 0]}>
          <Skeleton height={446} />
        </Box>
        <Box mt={[23, 23, 0]} width={1} maxWidth={['unset', '45%', 302]}>
          <Skeleton height={418} />
        </Box>
      </Flex>
    </Box>
  </SkeletonProvider>
);
