import ajax from './utils/ajax';
import { stringify } from 'modules/utils/qs';
import { Folder, QrCodeListItem } from 'types';

type GetCountriesStatsRequest = {
  id: number;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

type GetCountriesStatsResponse = {
  country_group: Array<{
    [countryName: string]: { count: number; share: number };
  }>;
  last: boolean;
};

export const getCountriesStats = async (
  payload: GetCountriesStatsRequest
): Promise<GetCountriesStatsResponse> => {
  const { id, start_date, end_date, page } = payload;

  const { data } = await ajax.get(
    `/analytics/country_statistics/${id}${stringify({
      start_date,
      end_date,
      page,
    })}`
  );

  return data;
};

type GetCitiesStatsRequest = {
  id: number;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

type GetCitiesStatsResponse = {
  city_group: Array<{ [cityName: string]: { count: number; share: number } }>;
  last: boolean;
};

export const getCitiesStats = async (
  payload: GetCitiesStatsRequest
): Promise<GetCitiesStatsResponse> => {
  const { id, start_date, end_date, page } = payload;

  const { data } = await ajax.get(
    `/analytics/city_statistics/${id}${stringify({
      start_date,
      end_date,
      page,
    })}`
  );

  return data;
};

type GetOsStatsRequest = {
  id: number;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

type GetOsStatsResponse = {
  os_group: Array<{ [name: string]: { count: number; share: number } }>;
  last: boolean;
};

export const getOsStats = async (
  payload: GetOsStatsRequest
): Promise<GetOsStatsResponse> => {
  const { id, start_date, end_date, page } = payload;

  const { data } = await ajax.get(
    `/analytics/os_statistics/${id}${stringify({
      start_date,
      end_date,
      page,
    })}`
  );

  return data;
};

type GetCsvFileRequest = {
  id: number;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

type GetCsvFileResponse = Blob;

export const getCsvFile = async (
  payload: GetCsvFileRequest
): Promise<GetCsvFileResponse> => {
  const { id, start_date, end_date, page } = payload;
  const url = `/analytics/export_csv/${id}${stringify({
    start_date,
    end_date,
    page,
  })}`;

  const { data } = await ajax({
    url,
    method: 'GET',
    responseType: 'blob',
  });

  return data;
};

type DeleteAnalyticsRequest = {
  id: number;
};

type DeleteAnalyticsResponse = void;

export const deleteAnalytics = async (
  payload: DeleteAnalyticsRequest
): Promise<DeleteAnalyticsResponse> => {
  const { id } = payload;

  const { data } = await ajax.delete(`/analytics/reset_scans/${id}`);

  return data;
};

type GetScanGraphDataRequest = {
  id: number;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
};

type GetScanGraphDataResponse = {
  columns_data: Array<{
    labels: { short_label: string; long_label: string };
    unique_count: number;
    count: number;
  }>;
};

export const getScanGraphData = async (
  payload: GetScanGraphDataRequest
): Promise<GetScanGraphDataResponse> => {
  const { id, start_date, end_date } = payload;

  const { data } = await ajax.get(
    `/analytics/graph_data/${id}${stringify({
      start_date,
      end_date,
    })}`
  );

  return data;
};

type GetViewInfoByCodeIdRequest = {
  id: number;
};

type GetViewInfoByCodeIdResponse = {
  scans: {
    total: number;
    uniq: number;
  };
};

export const getViewInfoByCodeId = async (
  payload: GetViewInfoByCodeIdRequest
): Promise<GetViewInfoByCodeIdResponse> => {
  const { id } = payload;

  const { data } = await ajax.get(`/analytics/views_info/${id}}`);

  return data;
};

type FullQrCodesInfoResponse = {
  total_scans: {
    columns_data: Array<{
      labels: { short_label: string; long_label: string };
      count: number;
    }>;
    growth: number;
    label: number;
  };
  unique_scans: {
    columns_data: Array<{
      labels: { short_label: string; long_label: string };
      count: number;
    }>;
    growth: number;
    label: number;
  };
  active_dynamics_qr_codes: {
    columns_data: Array<{
      labels: { short_label: string; long_label: string };
      count: number;
    }>;
    growth: number;
    label: number;
  };
  average_scans_per_code: {
    columns_data: Array<{
      labels: { short_label: string; long_label: string };
      count: number;
    }>;
    growth: number;
    label: number;
  };
  graph: {
    columns_data: Array<{
      labels: { short_label: string; long_label: string };
      count: number;
    }>;
    growth: number;
    label: number;
  };
  top_qr_scans: Array<QrCodeListItem>;
  top_folders_scans: Array<Folder>;
  top_os: Array<{ name: string; count: number; percents: number }>;
  qr_count: number;
  start_date: string;
};

type GetQrCodesFullStatsRequest = {
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

export const getQrCodesFullInfo = async (
  payload: GetQrCodesFullStatsRequest
): Promise<FullQrCodesInfoResponse> => {
  const { start_date, end_date, page } = payload;
  const { data } = await ajax.get(
    `/analytics/qr_codes_info${stringify({
      start_date,
      end_date,
      page,
    })}`
  );

  return data;
};

type GetCountriesFullStatsRequest = {
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
  page?: number; // default 0
};

type GetCountriesFullStatsResponse = {
  regions: {
    region_scans_count: number;
    countries: Array<{
      country: string;
      cities: Array<{ city: string; count: number; share: number }>;
      count: number;
      share: number;
      region_name: string;
    }>;
  };
  last: boolean;
};

export const getCountriesFullStats = async (
  payload: GetCountriesFullStatsRequest
): Promise<GetCountriesFullStatsResponse> => {
  const { start_date, end_date, page } = payload;

  const { data } = await ajax.get(
    `/analytics/full_countries_info${stringify({
      start_date,
      end_date,
      page,
    })}`
  );

  return data;
};
