import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useDebounce } from 'react-use';

type AutoSaveDebounceControllerProps = {
  values: {};
  active?: string | undefined;
  onSave: (args: {}) => void;
};

const AutoSaveDebounceController = ({
  values,
  onSave,
}: AutoSaveDebounceControllerProps) => {
  const [currentValues, setCurrentValues] = useState({});

  useDebounce(
    () => {
      const isNoChanges = isEqual(values, currentValues);

      if (!isNoChanges) {
        setCurrentValues(values);
        onSave(values);
      }
    },
    300,
    [values, currentValues]
  );

  return null;
};

type Props = {
  onSave: (args: any) => void;
};

export const AutoSaveDebounce = ({ onSave }: Props) => (
  <FormSpy
    subscription={{
      values: true,
      error: true,
      valid: true,
      invalid: true,
      submitError: true,
      submitting: true,
    }}
    render={(renderProps) => (
      <AutoSaveDebounceController onSave={onSave} values={renderProps.values} />
    )}
  />
);
