import { UI_HEADER_HEIGHT } from 'const';
import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from 'theme';
import { mq } from 'theme/breakpoints';

const RootLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: ${UI_HEADER_HEIGHT} 16px 0;

  ${mq.md} {
    max-width: 1440px; // 1104px;
    padding: ${UI_HEADER_HEIGHT} 8px 0;
  }
`;

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => (
  <RootLayout>
    <Container>{children}</Container>
  </RootLayout>
);

const EditorRootLayout = styled(RootLayout)(({ theme }) => ({
  backgroundColor: theme.colors.white.main,
  [mq.sm]: {
    background: `linear-gradient(to right, ${theme.colors.white.main} 65%, ${theme.colors.gray.light} 35%)`,
  },
}));

interface EditorLayoutProps {
  children: ReactNode;
  rightSlot: ReactNode;
}

const EditorContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  ${mq.sm} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px;
  }

  ${mq.xl} {
    max-width: 1274px;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 8px;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  background-color: ${themeGet('colors.white.main')};

  ${mq.sm} {
    width: 60%;
    margin-top: ${UI_HEADER_HEIGHT};
  }

  ${mq.md} {
    margin-left: 8%;
  }

  ${mq.xl} {
    width: 57%;
    margin-left: 13%;
  }
`;

const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 116px;

  ${mq.xs} {
    margin-top: ${UI_HEADER_HEIGHT};
  }

  ${mq.sm} {
    position: sticky;
    top: 0;
    width: 30%;
    height: calc(100vh - 60px);
    margin-top: ${UI_HEADER_HEIGHT};
  }
`;

export const EditorLayout = memo(
  ({ children, rightSlot }: EditorLayoutProps) => (
    <EditorRootLayout>
      <EditorContainer>
        <LeftSide>{children}</LeftSide>
        <RightSide>{rightSlot}</RightSide>
      </EditorContainer>
    </EditorRootLayout>
  )
);
