import { Error } from 'ui/labels';
import { Tabs, Tab } from 'ui/tabs';
import { Root, Label, InputProps } from 'ui/input';

type Props = InputProps & {
  label: string;
  value: string;
  onChange: (v: string) => void;
  tabs: Tab[];
};

export const SwitchInput = ({
  id,
  label,
  value,
  tabs,
  onChange,
  errorText,
}: Props) => {
  return (
    <Root className={errorText ? 'error' : ''}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Tabs active={value} onClick={onChange} tabs={tabs} />
      {errorText && <Error>{errorText}</Error>}
    </Root>
  );
};
