import arrowDownIcon from 'assets/images/arrow-down.svg';
import arrowUpIcon from 'assets/images/arrow-up.svg';
import addIcon from 'assets/images/plus-blue.svg';
import removeIcon from 'assets/images/remove.svg';
import threeDotsIcon from 'assets/images/three-dots-gray-black.svg';
import { BaseSyntheticEvent } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Button } from 'ui/button';
import { Card } from 'ui/card';
import { Divider } from 'ui/divider';
import { Dropdown } from 'ui/dropdown';
import { Icon } from 'ui/icon';
import { Item, List } from 'ui/list';
import { Typography } from 'ui/typography';

export type QrCodeListItem = { value: string; title: string };

interface Props {
  isOpen: boolean;
  onToggle: (v: boolean) => void;
  onAddNewSubCategory: (event: BaseSyntheticEvent) => void;
  onMoveUp: (event: BaseSyntheticEvent) => void;
  onMoveDown: (event: BaseSyntheticEvent) => void;
  onDelete: (event: BaseSyntheticEvent) => void;
}

const ToggleButton = (props: {}) => (
  <Button
    p={['18px 11px', '18px 11px']}
    variant='filled'
    bg='gray.light2'
    {...props}
  >
    <Icon url={threeDotsIcon} height='4px' width='18px' />
  </Button>
);

export const CategoryOptionsDropdown = ({
  onToggle,
  isOpen,
  onAddNewSubCategory,
  onMoveUp,
  onMoveDown,
  onDelete,
}: Props) => {
  const handleItemClick = (cb: (event: BaseSyntheticEvent) => void) => (
    event: BaseSyntheticEvent
  ) => {
    cb(event);
    onToggle(false);
  };

  return (
    <Dropdown
      position={{ top: 'calc(100% + 8px)', right: 0 }}
      toggleSlot={<ToggleButton />}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Card>
        <Box p='8px 0' minWidth='190px'>
          <List>
            <Flex
              as={Item}
              alignItems='center'
              onClick={handleItemClick(onAddNewSubCategory)}
            >
              <Icon
                url={addIcon}
                height='14px'
                mr='10px'
                backgroundPosition='center'
              />
              <Typography
                variant='footnote'
                color='blue.main'
                fontWeight='normal'
              >
                Add subcategory
              </Typography>
            </Flex>
            <Divider />
            <Flex
              as={Item}
              alignItems='center'
              onClick={handleItemClick(onMoveUp)}
            >
              <Icon
                url={arrowUpIcon}
                height='14px'
                mr='10px'
                backgroundPosition='center'
              />
              <Typography
                variant='footnote'
                color='gray.black'
                fontWeight='normal'
              >
                Move Up
              </Typography>
            </Flex>
            <Flex
              as={Item}
              alignItems='center'
              onClick={handleItemClick(onMoveDown)}
            >
              <Icon
                url={arrowDownIcon}
                height='14px'
                mr='10px'
                backgroundPosition='center'
              />
              <Typography
                variant='footnote'
                color='gray.black'
                fontWeight='normal'
              >
                Move Down
              </Typography>
            </Flex>
            <Divider />
            <Flex
              as={Item}
              alignItems='center'
              onClick={handleItemClick(onDelete)}
            >
              <Icon
                url={removeIcon}
                height='14px'
                mr='10px'
                backgroundPosition='center'
              />
              <Typography
                variant='footnote'
                color='gray.black'
                fontWeight='normal'
              >
                Delete
              </Typography>
            </Flex>
          </List>
        </Box>
      </Card>
    </Dropdown>
  );
};
