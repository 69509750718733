import { createAction } from '@reduxjs/toolkit';
import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_LOGIN_OAUTH,
  ACTION_AUTH_REGISTER,
  ACTION_AUTH_REGISTER_OAUTH,
  ACTION_AUTH_FORGOT_PWD,
  ACTION_AUTH_RESET_PWD,
  ACTION_AUTH_STORE_TOKEN,
  ACTION_AUTH_RESET_TOKEN,
  ACTION_AUTH_LOGOUT,
  ACTION_CONFIRM_EMAIL,
  ACTION_RESEND_CONFIRM_EMAIL,
} from 'const';

export type LoginPayload = {
  email: string;
  password: string;
  remember: boolean;
};

export type ConfirmPayload = {
  confirmation_token: string;
}

export type ResendConfirmPayload = {
  email: string;
}

export const login = createAction<LoginPayload>(ACTION_AUTH_LOGIN);
export const loginOAuth = createAction<{ tokenId: string }>(
  ACTION_AUTH_LOGIN_OAUTH
);
export const confirmEmail = createAction<ConfirmPayload>(ACTION_CONFIRM_EMAIL)
export const resendConfirmEmail = createAction<ResendConfirmPayload>(ACTION_RESEND_CONFIRM_EMAIL)

export type RegisterPayload = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  time_zone: string;
};

export const register = createAction<RegisterPayload>(ACTION_AUTH_REGISTER);
export const registerOAuth = createAction<{ tokenId: string }>(
  ACTION_AUTH_REGISTER_OAUTH
);

export const forgotPwd = createAction(ACTION_AUTH_FORGOT_PWD);
export const resetPwd = createAction(ACTION_AUTH_RESET_PWD);

export type StoreTokenPayload = { token: string };

export const storeToken = createAction<StoreTokenPayload>(
  ACTION_AUTH_STORE_TOKEN
);

export const resetToken = createAction(ACTION_AUTH_RESET_TOKEN);
export const logout = createAction(ACTION_AUTH_LOGOUT);
