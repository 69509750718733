import { Field } from 'react-final-form';
import { SelectQrCodeType } from 'ui/input/SelectQrCodeType';
import { required } from 'modules/utils/validators';
import { useFeatureAccess } from 'modules/utils/useFeatureAccess';
import {
  UI_TYPE_OPTIONS,
  DYNAMIC_TYPES,
  STATIC_TYPES,
  BULK_URL_TYPE,
  URL_TYPE,
} from 'const';

type Props = {
  isStaticCodesAllowed: boolean;
  isDynamicCodesAllowed: boolean;
};

const getAllowanceStatus = (
  value: string,
  allowedTypes: string[],
  isStaticCodesAllowed: boolean,
  isDynamicCodesAllowed: boolean
) => {
  if (
    !allowedTypes?.includes(value) ||
    (!isDynamicCodesAllowed && !isStaticCodesAllowed)
  ) {
    return false;
  }

  if (value === URL_TYPE || value === BULK_URL_TYPE) {
    return true;
  }

  if (
    (DYNAMIC_TYPES.includes(value) && !isDynamicCodesAllowed) ||
    (STATIC_TYPES.includes(value) && !isStaticCodesAllowed)
  ) {
    return false;
  }

  return true;
};

export const SelectQrCodeTypeSubform = ({
  isStaticCodesAllowed,
  isDynamicCodesAllowed,
}: Props) => {
  const { allowedTypes } = useFeatureAccess();

  const options = UI_TYPE_OPTIONS.map((option) => ({
    ...option,
    allowed: getAllowanceStatus(
      option.value,
      allowedTypes,
      isStaticCodesAllowed,
      isDynamicCodesAllowed
    ),
  }));

  return (
    <Field
      id='contentType'
      name='content_type'
      component={SelectQrCodeType}
      validate={required}
      options={options}
    />
  );
};
