import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  fetchCountries,
  fetchStates,
  fetchCities,
  setAppState,
} from '../actions/app';
import { getCountryList, getStateList, getCityList } from 'api/countries';

const mapValues = (items: { [code: string]: string }) => {
  const keys = Object.keys(items);

  return keys.map((key) => ({
    code: key,
    title: items[key],
    value: items[key],
  }));
};

function* fetchFieldCountries() {
  try {
    const { data } = yield call(getCountryList);

    const countries = mapValues(data);

    yield put(setAppState({ countries }));
  } catch (e) {}
}

function* fetchStatesByCountryCode({
  payload: { countryCode },
}: PayloadAction<{ countryCode: string }>) {
  try {
    yield put(setAppState({ loading: true, error: null }));

    const { data } = yield call(getStateList, { countryCode });

    const states = mapValues(data);

    yield put(setAppState({ states }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* fetchCitiesByCountryAndStateCodes({
  payload: { countryCode, stateCode },
}: PayloadAction<{ countryCode: string; stateCode: string }>) {
  try {
    const { data } = yield call(getCityList, { countryCode, stateCode });

    const cities = mapValues(data);

    yield put(setAppState({ cities }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

export default function* watcher() {
  yield takeLatest(fetchCountries.type, fetchFieldCountries);
  yield takeLatest(fetchStates.type, fetchStatesByCountryCode);
  yield takeLatest(fetchCities.type, fetchCitiesByCountryAndStateCodes);
}
