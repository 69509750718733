export const colors = {
  red: {
    text: '#E64646',
    bg: '#FAEBEB',
    main: '#F17979',
    light: '#FFF5F5',
  },
  white: {
    main: '#FFFFFF',
  },
  gray: {
    black: '#32363D',
    medium: '#949AA7',
    light: '#F7F7F7',
    light2: '#F1F1F1',
  },
  blue: {
    main: '#3278E4',
    mediumLight: '#EBF1FC',
    light: '#ABCAFA',
    light2: '#E3EEFF',
  },
  green: {
    main: '#62C962',
  },
};

export const Colors = typeof colors;
