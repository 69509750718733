import { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputProps } from 'ui/input';

export const fieldAdapter = (Field: ComponentType<any>) => ({
  input,
  meta,
  ...rest
}: FieldRenderProps<string, any> & InputProps) => (
  <Field {...input} {...rest} errorText={meta.touched ? meta.error : ''} />
);
