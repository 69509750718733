import { PayloadAction } from '@reduxjs/toolkit';
import {
  addCodesToFolder,
  createQrCode,
  fetchQrCodes,
  patchQrCodeActivity,
  patchQrCodeById,
  patchQrCodeStatus,
} from 'api/qrcode';
import { convertPageToOffset } from 'modules/utils/convert';
import { groupQrCodesIdsByQuantity } from 'pages/list/utils/codesUtil';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QrCodeFormValues, QrCodeListItem } from 'types';
import { setAppState } from '../actions/app';
import {
  AddToFolderActionPayload,
  addToFolderQrCodes,
  ArchiveQrCodeActionPayload,
  archiveQrCodes,
  CheckAllQrCodeActionPayload,
  checkAllQrCodes,
  checkQrCodes,
  createQrCodeRoutine,
  fetchQrCodes as fetchQrCodesAction,
  FetchQrCodesActionPayload,
  PauseQrCodeActionPayload,
  pauseQrCodes,
  putAllQrCodes,
  putSizesQrCodes,
  removeQrCode,
  RestoreQrCodeActionPayload,
  restoreQrCodes,
  UnpauseQrCodeActionPayload,
  unpauseQrCodes,
  updateByIdQrCodeRoutine,
  updateQrCodes,
} from '../actions/qrCodes';
import { selectQrCodeById } from '../selectors/qrCodes';
import { CheckQrCodeActionPayload } from './../actions/qrCodes';

function* fetchQrCodesByQuery({
  payload,
}: PayloadAction<FetchQrCodesActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { limit, offset } = convertPageToOffset(
      payload.page,
      payload.perPage
    );
    const {
      qr_codes,
      sizes_by_type,
    }: {
      qr_codes: QrCodeListItem[];
      sizes_by_type: { [type: string]: number };
    } = yield call(fetchQrCodes, {
      limit,
      offset,
      filter_sd_type: payload.filter_sd_type,
      filter_sort: payload.filter_sort,
      filter_type: payload.filter_type,
      search_pattern: payload.search_pattern,
      status: payload.status,
      folder_id: payload.folderId,
    });
    const qrCodes = qr_codes.map(code => ({ ...code, qr_code_type: code.dynamic ? 'dynamic' : 'static' }))
    const hasMore = qr_codes.length === payload.perPage;

    yield put(putAllQrCodes(qrCodes));
    yield put(putSizesQrCodes(sizes_by_type));

    yield put(setAppState({ loading: false, hasMore, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* createNewQrCode({ payload }: PayloadAction<QrCodeFormValues>) {
  try {
    yield put(createQrCodeRoutine.request());
    yield call(createQrCode, payload);

    yield put(createQrCodeRoutine.success());
  } catch (e) {
    yield put(createQrCodeRoutine.failure(e.message || e));
  } finally {
    yield put(createQrCodeRoutine.fulfill());
  }
}

function* updateQrCodeById({ payload }: PayloadAction<QrCodeFormValues>) {
  try {
    yield put(updateByIdQrCodeRoutine.request());
    yield call(patchQrCodeById, payload);

    yield put(updateByIdQrCodeRoutine.success());
  } catch (e) {
    yield put(updateByIdQrCodeRoutine.failure(e.message || e));
  } finally {
    yield put(updateByIdQrCodeRoutine.fulfill());
  }
}

function* addToFolder({ payload }: PayloadAction<AddToFolderActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const ids = payload.ids;
    const maxRequestNum = 10;

    if (ids.length > maxRequestNum) {
      const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
        ids,
        maxRequestNum
      );

      for (const groupedIds of groupedQrCodesIdsByQuantity) {
        yield call(addCodesToFolder, {
          folderId: payload.folderId,
          ids: groupedIds,
        });

        for (const id of groupedIds) {
          const qrCode = yield select(selectQrCodeById(id));

          yield put(
            updateQrCodes({
              ...qrCode,
              folder_id: payload.folderId,
              folder_name: payload.folderName,
            })
          );
        }
      }
    } else {
      yield call(addCodesToFolder, payload);

      for (const id of ids) {
        const qrCode = yield select(selectQrCodeById(id));

        yield put(
          updateQrCodes({
            ...qrCode,
            folder_id: payload.folderId,
            folder_name: payload.folderName,
          })
        );
      }
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* archiveQrCodesByIds({
  payload,
}: PayloadAction<ArchiveQrCodeActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { ids, removeAfter } = payload;
    const maxRequestNum = 10;

    if (removeAfter) {
      if (ids.length > maxRequestNum) {
        const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
          ids,
          maxRequestNum
        );

        for (const groupedIds of groupedQrCodesIdsByQuantity) {
          yield call(patchQrCodeStatus, { ids: groupedIds, status: 'expired' });

          for (const id of groupedIds) {
            yield put(removeQrCode({ id }));
          }
        }
      } else {
        yield call(patchQrCodeStatus, { ids, status: 'expired' });

        for (const id of ids) {
          yield put(removeQrCode({ id }));
        }
      }
    } else {
      if (ids.length > maxRequestNum) {
        const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
          ids,
          maxRequestNum
        );

        for (const groupedIds of groupedQrCodesIdsByQuantity) {
          yield call(patchQrCodeStatus, { ids: groupedIds, status: 'expired' });

          for (const id of groupedIds) {
            const code = yield select(selectQrCodeById(id));

            yield put(updateQrCodes({ ...code, status: 'expired_manually' }));
          }
        }
      } else {
        yield call(patchQrCodeStatus, { ids, status: 'expired' });

        for (const id of ids) {
          const code = yield select(selectQrCodeById(id));

          yield put(updateQrCodes({ ...code, status: 'expired_manually' }));
        }
      }
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* restoreQrCodesByIds({
  payload,
}: PayloadAction<RestoreQrCodeActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { ids, removeAfter } = payload;
    const maxRequestNum = 10;

    if (removeAfter) {
      if (ids.length > maxRequestNum) {
        const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
          ids,
          maxRequestNum
        );

        for (const groupedIds of groupedQrCodesIdsByQuantity) {
          yield call(patchQrCodeStatus, { ids: groupedIds, status: 'working' });

          for (const id of groupedIds) {
            yield put(removeQrCode({ id }));
          }
        }
      } else {
        yield call(patchQrCodeStatus, { ids, status: 'working' });

        for (const id of ids) {
          yield put(removeQrCode({ id }));
        }
      }
    } else {
      if (ids.length > maxRequestNum) {
        const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
          ids,
          maxRequestNum
        );

        for (const groupedIds of groupedQrCodesIdsByQuantity) {
          yield call(patchQrCodeStatus, { ids: groupedIds, status: 'working' });

          for (const id of groupedIds) {
            const code = yield select(selectQrCodeById(id));

            yield put(updateQrCodes({ ...code, status: 'working' }));
          }
        }
      } else {
        yield call(patchQrCodeStatus, { ids, status: 'working' });

        for (const id of ids) {
          const code = yield select(selectQrCodeById(id));

          yield put(updateQrCodes({ ...code, status: 'working' }));
        }
      }
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* pauseQrCodesByIds({
  payload,
}: PayloadAction<PauseQrCodeActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { ids } = payload;
    const maxRequestNum = 20;

    if (ids.length > maxRequestNum) {
      const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
        ids,
        maxRequestNum
      );
      for (const groupedIds of groupedQrCodesIdsByQuantity) {
        yield call(patchQrCodeActivity, { ids: groupedIds, status: 'paused' });

        for (const id of groupedIds) {
          const code = yield select(selectQrCodeById(id));

          yield put(
            updateQrCodes({ ...code, qr_code_dynamic_status: 'paused' })
          );
        }
      }
    } else {
      yield call(patchQrCodeActivity, { ids, status: 'paused' });

      for (const id of ids) {
        const code = yield select(selectQrCodeById(id));

        yield put(updateQrCodes({ ...code, qr_code_dynamic_status: 'paused' }));
      }
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* unpauseQrCodesByIds({
  payload,
}: PayloadAction<UnpauseQrCodeActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { ids } = payload;
    const maxRequestNum = 20;

    if (ids.length > maxRequestNum) {
      const groupedQrCodesIdsByQuantity = groupQrCodesIdsByQuantity(
        ids,
        maxRequestNum
      );
      for (const groupedIds of groupedQrCodesIdsByQuantity) {
        yield call(patchQrCodeActivity, { ids: groupedIds, status: 'actived' });

        for (const id of groupedIds) {
          const code = yield select(selectQrCodeById(id));

          yield put(
            updateQrCodes({ ...code, qr_code_dynamic_status: 'actived' })
          );
        }
      }
    } else {
      yield call(patchQrCodeActivity, { ids, status: 'actived' });

      for (const id of ids) {
        const code = yield select(selectQrCodeById(id));

        yield put(
          updateQrCodes({ ...code, qr_code_dynamic_status: 'actived' })
        );
      }
    }
    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* checkQrCodesByIds({
  payload,
}: PayloadAction<CheckQrCodeActionPayload>) {
  try {
    yield put(setAppState({ loading: true, error: null }));
    const { ids } = payload;

    for (const id of ids) {
      const code: QrCodeListItem = yield select(selectQrCodeById(id));
      let isChecked = code.isChecked === true ? false : true;

      yield put(updateQrCodes({ ...code, isChecked }));
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

function* checkAllQrCodesByIds({
  payload,
}: PayloadAction<CheckAllQrCodeActionPayload>) {
  const { codes, isCheckedAll } = payload;

  try {
    yield put(setAppState({ loading: true, error: null }));

    for (const item of codes) {
      const code: QrCodeListItem = yield select(selectQrCodeById(item.id));

      yield put(
        updateQrCodes({
          ...code,
          isChecked: isCheckedAll === true ? true : false,
        })
      );
    }

    yield put(setAppState({ loading: false, error: null }));
  } catch (e) {
    yield put(setAppState({ error: e.message, loading: false }));
  }
}

export default function* watcher() {
  yield takeLatest(addToFolderQrCodes.type, addToFolder);
  yield takeLatest(fetchQrCodesAction.type, fetchQrCodesByQuery);
  yield takeLatest(createQrCodeRoutine.TRIGGER, createNewQrCode);
  yield takeLatest(updateByIdQrCodeRoutine.TRIGGER, updateQrCodeById);
  yield takeLatest(archiveQrCodes.type, archiveQrCodesByIds);
  yield takeLatest(restoreQrCodes.type, restoreQrCodesByIds);
  yield takeLatest(pauseQrCodes.type, pauseQrCodesByIds);
  yield takeLatest(unpauseQrCodes.type, unpauseQrCodesByIds);
  yield takeLatest(checkQrCodes.type, checkQrCodesByIds);
  yield takeLatest(checkAllQrCodes.type, checkAllQrCodesByIds);
}
