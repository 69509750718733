import { Box, Flex } from 'rebass/styled-components';
import { MainLayout } from 'ui/layout';
import { Skeleton, SkeletonProvider } from 'ui/skeleton';

export const CommonAnalyticsSkeleton = () => (
  <SkeletonProvider>
    <MainLayout>
      <Box maxWidth={1270} mx='auto' py={24} pt={56}>
        <Box width={1} maxWidth={157} mb={16}>
          <Skeleton height={41} />
        </Box>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex>
            <Box width={1} maxWidth={180}>
              <Skeleton height={24} />
            </Box>
            <Box width={1} maxWidth={212}>
              <Skeleton height={24} />
            </Box>
          </Flex>
          <Box width={1} maxWidth={84}>
            <Skeleton height={20} />
          </Box>
        </Flex>
        <Box
          mt={16}
          sx={{
            display: 'grid',
            gridGap: '24px',
            gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr 1fr 1fr 1fr'],
          }}
        >
          <Box
            maxWidth={179}
            sx={{ gridColumn: ['1/2', 'auto'], gridRow: ['1 / 2', 'auto'] }}
          >
            <Skeleton height={108} />
          </Box>
          <Box
            maxWidth={179}
            sx={{ gridColumn: ['2/2', 'auto'], gridRow: ['1 / 2', 'auto'] }}
          >
            <Skeleton height={108} />
          </Box>
          <Box
            maxWidth={179}
            sx={{ gridColumn: ['1/2', 'auto'], gridRow: ['2 / 2', 'auto'] }}
          >
            <Skeleton height={108} />
          </Box>
          <Box
            maxWidth={179}
            sx={{ gridColumn: ['2/2', 'auto'], gridRow: ['2 / 2', 'auto'] }}
          >
            <Skeleton height={108} />
          </Box>
          <Box
            sx={{ gridColumn: ['span 2', '5 / 7'], gridRow: ['auto', '1 / 3'] }}
            maxWidth={458}
          >
            <Skeleton height={442} />
          </Box>
          <Box
            sx={{ gridColumn: ['span 2', '1 / 5'], gridRow: ['auto', '2 / 3'] }}
          >
            <Skeleton height={310} />
          </Box>
          <Box
            sx={{ gridColumn: ['span 2', '1 / 5'], gridRow: ['auto', '3 / 5'] }}
          >
            <Skeleton height={340} />
          </Box>
          <Box
            sx={{ gridColumn: ['span 2', '5 / 7'], gridRow: ['auto', '3 / 5'] }}
          >
            <Skeleton height={340} />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  </SkeletonProvider>
);
