import axios from 'axios';
import { getToken } from './localStorage';
import { ROUTE_CONFIRM_USER_EMAIL } from 'const'

export const headers = {
  HTTP_X_REQUESTED_WITH: 'XMLHttpRequest',
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const API_ROOT = process.env.REACT_APP_API_URL;

const ajax = axios.create({
  baseURL: API_ROOT,
  headers,
  withCredentials: true,
});

ajax.interceptors.request.use((request) => {
  if (!request.headers.Authorization && request.withCredentials) {
    request.headers.Authorization = `Token ${getToken()}`;
  }
  return request;
});

ajax.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getToken();

    if (401 === error.response?.data?.error?.status && token) {
      return (window.location.href = '/logout');
    }

    if (403 === error.response?.data?.error?.status &&
        token &&
        error.response?.data?.error?.description === "email_confirmation") {
          return (window.location.href = ROUTE_CONFIRM_USER_EMAIL);
        }

    return Promise.reject(error?.response?.data?.error || error);
  }
);

export default ajax;
