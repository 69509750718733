import ajax from './utils/ajax';
import { mapResponseToInvite } from './mappers/invitations';
import { InviteResponse } from './types';
import { Invite } from 'types';

type SendInviteRequest = {
  email: string;
};

export const sendInvite = async (payload: SendInviteRequest) => {
  await ajax.post('/invitations/invite', payload);
};

type RevokeInvitationRequest = {
  id: number;
};

export const revokeInvitation = async ({ id }: RevokeInvitationRequest) => {
  await ajax.delete(`/invitations?user_id=${id}`);
};

type AcceptInvitationRequest = {
  token: string;
  first_name: string;
  last_name: string;
  password: string;
};

export const acceptInvitation = async (payload: AcceptInvitationRequest) => {
  await ajax.post('/invitations/accept', payload);
};

type GetInvitedUsers = Array<Invite>;

export const getInvitedUsers = async (): Promise<GetInvitedUsers> => {
  const { data }: { data: InviteResponse[] } = await ajax.get('/users');

  return data.map(mapResponseToInvite);
};
