import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { position, PositionProps } from 'styled-system';
import { mq } from 'theme/breakpoints';
import { Typography } from 'ui/typography';
import { colors } from 'theme/colors';

export type NotificationType = 'error' | 'success' | undefined;

const TYPE_COLOR_FUNCS = {
  error: colors.red.main,
  success: colors.green.main,
};

const NotificationUI = styled.div<
  { type: NotificationType; isNotificationVisible?: boolean } & PositionProps
>`
  ${position};
  background-color: ${({ type = 'error' }) =>
    TYPE_COLOR_FUNCS[type]};
  color: ${({ theme }) => theme.colors.white.main};
  opacity: ${({ isNotificationVisible }) => (isNotificationVisible ? 1 : 0)};
  right: ${({ isNotificationVisible }) =>
    isNotificationVisible ? '0' : '-16px'};
  visibility: ${({ isNotificationVisible }) =>
    isNotificationVisible ? 'visible' : 'hidden'};
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
  transition: all 0.2s;
  z-index: 1050;
  cursor: pointer;

  ${mq.md} {
    top: ${({ isNotificationVisible }) =>
      isNotificationVisible ? '60px' : '-5%'};
  }

  p {
    padding: 8px 12px;
    line-height: 1.6;

    ${mq.md} {
      padding: 12px 18px;
    }
  }

  button {
    padding-right: 12px;

    ${mq.md} {
      padding-right: 16px;
    }
  }
`;

type NotificationProps = PositionProps & {
  type: NotificationType;
  message?: string | null;
  typographyProps?: any;
  isNotificationVisible?: boolean;
  onClose?: () => void;
  children?: ReactNode;
};

export const Notification = memo(
  ({
    typographyProps = {},
    message,
    isNotificationVisible,
    onClose,
    children,
    ...rest
  }: NotificationProps) => {
    return (
      <NotificationUI isNotificationVisible={isNotificationVisible} onClick={onClose} {...rest}>
        {(message || children) && (
          <>
            <Typography
              fontSize='12px'
              fontWeight='bold'
              color='white.main'
              {...typographyProps}
            >
              {message || children}
            </Typography>
          </>
        )}
      </NotificationUI>
    );
  }
);
