import { useMemo } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Button } from 'ui/button';

const Root = styled(Box)<{ bg?: string }>`
  background-color: ${({ theme, bg }) => bg || theme.colors.gray.light};
  position: relative;
  border-radius: 8px;
  padding: 2px;
`;

const ButtonGroup = styled(Flex)`
  position: relative;
`;

const ActiveTubBotton = styled(Button).attrs({
  variant: 'filled',
})<{ width: string; left: string }>(({ theme, width = '100%', left = 0 }) => ({
  transition: 'left .3s ease-in-out',
  backgroundColor: theme.colors.white.main,
  position: 'absolute',
  height: '100%',
  width,
  left,
}));

const TabButton = styled(Button).attrs({ variant: 'filled' })(({ theme }) => ({
  transition: 'color .2s ease-in-out .1s, font-weight .2s ease-in-out .1s',
  fontSize: theme.fontSizes[1],
  lineHeight: '18px',
  fontWeight: theme.fontWeights.normal,
  backgroundColor: 'transparent',
  color: theme.colors.gray.medium,
  width: '100%',
  zIndex: 100,

  '&:active, &.active': {
    fontWeight: theme.fontWeights.bold,
    color: theme.colors.gray.black,
  },
}));

export type Tab = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  tabs: Tab[];
  active: string;
  onClick: (active: string) => void;
  buttonProps?: {};
  rootProps?: {};
};

export const Tabs = ({
  tabs,
  onClick,
  active,
  buttonProps,
  rootProps,
}: Props) => {
  const activeTabIndex = useMemo(
    () => tabs.findIndex((t) => t.value === active),
    [active, tabs]
  );
  const tabsQuantity = useMemo(() => tabs.length, [tabs.length]);
  const activePosition = useMemo(
    () => `${(100 / tabsQuantity) * activeTabIndex}%`,
    [activeTabIndex, tabsQuantity]
  );
  const tabWidth = useMemo(() => `${100 / tabs.length}%`, [tabs.length]);

  return (
    <Root {...rootProps}>
      <ButtonGroup>
        <ActiveTubBotton
          as='div'
          width={tabWidth}
          left={activePosition}
          {...buttonProps}
        />
        {tabs.map((tab) => (
          <TabButton
            key={tab.value}
            className={tab.value === active ? 'active' : ''}
            onClick={() => onClick(tab.value)}
            disabled={tab.disabled}
            {...buttonProps}
          >
            {tab.label}
          </TabButton>
        ))}
      </ButtonGroup>
    </Root>
  );
};
