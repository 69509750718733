import { fetchQrCodes } from 'api/qrcode';

export const groupQrCodesIdsByQuantity = (
  allIds: number[],
  groupByQuantity: number
) => {
  return allIds.reduce((resultArray: number[][], item, index) => {
    const chunkIndex = Math.floor(index / groupByQuantity);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

export const fetchAllCodes = async (
  query: any,
  checkedItemsCount: number,
  maxRequestNum = 10
) => {
  const numberOfIterations = Math.ceil(checkedItemsCount / maxRequestNum);
  const requestsArr = [...Array(numberOfIterations)].map((it, i) => i);

  const allCodesPromise = await Promise.all(
    requestsArr.map(async (it, i) => {
      const { qr_codes } = await fetchQrCodes({
        limit: maxRequestNum,
        offset: it * maxRequestNum,
        ...query,
      });
      return qr_codes;
    })
  );

  return allCodesPromise.flat();
};
