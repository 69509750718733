import { createSlice } from '@reduxjs/toolkit';
import { QrCodeListItem } from 'types';
import {
  putAllQrCodes,
  putSizesQrCodes,
  removeAllQrCodes,
  removeQrCode,
  updateQrCodes,
} from '../actions/qrCodes';

export interface QrCodessState {
  ids: number[];
  byIds: {
    [id: number]: QrCodeListItem | undefined;
  };
  sizesByType: { [type: string]: number };
}

const initialState: QrCodessState = {
  ids: [],
  byIds: {},
  sizesByType: {},
};

export const qrCOdesSlice = createSlice({
  name: 'qrCodes',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [putAllQrCodes.type]: (
      state,
      { payload: codes }: { payload: QrCodeListItem[] }
    ) => {
      const newIds = codes.map((f) => f.id);
      const newItems = codes.reduce((acc, f) => ({ ...acc, [f.id]: f }), {});
      const byIds = { ...state.byIds, ...newItems };

      return {
        ...state,
        ids: state.ids.concat(newIds),
        byIds,
      };
    },
    [updateQrCodes.type]: (
      state,
      { payload: qrCode }: { payload: QrCodeListItem }
    ) => ({
      ...state,
      byIds: { ...state.byIds, [qrCode.id]: qrCode },
    }),
    [removeAllQrCodes.type]: () => initialState,
    [removeQrCode.type]: (
      state,
      { payload: { id } }: { payload: { id: number } }
    ) => ({
      ...state,
      ids: state.ids.filter((i) => i !== id),
      byIds: { ...state.byIds, [id]: undefined },
    }),
    [putSizesQrCodes.type]: (
      state,
      { payload }: { payload: { [key: string]: number } }
    ) => ({
      ...state,
      sizesByType: payload,
    }),
  },
});
