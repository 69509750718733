import styled from 'styled-components';
import { Error } from 'ui/labels';

export const Label = styled.label`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.gray.medium};
  line-height: 18px;
  margin-bottom: 4px;
`;

export const InputUI = styled('input')(({ theme }) => ({
  color: theme.colors.gray.black,
  backgroundColor: theme.colors.gray.light,
  padding: '11px 16px',
  borderRadius: '8px',
  border: '1px solid #E6E9EF',
  width: '100%',
  lineHeight: 1,
  '&::placeholder': {
    color: theme.colors.gray.medium,
  },
}));

export const Root = styled.div`
  &.error {
    ${Label} {
      color: ${({ theme }) => theme.colors.red.text};
    }

    ${InputUI} {
      background-color: ${({ theme }) => theme.colors.red.bg};
      border-color: ${({ theme }) => theme.colors.red.text};
    }
  }
  
  &.selectorError {
    ${InputUI} {
      color: ${({ theme }) => theme.colors.gray.medium};
      border-color: ${({ theme }) => theme.colors.red.text};
    }
  }
`;

export type InputProps = {
  id: string;
  value: string | number;
  onChange: any;
  onFocus: any;
  onBlur: any;
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  errorText?: string;
  autoComplete?: string;
};

export const TextField = ({
  id,
  label,
  errorText,
  autoComplete,
  ...rest
}: InputProps) => (
  <Root className={errorText ? 'error' : ''}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <InputUI id={id} autoComplete={autoComplete} {...rest} />
    {errorText && <Error>{errorText}</Error>}
  </Root>
);
